/**
 * Import Layer
 */
import React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { css } from '@emotion/react';

import { useHospital } from 'hooks';

/**
 * Types Layer
 */
type PrognosisButtonUIType = { url: string };

/**
 * Presentational Layer
 */
export const PrognosisButtonUI = React.memo((props: PrognosisButtonUIType) => {
  return (
    <Button
      size="small"
      startIcon={<EditIcon />}
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        height: 36px;
        width: 116px;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: #333333;
        background-color: #f0ffff;
        &:hover {
          opacity: 0.8;
          background-color: #f0ffff;
        }
      `}
    >
      予後入力
    </Button>
  );
});

/**
 * Conrainer Layer
 */
export const PrognosisButton = () => {
  const { hpUID, hostUrl } = useHospital();
  const fileName = 'NSER_mobile';
  const script = 'fromdashboard_list';
  const date = new Date();
  const year = date.getFullYear();
  const month = ('00' + (date.getMonth() + 1)).slice(-2);
  const day = ('00' + date.getDate()).slice(-2);
  const url = `${hostUrl}/fmi/webd/${fileName}?script=${script}&param=${hpUID}θ${year}${month}${day}`;

  return <PrognosisButtonUI url={url} />;
};
