import React from 'react';
import { Button, css, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InvitationItem } from './InvitationItem';

type ModalPaperProps = {
  invitationIds: string[];
  handleClose: () => void;
};

const boxStyle = css`
  position: relative;
  height: min-content;
  width: min-content;
  padding: 16px 32px;
`;

const titleStyle = css`
  padding: 0px 8px 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.5rem; /* 24px */
  line-height: 2.5rem; /* 40px */
  font-weight: bold;
`;

const contentStyle = css`
  width: 592px;
  height: 528px;
  overflow-y: scroll;
  background-color: #000000;
  border-radius: 4px;
`;

const closeButtonStyle = css`
  position: absolute;
  top: 16px;
  right: 32px;
`;

export const ModalPaper: React.VFC<ModalPaperProps> = React.memo(
  ({ invitationIds, handleClose }) => {
    return (
      <Paper css={boxStyle} elevation={6}>
        <Typography variant="h6" css={titleStyle}>
          招待一覧
        </Typography>
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={handleClose}
          css={closeButtonStyle}
        >
          閉じる
        </Button>
        <Paper elevation={0} css={contentStyle}>
          <ul
            css={css`
              margin: 0;
              padding: 0;
            `}
          >
            {invitationIds.map((id) => (
              <li key={id}>
                <InvitationItem id={id} />
              </li>
            ))}
          </ul>
        </Paper>
      </Paper>
    );
  }
);
