/**
 * Import Layer
 */
import React from 'react';
import { SerializedStyles } from '@emotion/react';

/**
 * Types Layer
 */
type ImageType = { imageUrl: string; imageCss: SerializedStyles };

/**
 * Presentational Layer
 */
export const Image = React.memo((props: ImageType) => {
  return <img src={props.imageUrl} alt="QRCode" css={props.imageCss} />;
});
