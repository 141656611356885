import { css } from '@emotion/react';
import { CSSProperties } from 'react';
import { Property } from 'csstype';

// デバッグ用フラグ（印刷エリアを赤枠で表示）
const PRINT_AREA_VISIBILITY_FLAG = false;

type HandoffSheetProps = {
  componentRef: React.MutableRefObject<HTMLDivElement | null>;
  patientId: string;
  age: number | null;
  sex: string;
  birthdate: string;
  nameCana: string;
  name: string;
  chiefComplaint: string;
  historyOfPresentIllness: string;
  pastMedicalHistory: string;
  medicine: string;
  jcs: string;
  gcsE: number | null;
  gcsV: number | null;
  gcsM: number | null;
  respirationRate: number | null;
  pulseRate: number | null;
  systolicBloodPressure: number | null;
  dyastolicBloodPressure: number | null;
  sp: number | null;
  sp2: number | null;
  oxygenRoute: string;
  oxygenFlow: number | null;
  bodyTemperature: number | null;
  cpf: string;
  hospitalArrivalTime: string;
  timeToArrive: number | null;
  emsTeamName: string;
  withPerson: string;
  qrCodeUrl: string;
}
type HandoffSheetParameters = Omit<HandoffSheetProps, "componentRef">;

const HandoffSheet: React.FC<HandoffSheetProps> = (props) => {
  const RootStyle = css`
    color: black;
    background-color: white;
    padding: 4px;
    font-size: 12pt;
  `;

  return (
    <div ref={props.componentRef} css={RootStyle}>
      <HandoffPatientBasicInfo {...props} />
      <HandoffPatientAdditionalInfo {...props} />
      <HandoffVitalSign {...props} />
      <HandoffOtherInfo {...props} />
      <HandoffQrCode {...props} />
      <HandoffPrintDate />
    </div>
  );
};

const HandoffPatientBasicInfo: React.FC<HandoffSheetParameters> = (params) => {
  let name = "";
  let nameSub = undefined;
  if (params.name === "" && params.nameCana === "") {
    // 氏名情報がない場合は固定値
    name = "【氏名情報なし】"
  } else if (params.name === "") {
    // フリガナのみの場合、表示範囲を拡大して15文字2行で表示
    nameSub = params.nameCana.substring(0, 30);
  } else {
    // 氏名は10文字まで表示
    name = params.name.substring(0, 10);
  }
  const sex = (params.sex === '' || params.sex === '不明') ? '性別不明' : params.sex;

  const timeToArrive = (params.timeToArrive !== null) ? `${params.timeToArrive}分後` : "";
  let hospitalArrivalTime = "";
  if (params.hospitalArrivalTime !== '' && params.hospitalArrivalTime !== undefined) {
    const raw_time = new Date(params.hospitalArrivalTime);
    const local_arrival_time_string = raw_time.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
    const time = new Date(local_arrival_time_string);
    const hour = ('0' + time.getHours()).slice(-2);
    const minutes = ('0' + time.getMinutes()).slice(-2);
    hospitalArrivalTime = `${hour}時${minutes}分`;
  }

  const patientIdLabelProps = {value: "患者ID", fontSize: 12, top: 16, left: 8, width: 56};
  const patientIdProps = {value: params.patientId, fontSize: 20, top: 8, left: 64, width: 160};
  const birthdateLabelProps = {value: "生年月日", fontSize: 12, top: 16, left: 240, width: 72};
  const birthdateProps = {value: params.birthdate, fontSize: 20, top: 8, left: 312, width: 200};
  const ageProps = {value: params.age, fontSize: 36, top: 8, left: 544, width: 60};
  const agePostProps = {value: "歳", fontSize: 18, top: 28, left: 604, width: 24};
  const sexProps = {value: sex, fontSize: 36, top: 8, left: 656, width: 108, textAlign: "center"} as TextPrintProps;
  const nameCanaProps = nameSub === undefined ? {value: params.nameCana, fontSize: 16, top: 116, left: 16, width: 750} : null;
  const nameProps = nameSub === undefined ? {value: name, fontSize: 54, top: 144, left: 16, width: 750, height: 80, underLine: true} : null;
  const nameSubProps = nameSub !== undefined ? {value: nameSub, fontSize: 36, top: 116, left: 16, width: 750, height: 108, underLine: true} : null;

  const timeToArriveLabelProps = {value: "到着予定：", fontSize: 12, top: 16, left: 16};
  const timeToArriveProps = {value: timeToArrive, fontSize: 34, top: 40, left: 16, width: 200};
  const hospitalArrivalTimeProps = {value: hospitalArrivalTime, fontSize: 34, top: 40, left: 220, width: 200, textAlign: "right"} as TextPrintProps;
  const emsTeamNameLabelProps = {value: "救急隊名：", fontSize: 12, top: 116, left: 16};
  const emsTeamNameProps = {value: params.emsTeamName, fontSize: 34, top: 140, left: 16, width: 404};

  return (
    <div style={{position: "relative"}}>
      {printText(patientIdLabelProps)}{printText(patientIdProps)}
      {printText(birthdateLabelProps)}{printText(birthdateProps)}
      {printText(ageProps)}{printText(agePostProps)}
      {printText(sexProps)}
      {printText(nameCanaProps)}
      {printText(nameProps)}
      {printText(nameSubProps)}
      <div style={{position: "absolute", top:"8px", left:"800px", width: "440px", height: "218px", outline: "black solid 1px", padding: "4px"}}>
        {printText(timeToArriveLabelProps)}
        {printText(timeToArriveProps)}
        {printText(hospitalArrivalTimeProps)}
        {printText(emsTeamNameLabelProps)}
        {printText(emsTeamNameProps)}
      </div>
    </div>
  );
}

const HandoffPatientAdditionalInfo: React.FC<HandoffSheetParameters> = (params) => {
  const adjustText = (value: string, line: number, column: number) => {
    // 改行文字の置換
    let split_value = value.split('@');
    let text_lines = [];
    for (let i = 0; i < split_value.length; i++) {
      if (split_value[i].length <= column) {
        text_lines.push(split_value[i])
      } else {
        // 改行処理
        let start_pos = 0;
        while (true) {
          let line = split_value[i].substring(start_pos, start_pos + column)
          if (line.length !== 0) text_lines.push(line);
          if (line.length < column) break;
          start_pos += column;
        }
      }
    }
  
    // 表示可能行数を超過
    if (line < text_lines.length) {
      text_lines = text_lines.slice(0, line);
      text_lines[line - 1] = text_lines[line - 1].slice(0, -3) + '【略】';
    }
  
    return text_lines.join('\n');
  }

  // CPAの情報が空の場合、目撃とバイスタンダーも空にする。
  const cpa = params.cpf.substring(0, 1);
  const witness = params.cpf.substring(1, 2);
  const bystander = params.cpf.substring(2, 3);
  const isCpf = (cpa === '1');
  const isWitness = (cpa === '1' && witness === '1');
  const isBystander = (cpa === '1' && bystander === '1');

  const chiefComplaint = params.chiefComplaint.substring(0, 28);
  const chiefComplaintCategoryProps = {value: "主訴", top: 8, left: 8, width: 60};
  const chiefComplaintProps = {value: chiefComplaint, fontSize: 28, top: 8, left: 76, width: 542, height: 96};
  const cpaCheckProps = {value: (isCpf ? "☒" : "☐") + " CPA", fontSize: 20, top: 112, left: 16, width: 120, height: 32};
  const witnessCheckProps = {value: (isWitness ? "☒" : "☐") + " 目撃", fontSize: 20, top: 112, left: 144, width: 120, height: 32};
  const bystanderCheckProps = {value: (isBystander ? "☒" : "☐") + " バイスタンダー", fontSize: 20, top: 112, left: 272, width: 240, height: 32};

  // CPAモードでは現病歴エリアを縮小
  const hpColumons = isCpf ? 14 : 16;
  const hpTop = isCpf ? 144 : 104;
  const hpHeight = isCpf ? 280 : 320;
  const historyOfPresentIllness = adjustText(params.historyOfPresentIllness, hpColumons, 28);
  const historyOfPresentIllnessCategoryProps = {value: "現病歴", top: 8, left: 8, width: 60};
  const historyOfPresentIllnessProps = {value: historyOfPresentIllness, fontSize: 14, top: 8, left: 76, width: 542, height: hpHeight};

  const pastMedicalHistory = adjustText(params.pastMedicalHistory, 3, 28);
  const pastMedicalHistoryCategoryProps = {value: "既往歴", top: 8, left: 8, width: 60};
  const pastMedicalHistoryProps = {value: pastMedicalHistory, fontSize: 14, top: 8, left: 76, width: 542, height: 56};

  const medicine = adjustText(params.medicine, 3, 28);
  const medicineCategoryProps = {value: "内服薬", top: 8, left: 8, width: 60};
  const medicineProps = {value: medicine, fontSize: 14, top: 8, left: 76, width: 542, height: 56};

  return (
    <div style={{position: "relative", top: "240px"}}>
      <div style={{position: "relative"}}>
        {printCategory(chiefComplaintCategoryProps)}
        {printText(chiefComplaintProps)}
        {isCpf && printText(cpaCheckProps)}
        {isCpf && printText(witnessCheckProps)}
        {isCpf && printText(bystanderCheckProps)}
      </div>
      <div style={{position: "relative", top: hpTop + "px"}}>
        {printCategory(historyOfPresentIllnessCategoryProps)}
        {printText(historyOfPresentIllnessProps)}
      </div>
      <div style={{position: "relative", top: "436px"}}>
        {printCategory(pastMedicalHistoryCategoryProps)}
        {printText(pastMedicalHistoryProps)}
      </div>
      <div style={{position: "relative", top: "514px"}}>
        {printCategory(medicineCategoryProps)}
        {printText(medicineProps)}
      </div>
    </div>
  );
}

const HandoffVitalSign: React.FC<HandoffSheetParameters> = (params) => {
  const isBodyTemperatureHigh = (params.bodyTemperature != null && 38 <= params.bodyTemperature);
  const isBodyTemperatureLow = (params.bodyTemperature != null && params.bodyTemperature < 34);

  const vitalSignsCategoryProps = {value: "バイタルサイン", top: 8, left: 8, width: 136};
  const jcsLabelProps = {value: "JCS", fontSize: 20, top: 40, left: 8, width: 72, height: 40};
  const jcsProps = {value: params.jcs, fontSize: 20, top: 40, left: 84, width: 80, height: 40, frame: true};
  const gcsLabelProps = {value: "GCS", fontSize: 20, top: 40, left: 188, width: 64, height: 40};
  const gcsELabelProps = {value: "E", fontSize: 14, top: 40, left: 256, width: 24, height: 40};
  const gcsEProps = {value: params.gcsE, fontSize: 20, top: 40, left: 276, width: 32, height: 40, frame: true};
  const gcsVLabelProps = {value: "V", fontSize: 14, top: 40, left: 316, width: 24, height: 40};
  const gcsVProps = {value: params.gcsV, fontSize: 20, top: 40, left: 336, width: 32, height: 40, frame: true};
  const gcsMLabelProps = {value: "M", fontSize: 14, top: 40, left: 376, width: 24, height: 40};
  const gcsMProps = {value: params.gcsM, fontSize: 20, top: 40, left: 396, width: 32, height: 40, frame: true};
  const respirationRateLabelProps = {value: "呼吸", fontSize: 20, top: 40, left: 448, width: 56, height: 40};
  const respirationRateProps = {value: params.respirationRate, fontSize: 20, top: 40, left: 508, width: 64, height: 40, frame: true};
  const respirationRateUnitProps = {value: "/min", fontSize: 14, top: 40, left: 580, width: 56, height: 40};
  const bloodPressureLabelProps = {value: "血圧", fontSize: 20, top: 100, left: 8, width: 72, height: 40};
  const systolicBloodPressureProps = {value: params.systolicBloodPressure, fontSize: 20, top: 100, left: 84, width: 64, height: 40, frame: true};
  const bloodPressureSeparatorProps = {value: "/", fontSize: 20, top: 100, left: 158, width: 16, height: 40};
  const dyastolicBloodPressureProps = {value: params.dyastolicBloodPressure, fontSize: 20, top: 100, left: 176, width: 64, height: 40, frame: true};
  const pulseRateLabelProps = {value: "脈拍", fontSize: 20, top: 100, left: 448, width: 56, height: 40};
  const pulseRateProps = {value: params.pulseRate, fontSize: 20, top: 100, left: 508, width: 64, height: 40, frame: true};
  const pulseRateUnitProps = {value: "/min", fontSize: 14, top: 100, left: 580, width: 56, height: 40};
  const spO2LabelProps = {value: "SpO2", fontSize: 20, top: 160, left: 8, width: 72, height: 40};
  const spProps = {value: params.sp, fontSize: 20, top: 160, left: 84, width: 64, height: 40, frame: true};
  const spUnitProps = {value: "%", fontSize: 14, top: 160, left: 158, width: 16, height: 40};
  const spArrowProps = {value: "→", fontSize: 20, top: 160, left: 180, width: 32, height: 36};
  const sp2Props = {value: params.sp2, fontSize: 20, top: 160, left: 216, width: 64, height: 40, frame: true};
  const sp2UnitProps = {value: "%", fontSize: 14, top: 160, left: 290, width: 16, height: 40};
  const oxygenRouteProps = {value: params.oxygenRoute, fontSize: 14, top: 160, left: 322, width: 200, height: 40, frame: true};
  const oxygenFlowProps = {value: params.oxygenFlow, fontSize: 18, top: 160, left: 522, width: 32, height: 40, frame: true};
  const oxygenUnitProps = {value: "L", fontSize: 14, top: 160, left: 562, width: 16, height: 40};
  const bodyTemperatureLabelProps = {value: "体温", fontSize: 20, top: 220, left: 8, width: 72, height: 40};
  const bodyTemperatureProps = {value: params.bodyTemperature, fontSize: 20, top: 220, left: 84, width: 64, height: 40, frame: true};
  const bodyTemperatureUnitProps = {value: "℃", fontSize: 14, top: 220, left: 158, width: 16, height: 40};
  const bodyTemperatureHighCheckProps = {value: (isBodyTemperatureHigh ? "☒" : "☐") + " high", fontSize: 18, top: 220, left: 188, width: 80, height: 40};
  const bodyTemperatureLowCheckProps = {value: (isBodyTemperatureLow ? "☒" : "☐") + " low", fontSize: 18, top: 220, left: 268, width: 80, height: 40};
  const pupilLabelProps = {value: "瞳孔", fontSize: 20, top: 220, left: 352, width: 56, height: 40};
  const pupilLabelSubProps = {value: "(右/左)", fontSize: 14, top: 220, left: 408, width: 72, height: 40};
  const rightPupil1Props = {value: "", fontSize: 20, top: 220, left: 472, width: 32, height: 40, frame: true};
  const rightPupil2Props = {value: "", fontSize: 20, top: 220, left: 504, width: 32, height: 40, frame: true};
  const pupilSeparatorProps = {value: "/", fontSize: 20, top: 220, left: 544, width: 16, height: 40};
  const leftPupil1Props = {value: "", fontSize: 20, top: 220, left: 560, width: 32, height: 40, frame: true};
  const leftPupil2Props = {value: "", fontSize: 20, top: 220, left: 592, width: 32, height: 40, frame: true};

  return (
    <div style={{position: "relative", top: "240px", left: "624px"}}>
      {printCategory(vitalSignsCategoryProps)}
      {printVsText(jcsLabelProps)}{printVsText(jcsProps)}
      {printVsText(gcsLabelProps)}
      {printVsText(gcsELabelProps)}{printVsText(gcsEProps)}
      {printVsText(gcsVLabelProps)}{printVsText(gcsVProps)}
      {printVsText(gcsMLabelProps)}{printVsText(gcsMProps)}
      {printVsText(respirationRateLabelProps)}
      {printVsText(respirationRateProps)}{printVsText(respirationRateUnitProps)}
      {printVsText(bloodPressureLabelProps)}
      {printVsText(systolicBloodPressureProps)}{printVsText(bloodPressureSeparatorProps)}{printVsText(dyastolicBloodPressureProps)}
      {printVsText(pulseRateLabelProps)}
      {printVsText(pulseRateProps)}{printVsText(pulseRateUnitProps)}
      {printVsText(spO2LabelProps)}
      {printVsText(spProps)}{printVsText(spUnitProps)}
      {printVsText(spArrowProps)}
      {printVsText(sp2Props)}{printVsText(sp2UnitProps)}
      {printVsText(oxygenRouteProps)}{printVsText(oxygenFlowProps)}{printVsText(oxygenUnitProps)}
      {printVsText(bodyTemperatureLabelProps)}
      {printVsText(bodyTemperatureProps)}{printVsText(bodyTemperatureUnitProps)}
      {printVsText(bodyTemperatureHighCheckProps)}{printVsText(bodyTemperatureLowCheckProps)}
      {printVsText(pupilLabelProps)}{printVsText(pupilLabelSubProps)}
      {printVsText(rightPupil1Props)}{printVsText(rightPupil2Props)}
      {printVsText(pupilSeparatorProps)}
      {printVsText(leftPupil1Props)}{printVsText(leftPupil2Props)}
    </div>
  );
}

const HandoffOtherInfo: React.FC<HandoffSheetParameters> = (params) => {
  const withPersonLabelProps = {value: "付き添い：", fontSize:14, top: 8, left: 8, width: 96, height: 24};
  const withPersonProps = {value: params.withPerson, fontSize:14, top: 8, left: 104, width: 184, height: 24};
  const examinationKindLabelProps = {value: "初/再診区分：", fontSize:14, top: 8, left: 304, width: 120, height: 24};
  const policeNameLabelProps = {value: "警察名：", fontSize:14, top: 40, left: 8, width: 96, height: 24};
  const clinicalDepartmentLabelProps = {value: "診療科：", fontSize:14, top: 40, left: 304, width: 96, height: 24};
  const referralSourceLabelProps = {value: "紹介元：", fontSize:14, top: 72, left: 8, width: 96, height: 24};

  return (
    <div style={{position: "relative", top: "520px", left: "624px"}}>
      <div style={{position: "absolute", top:"8px", left:"8px", width: "616px", height: "104px", outline: "black solid 1px", padding: "4px"}}>
        {printText(withPersonLabelProps)}{printText(withPersonProps)}
        {printText(examinationKindLabelProps)}
        {printText(policeNameLabelProps)}
        {printText(clinicalDepartmentLabelProps)}
        {printText(referralSourceLabelProps)}
      </div>
    </div>
  );
}

const HandoffQrCode: React.FC<HandoffSheetParameters> = (params) => {
  return(
    <div style={{position: "relative", top: "640px", left: "1010px"}}>
      <div style={{position: "absolute", top:"8px", left:"8px", width: "224px", height: "224px", outline: "black solid 1px", padding: "4px", display: "flex", alignItems: "center"}}>
        {!!params.qrCodeUrl
          ? <img src={params.qrCodeUrl} alt="QRCode" style={{width: "216px", height: "216px"}}/>
          : <div style={{width: "100%", textAlign: "center"}}>NO QR-CODE</div>
        }
      </div>
    </div>
  );
}

const HandoffPrintDate: React.FC = () => {
  // 印刷時間
  const pTime = new Date(new Date().toLocaleString('ja-JP', {timeZone: 'Asia/Tokyo'}));
  const pYear = pTime.getFullYear();
  const pMonth = ('00' + (pTime.getMonth() + 1)).slice(-2);
  const pDay = ('00' + pTime.getDate()).slice(-2);
  const pHour = ('0' + pTime.getHours()).slice(-2);
  const pMinutes = ('0' + pTime.getMinutes()).slice(-2);
  const printDatetime = `${pYear}-${pMonth}-${pDay} ${pHour}時${pMinutes}分`

  const printDatetimeLabelProps = {value: "印刷時刻：", fontSize:14, top: 8, left: 8, width: 96, height: 24};
  const printDatetimeProps = {value: printDatetime, fontSize:14, top: 8, left: 104, width: 240, height: 24};

  return (
    <div style={{position: "relative", top: "840px"}}>
      {printText(printDatetimeLabelProps)}{printText(printDatetimeProps)}
    </div>
  );
}

type TextPrintProps = {
  value: string | number | null;
  fontSize?: number;
  top: number;
  left: number;
  width?: number;
  height?: number;
  textAlign?: Property.TextAlign;
  underLine?: boolean;
}

type VsTextPrintProps = {
  value: string | number | null;
  fontSize?: number;
  top: number;
  left: number;
  width: number;
  height: number;
  frame?: boolean;
}

type CategoryPrintProps = {
  value: string;
  top: number;
  left: number;
  width: number;
}

const printText = (props: TextPrintProps | null): JSX.Element => {
  const testOutline = PRINT_AREA_VISIBILITY_FLAG ? "red dashed 1px" : null;
  if (props === null) {
    return (<></>);
  }
  const cssProps: CSSProperties = {
    display: "inline-block",
    position: "absolute",
    top: props.top + "px",
    left: props.left + "px",
    lineHeight: "105%",
    whiteSpace: "pre-wrap",
  }
  if (props.fontSize) cssProps.fontSize = props.fontSize + "pt";
  if (props.width) cssProps.width = props.width + "px";
  if (props.height) cssProps.height = props.height + "px";
  if (props.textAlign) cssProps.textAlign = props.textAlign;
  if (props.underLine) cssProps.borderBottom = "solid 2px";
  if (testOutline) cssProps.outline = testOutline;
  
  return (
    <span style={cssProps}>{props.value}</span>
  );
}

const printVsText = (props: VsTextPrintProps): JSX.Element => {
  const frameCssProps : CSSProperties = {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: props.top + "px",
    left: props.left + "px",
    width: props.width + "px",
    height: props.height + "px",
  }
  if (props.frame) frameCssProps.outline = "solid 1px";

  const textCssProps: CSSProperties = {
    display: "inline-block",
    width: props.width + "px",
  }
  if (props.fontSize) textCssProps.fontSize = props.fontSize + "pt";
  if (props.frame) textCssProps.textAlign = "center";
  
  return (
    <div style={frameCssProps}>
      <span style={textCssProps}>{props.value}</span>
    </div>
  );
}

const printCategory = (props: CategoryPrintProps): JSX.Element => {
  const cssProps: CSSProperties = {
    position: "absolute",
    top: props.top + "px",
    left: props.left + "px",
    width: props.width + "px",
    height: "24px",
    paddingTop: "1px",
    fontSize: "11pt",
    outline: "black solid 2px",
    textAlign: "center",
}
  return (
    <div style={cssProps}>{props.value}</div>
  );
}

export default HandoffSheet;
