/**
 * Import Layer
 */
import React from 'react';

import { Button } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { css } from '@emotion/react';

import { useSelectedPatient, useHospital, useFooterButtonHandler } from 'hooks';
import { Patient, LastClickedButtonType } from 'states/ducks/patients/types';

/**
 * Types Layer
 */
type FooterButtonType = { action?: LastClickedButtonType };
type FooterButtonUIType = FooterButtonType & {
  selectedPatient: Patient;
  isOpen: boolean;
  handleSubmit: (patient: Patient) => Promise<void>;
  handleClose: () => void;
};
export type ApiRequestType = 'accept' | 'denial' | 'additional' | '';

/**
 * Presentational Layer
 */
const defaultButtonStyle = css`
  width: 30%;
  height: 70%;
  color: white;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const accecptButtonStyle = css`
  ${defaultButtonStyle}
  &:hover {
    opacity: 0.8;
    background: #1877f2;
  }
  background: #1877f2;
`;
const rejectButtonStyle = css`
  ${defaultButtonStyle}
  &:hover {
    opacity: 0.8;
    background: #ee4056;
  }
  background: #ee4056;
`;

const requestInfoButtonStyle = css`
  ${defaultButtonStyle}
  &:hover {
    opacity: 0.8;
    background: #929da6;
  }
  background: #929da6;
`;

export const FooterButtonUI = React.memo((props: FooterButtonUIType) => {
  const { action, selectedPatient, isOpen, handleSubmit, handleClose } = props;
  const { lastClicked, ambulancerecord_UID } = selectedPatient;

  const cssStyele =
    action === 'ACCEPT'
      ? accecptButtonStyle
      : action === 'REJECT'
      ? rejectButtonStyle
      : action === 'REQUEST'
      ? requestInfoButtonStyle
      : defaultButtonStyle;
  const textLabel =
    action === 'ACCEPT'
      ? '受入可'
      : action === 'REJECT'
      ? '不可'
      : action === 'REQUEST'
      ? '情報求む'
      : '';

  return (
    <>
      <Button
        type="submit"
        disabled={ambulancerecord_UID === ''}
        startIcon={lastClicked === action ? <CheckOutlinedIcon /> : undefined}
        css={cssStyele}
        onClick={async () => {
          handleSubmit(selectedPatient);
        }}
      >
        {textLabel}
      </Button>
      <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="success"
          icon={false}
          css={css`
            width: 240px;
            font-size: 18px;
            position: fixed;
            bottom: 110px;
            right: 2vw;
          `}
        >
          送信しました
        </Alert>
      </Snackbar>
    </>
  );
});

/**
 * Container Layer
 */
export const FooterButton = React.memo((props: FooterButtonType) => {
  const { hpUID } = useHospital();
  const selectedPatient = useSelectedPatient();
  const { ambulancerecord_UID } = selectedPatient;
  const { action } = props;

  let requestType: ApiRequestType;
  switch (action) {
    case 'ACCEPT':
      requestType = 'accept';
      break;
    case 'REJECT':
      requestType = 'denial';
      break;
    case 'REQUEST':
      requestType = 'additional';
      break;
    default:
      requestType = '';
      break;
  }

  if (hpUID == null || hpUID === '') {
    console.error('FooterButton: hpUID is null or empty');
  }

  const { isOpen, handleSubmit, handleClose } = useFooterButtonHandler(
    ambulancerecord_UID,
    hpUID ?? '',
    props.action,
    requestType
  );

  return (
    <FooterButtonUI
      action={props.action}
      selectedPatient={selectedPatient}
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
});
