/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePatientInfo = /* GraphQL */ `
  subscription OnCreatePatientInfo($ac: Int!, $hpUID: String) {
    onCreatePatientInfo(ac: $ac, qhpUID: $hpUID) {
      ambulancerecord_UID
      ac
      acclog
      ag
      am
      amUID
      amz {
        com
        cpss
        cvdd
        cvp
        cvr
        lvo
        vst
        mco
        freetextModalButtonLabel
        freetextModalContent
      }
      ar
      bd
      bt
      cc
      co
      cpf
      cpr
      cptd
      cr
      cvb
      cvf
      da
      db
      dc
      dd
      de
      dh
      di
      dp
      dq
      drcar_inquiry
      drheli_inquiry
      ea
      em
      ge
      gm
      gv
      ha
      hd
      ho
      hpUID
      id
      jc
      jn
      me
      na
      naf
      nsc
      of
      or
      ot
      pd
      ph
      pi
      pr
      qa
      qhpUID
      re
      rr
      sb
      se
      simultaneousInquiry
      peerHospitals {
        id
        hospitalName
        response
      }
      destination_hospital
      sp
      sp2
      tff
      ty
      vt
      wp
      vitals {
        bt
        db
        of
        or
        pr
        rr
        sp
        sb
        sp2
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePatientInfo = /* GraphQL */ `
  subscription OnUpdatePatientInfo($ac: Int!, $hpUID: String) {
    onUpdatePatientInfo(ac: $ac, qhpUID: $hpUID) {
      ambulancerecord_UID
      ac
      acclog
      ag
      am
      amUID
      amz {
        com
        cpss
        cvdd
        cvp
        cvr
        lvo
        vst
        mco
        freetextModalButtonLabel
        freetextModalContent
      }
      ar
      bd
      bt
      cc
      co
      cpf
      cpr
      cptd
      cr
      cvb
      cvf
      da
      db
      dc
      dd
      de
      dh
      di
      dp
      dq
      drcar_inquiry
      drheli_inquiry
      ea
      em
      ge
      gm
      gv
      ha
      hd
      ho
      hpUID
      id
      jc
      jn
      me
      na
      naf
      nsc
      of
      or
      ot
      pd
      ph
      pi
      pr
      qa
      qhpUID
      re
      rr
      sb
      se
      simultaneousInquiry
      peerHospitals {
        id
        hospitalName
        response
      }
      destination_hospital
      sp
      sp2
      tff
      ty
      vt
      wp
      vitals {
        bt
        db
        of
        or
        pr
        rr
        sp
        sb
        sp2
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateImagesData = /* GraphQL */ `
  subscription OnCreateImagesData($patientId: String!) {
    onCreateImagesData(patientId: $patientId) {
      id
      patientId
      isVideo
      videoUrl
      videoDuration
      videoWidth
      videoHeight
      createdAt
    }
  }
`;

export const onCreateInvitation = /* GraphQL */ `
  subscription OnCreateInvitation($hpUID: String!) {
    onCreateInvitation(hpUID: $hpUID) {
      id
      hpUID
      teamId
      teamName
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const onChangeMessage = /* GraphQL */ `
  subscription OnChangeMessage($hpUID: String!) {
    onChangeMessage(hpUID: $hpUID) {
      messages {
        id
        message
        size
        fontColor
        backgroundColor
      }
    }
  }
`;
