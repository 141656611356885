/**
 * Import Layer
 */
import React from 'react';
import { Box, Modal, Fade } from '@mui/material';
import { css } from '@emotion/react';

import { Image } from './Image';

/**
 * Types Layer
 */
type QrCodeModalType = {
  qrCodeUrl: string;
  isOpen: boolean;
  handleQrCodeClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

/**
 * Presentational Layer
 */
const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const fadeStyle = css`
  width: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  outline: none;
  border-radius: 6px;
`;

export const QrCodeModal = React.memo((props: QrCodeModalType) => {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleQrCodeClose}
      closeAfterTransition
      css={modalStyle}
    >
      <Fade in={props.isOpen}>
        <Box css={fadeStyle}>
          <Image
            imageUrl={props.qrCodeUrl}
            imageCss={css`
              max-width: 430px;
              max-height: 430px;
            `}
          />
        </Box>
      </Fade>
    </Modal>
  );
});
