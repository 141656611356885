import { Button, css, Paper, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const boxStyle = css`
  height: min-content;
  width: min-content;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
`;

const titleStyle = css`
  padding: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: bold;
`;

const contentStyle = css`
  width: 540px;
  height: 440px;
  padding: 16px;
  overflow-y: scroll;
  background-color: #222222;
`;

const markdownStyle = css`
  * {
    margin: 0;
  }

  p {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin: 1.25rem 0; /* 20px 0 */
  }

  li {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
    color: #ffc56d;
  }

  a {
    color: #8cb4ff;
  }

  h1 {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    margin-top: 4rem; /* 64px */
    margin-bottom: 2rem; /* 32px */
    padding-bottom: 0.25rem; /* 4px */
    border-bottom: solid 1px #5d5d5d;
  }

  h2 {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
    margin-top: 3rem; /* 48px */
  }

  h3 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 2rem; /* 32px */
  }

  h4 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 1.5rem; /* 24px */
  }

  h5 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 1.5rem; /* 24px */
  }

  h6 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin-top: 1.5rem; /* 24px */
  }

  code {
    padding: 0.125rem 0.375rem; /* 2px 6px */
    margin: 0 0.125rem; /* 0 2px */
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    border-radius: 0.25rem; /* 4px */
    font-family: monospace;
    background: #343434;
    color: #ffc56d;
  }

  pre {
    margin: 1rem 0; /* 16px 0 */
    padding: 0.5rem 1rem; /* 8px 16px */
    background: #343434;
    border-radius: 0.25rem; /* 4px */

    code {
      padding: 0;
      margin: 0;
    }
  }

  blockquote {
    margin: 1.25rem 0; /* 20px 0 */
    padding: 0.5rem 1.25rem; /* 8px 20px */
    border-left: solid 0.25rem; /* 4px */
    border-color: #afafaf;
    color: #a4a4a4;
    font-style: italic;
  }

  hr {
    border: 1px solid #5d5d5d;
  }

  table {
    margin: 1rem 0; /* 16px 0 */
    table-layout: auto;
    border-collapse: collapse;
  }

  td {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    padding: 0.5rem; /* 8px */
    border: 1px solid #5d5d5d;
  }

  th {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    padding: 0.5rem; /* 8px */
    border: 1px solid #5d5d5d;
    background: #343434;
    font-weight: bold;
  }

  section[data-footnotes='true'] {
    margin-top: 6rem; /* 96px */
    border-top: 1px solid #5d5d5d;
    color: #d3d3d3;

    h2 {
      margin: 0.5rem 0; /* 8px 0 */
    }

    p {
      margin: 0;
      font-size: 1.125rem; /* 18px */
      line-height: 1.75rem; /* 28px */
    }
  }
`;

const closeButtonStyle = css`
  display: block;
  margin-top: 8px;
  margin-left: auto;
`;

type MarkdownContentProps = {
  content: string | null | undefined;
};

type ModalPaperProps = {
  label: string | null | undefined;
  content: string | null | undefined;
  handleClose: () => void;
};

export const MarkdownContent: React.VFC<MarkdownContentProps> = React.memo(
  ({ content }) => {
    return (
      <section>
        <ReactMarkdown remarkPlugins={[remarkGfm]} css={markdownStyle}>
          {content?.replace(/\\n/g, '  \n') ?? ''}
        </ReactMarkdown>
      </section>
    );
  }
);

export const ModalPaper: React.VFC<ModalPaperProps> = React.memo(
  ({ label, content, handleClose }) => {
    return (
      <Paper css={boxStyle} elevation={6}>
        <Typography variant="h6" css={titleStyle}>
          {label || '追加情報'}
        </Typography>
        <Paper elevation={0} css={contentStyle}>
          <MarkdownContent content={content} />
        </Paper>
        <Button onClick={handleClose} css={closeButtonStyle}>
          閉じる
        </Button>
      </Paper>
    );
  }
);
