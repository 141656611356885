import axios from 'axios';

import { getIdToken } from './cognitoHelper';
import { setLocalStorage } from './localStorageHelper';
/**
 * Get QR Code URL Function
 * Types & Function
 */

// QR Code URL API Response
type QrApiResponse = {
  status: string;
  result: {
    presigned_url: string;
  };
};

export const qrApiRequest = async (
  qr_compression_flag: boolean,
  qrCodeString: string
) => {
  if (qrCodeString === '') return '';
  try {
    const idToken = await getIdToken();
    const response = await axios.post<QrApiResponse>(
      process.env.REACT_APP_QR_API_URL || 'localhost',
      {
        qr_compression_flag: qr_compression_flag,
        text: qrCodeString,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data.result.presigned_url;
  } catch (error) {
    console.error(error);
    return '';
  }
};

/**
 * fms1 Authentication Function
 * Types & Function
 */

// fms1 AuthAPI(AWS) Base Response
type BaseApiResponse = {
  status: number;
  message: string;
  error: boolean;
};

// fms1 AuthAPI(AWS) Success Response
type SuccessResponse = BaseApiResponse & {
  data: {
    username: string;
    hospitalName: string;
    hpUID: string;
    lat: number;
    lng: number;
    qr_compression_flag: boolean;
    dashboard_button_flag: boolean;
    COGNITO_USERNAME: string;
    COGNITO_PASSWORD: string;
  };
};

// fms1 AuthAPI(AWS) Failer Response
type FailerResponse = BaseApiResponse & {
  data: undefined;
};

// fms1 AuthAPI(AWS) Response / Success or Failer
type Fms1AuthResponse = SuccessResponse | FailerResponse;

// Local Storage Data Type
export type LocalStorageDataType = {
  username: string;
  hospitalName: string;
  hpUID: string;
  lat: string;
  lng: string;
  qr_compression_flag: string;
  dashboard_button_flag: string;
};

// fms1 configuration
const fms1Config = {
  BASE_URL: process.env.REACT_APP_AUTH_BASEURL,
  ENDPOINT: '/auth/signin',
  header: {
    'Content-Type': 'application/json',
  },
};

// fms1 authentication function
export const fmsAuth = async (user_name: string, user_pass: string) => {
  try {
    const response = await axios.post<Fms1AuthResponse>(
      fms1Config.BASE_URL + fms1Config.ENDPOINT,
      {
        user_name: user_name,
        user_pass: user_pass,
      }
    );
    if (response.data.error || !response.data.data) {
      return { isError: true };
    }
    const {
      username,
      hospitalName,
      hpUID,
      lat,
      lng,
      qr_compression_flag,
      dashboard_button_flag,
    } = response.data.data;
    const items = {
      username,
      hospitalName,
      hpUID,
      lat: String(lat),
      lng: String(lng),
      qr_compression_flag: String(qr_compression_flag),
      dashboard_button_flag: String(dashboard_button_flag),
    };
    setLocalStorage<LocalStorageDataType>(items);
    const cognito_username = Buffer.from(
      response.data.data.COGNITO_USERNAME,
      'base64'
    ).toString('ascii');
    const cognito_password = Buffer.from(
      response.data.data.COGNITO_PASSWORD,
      'base64'
    ).toString('ascii');
    return {
      isError: false,
      data: {
        username: response.data.data.username,
        hospitalName: response.data.data.hospitalName,
        hpUID: response.data.data.hpUID,
        lat: response.data.data.lat,
        lng: response.data.data.lng,
        qr_compression_flag: response.data.data.qr_compression_flag,
        dashboard_button_flag: response.data.data.dashboard_button_flag,
        cognito_username,
        cognito_password,
      },
    };
  } catch (error) {
    return { isError: true };
  }
};

export const footerButtonRequest = async (
  ambulancerecord_UID: string,
  requestType: 'accept' | 'denial' | 'additional' | '',
  hospitalUID: string,
  hostUrl: string
) => {
  const param = `${ambulancerecord_UID}θ${requestType}θ${hospitalUID}`;
  const idToken = await getIdToken();
  try {
    await axios.post<any>(
      process.env.REACT_APP_FOOTER_BUTTON_BASEURL || 'localhost',
      { hostUrl, param },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return;
  } catch (error) {
    console.error(error);
    return;
  }
};

// パスワードポリシー判定API
export const verifyPasswordPolicyRequest = async (
  hospitalUID: string,
  password: string
) => {
  const apiUrl = `${process.env.REACT_APP_HOSPITAL_USER_API_URL}/${hospitalUID}/verify-password-policy`
  try {
    const idToken = await getIdToken();
    const response = await axios.post<any>(
      apiUrl,
      { password },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data.result;
  } catch (error) {
    console.error(error);
    return false;
  }
};
