import { getFmsAuthenticatedUser } from './localStorageHelper';
import { cognitoAuth } from './cognitoHelper';
import { fmsAuth } from './externalApiHelper';
import { isUsablePastAuth } from './authJudgeHelper';

export type CurrentUserType =
  | {
      username: string;
      hospitalName: string;
      hpUID: string;
      lat: number | null;
      lng: number | null;
      qr_compression_flag: boolean;
      dashboard_button_flag: boolean;
    }
  | undefined;

export const getAuthenticatedUser = async (): Promise<CurrentUserType> => {
  const fmsUser = getFmsAuthenticatedUser();
  if (!fmsUser.isFmsAuthenticated) {
    return undefined;
  }
  return fmsUser.data;
};

type AuthenticateResponse =
  | {
      isAuthenticated: true;
      data: {
        username: string;
        hospitalName: string;
        hpUID: string;
        hostUrl: string;
        lat: number;
        lng: number;
        cognito_username: string;
        cognito_password: string;
        qr_compression_flag: boolean;
        dashboard_button_flag: boolean;
      };
    }
  | { isAuthenticated: boolean; data: undefined };

export const authenticate = async (
  user_name: string,
  user_pass: string
): Promise<AuthenticateResponse> => {
  if (isUsablePastAuth(user_name)) {
    // 従来の認証がまだ使用できる病院
    const response = await _authenticatePast(user_name, user_pass);
    if (response.isAuthenticated) {
      // 従来の認証で認証が成功したので、そのままレスポンス
      return response;
    }
  }
  const { isCognitoAuthenticated } = await cognitoAuth(user_name, user_pass);
  return { isAuthenticated: isCognitoAuthenticated, data: undefined };
};

const _authenticatePast = async (
  user_name: string,
  user_pass: string
): Promise<AuthenticateResponse> => {
  const { isError, data } = await fmsAuth(user_name, user_pass);
  if (!isError && data) {
    const isCognitoAuthenticated = await cognitoAuth(
      data.cognito_username,
      data.cognito_password
    );
    if (isCognitoAuthenticated) {
      return {
        isAuthenticated: true,
        data: { ...data, hostUrl: 'https://fms1.txpmedical.com' },
      };
    } else {
      return { isAuthenticated: false, data: undefined };
    }
  } else {
    return { isAuthenticated: false, data: undefined };
  }
};
