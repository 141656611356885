/**
 * Import Layer
 */
import React from 'react';

import { PatientDetailItem } from '../PatientDetailParts';
import { useSelectedPatient } from 'hooks';
import { checkEmpty } from '../utils';
import { getBirthDayString } from './getBirthDayString';
/**
 * Types Layer
 */
type PatientNameType = { na?: string; naf?: string; bd?: string; createdAt?: string };

/**
 * Presentational Layer
 */
export const PatientNameUI = React.memo(({ na, naf, bd, createdAt }: PatientNameType) => {
  const primaryBody = {
    text: na === undefined ? '-' : checkEmpty(na),
    subText: naf === undefined ? '' : checkEmpty(naf),
  };

  const secondaryBody = (
    <>
      <span
        style={{
          color: 'rgba(255, 255, 255, 0.6)',
          fontWeight: 'bold',
          fontSize: '12px',
        }}
      >
        生年月日&nbsp;&nbsp;&nbsp;
      </span>
      <span
        style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: '12px' }}
        data-testid="birthday-body"
      >
        {bd === undefined ? '-' : getBirthDayString(bd, createdAt)}
      </span>
    </>
  );

  return (
    <PatientDetailItem
      title={'氏名'}
      primaryBody={primaryBody}
      secondaryBody={secondaryBody}
    />
  );
});

/**
 * Container Layer
 */
export const PatientName = React.memo((props: PatientNameType) => {
  const { na, naf, bd, createdAt } = useSelectedPatient();

  return <PatientNameUI na={na} naf={naf} bd={bd} createdAt={createdAt} />;
});
