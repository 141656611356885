export const checkEmpty = (text: string) => (text === '' ? '-' : text);

export const insertNewLineByAtSign = (text: string) => {
  // 1文字目が",(comma)" または "、(読点)" だった場合、その文字の削除
  const removedFirstCommaToten = text.replace(/(^,)|(^、)/g, '');
  // 文字列に "@" が含まれていなければ、その文字列をreturn
  if (!removedFirstCommaToten.includes('@')) return removedFirstCommaToten;
  // 文字列内の "@" が 2個以上連続で存在している場合、1個の"@" へreplace (e.g. "@@@" -> "@")
  const removedAtSign = removedFirstCommaToten.replace(/@{2,}/g, '@');
  // 文字列の最後の文字が "@" の場合、その文字の削除  (e.g. "text@" -> "text")
  const removedEndAtSign =
    removedAtSign[removedAtSign.length - 1] === '@'
      ? removedAtSign.slice(0, -1)
      : removedAtSign;
  // "@" で区切って、JSX.Element[] 型にして return -> "@" を改行コードとして表示できる
  return removedEndAtSign.split('@').map((line, key) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));
};

export const calculateDiffTime = (ea: number, createdAt?: string) => {
  let dateObj: Date;
  if (!createdAt) {
    dateObj = new Date();
  } else {
    dateObj = new Date(createdAt);
  }
  dateObj.setMinutes(dateObj.getMinutes() + ea);
  return dateObj.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
};

export const estimateArrivalTime = (createdAt?: string, ea?: number | null) => {
  if (!ea) return '-';
  const diffTime = calculateDiffTime(ea, createdAt);
  const datetime = diffTime.split(' ');
  const eta = datetime[1].split(':');
  const subText = eta[0] + ':' + eta[1];
  return (
    <>
      {ea}
      <span style={{ fontSize: '12px' }}>分後</span> ( {subText}
      <span style={{ fontSize: '12px' }}>予定</span> )
    </>
  );
};

export const generateArrivalTimeView = (ha: string, ea?: number | null) => {
  const ea_minutes = !ea ? '-' : String(ea);
  const dateObj = new Date(ha);
  const localeString = dateObj.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
  const datetime = localeString.split(' ');
  const eta = datetime[1].split(':');
  const subText = eta[0] + ':' + eta[1];
  return (
    <>
      {ea_minutes}
      <span style={{ fontSize: '12px' }}>分後</span> ( {subText}
      <span style={{ fontSize: '12px' }}>予定</span> )
    </>
  );
};

export const convertIso8601Format = (ha?: string) => {
  if (!ha || ha === '') return '';
  const haDateObj = new Date(ha);
  var year = haDateObj.getFullYear();
  var month = ('00' + (haDateObj.getMonth() + 1)).slice(-2);
  var day = ('00' + haDateObj.getDate()).slice(-2);
  const hour = ('00' + haDateObj.getHours()).slice(-2);
  const minutes = ('00' + haDateObj.getMinutes()).slice(-2);
  const seconds = ('00' + haDateObj.getSeconds()).slice(-2);
  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}+09:00`;
};

export const createCovic19Text = (
  cvf?: boolean | null,
  cvb?: boolean | null,
  cptd?: string
) => {
  let refinedCvf: string;
  let refinedCvb: string;
  let refinedCptd: string;

  // cvf の文字列処理
  switch (cvf) {
    case true:
      refinedCvf = '有り';
      break;
    case false:
      refinedCvf = '無し';
      break;
    default:
      refinedCvf = '-';
      break;
  }
  // cvb の文字列処理
  switch (cvb) {
    case true:
      refinedCvb = '有り';
      break;
    case false:
      refinedCvb = '無し';
      break;
    default:
      refinedCvb = '-';
      break;
  }

  // cptd の文字列処理
  if (cptd === undefined) {
    refinedCptd = '-';
  } else {
    switch (cptd[0]) {
      case '0':
        refinedCptd = '無し';
        break;
      case '1':
        if (cptd.length === 1) refinedCptd = '有り';
        else refinedCptd = `有り / ${cptd.slice(1)}`;
        break;
      default:
        refinedCptd = '-';
        break;
    }
  }
  return `発熱：${refinedCvf}@呼吸器症状：${refinedCvb}@流行地への移動：${refinedCptd}`;
};
