/**
 * Import Layer
 */
import React from 'react';
import { Button } from '@mui/material';
import { css } from '@emotion/react';

/**
 * Types Layer
 */
type DemandButtonUIType = { url: string };

/**
 * Presentational Layer
 */
export const DemandButtonUI = React.memo((props: DemandButtonUIType) => {
  return (
    <Button
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        width: 116px;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: #333333;
        background-color: #f0ffff;
        &:hover {
          opacity: 0.8;
          background-color: #f0ffff;
        }
      `}
    >
      応需
    </Button>
  );
});

/**
 * Conrainer Layer
 */
export const DemandButton = ({ hospitalId }: { hospitalId: string | null }) => {
  const url = `https://fms1.txpmedical.com/fmi/webd/Demand_status?script=start_input(user_id)&param=${hospitalId}`;

  return <DemandButtonUI url={url} />;
};
