/**
 * Import Layer
 */
import React from 'react';

import { PatientDetailItem } from '../PatientDetailParts';
import { useSelectedPatient } from 'hooks';
import { checkEmpty, insertNewLineByAtSign } from '../utils';

/**
 * Types Layer
 */
type PresentIllnessType = { cc?: string };

/**
 * Presentational Layer
 */
export const PresentIllnessUI = React.memo((props: PresentIllnessType) => {
  const { cc } = props;
  const primaryBody = {
    text: cc === undefined ? '-' : insertNewLineByAtSign(checkEmpty(cc)),
  };

  return <PatientDetailItem title={'現病歴'} primaryBody={primaryBody} />;
});

/**
 * Container Layer
 */
export const PresentIllness = () => {
  const { cc } = useSelectedPatient();

  return <PresentIllnessUI cc={cc} />;
};
