// Local Storage Data Type
type LocalStorageDataType = {
  username: string | null;
  hospitalName: string | null;
  hpUID: string | null;
  lat: string | null;
  lng: string | null;
  qr_compression_flag: string | null;
  dashboard_button_flag: string | null;
};

type CurrentFmsUser =
  | {
      isFmsAuthenticated: true;
      data: {
        username: string;
        hospitalName: string;
        hpUID: string;
        lat: number | null;
        lng: number | null;
        qr_compression_flag: boolean;
        dashboard_button_flag: boolean;
      };
    }
  | {
      isFmsAuthenticated: false;
      data: {
        username: null;
        hospitalName: null;
        hpUID: null;
        lat: null;
        lng: null;
        qr_compression_flag: false;
        dashboard_button_flag: false;
      };
    };

// Set data to LocalStorage
export const setLocalStorage = <
  T extends {
    [s: string]: string;
  }
>(
  items: T
): void => {
  Object.entries<string>(items).forEach(([key, value], _) => {
    localStorage.setItem(key, value);
  });
};

// Get data from LocalStorage
const getLocalStorage = (): LocalStorageDataType => {
  return {
    username: localStorage.getItem('username'),
    hospitalName: localStorage.getItem('hospitalName'),
    hpUID: localStorage.getItem('hpUID'),
    lat: localStorage.getItem('lat'),
    lng: localStorage.getItem('lng'),
    qr_compression_flag: localStorage.getItem('qr_compression_flag'),
    dashboard_button_flag: localStorage.getItem('dashboard_button_flag'),
  };
};

export const getFmsAuthenticatedUser = (): CurrentFmsUser => {
  const {
    username,
    hospitalName,
    hpUID,
    lat,
    lng,
    qr_compression_flag,
    dashboard_button_flag,
  } = getLocalStorage();

  if (username !== null && hospitalName !== null && hpUID !== null) {
    return {
      isFmsAuthenticated: true,
      data: {
        username,
        hpUID,
        hospitalName,
        lat: lat !== null ? Number(lat) : null,
        lng: lng !== null ? Number(lng) : null,
        qr_compression_flag: qr_compression_flag === 'true',
        dashboard_button_flag: dashboard_button_flag === 'true',
      },
    };
  } else {
    return {
      isFmsAuthenticated: false,
      data: {
        username: null,
        hospitalName: null,
        hpUID: null,
        lat: null,
        lng: null,
        qr_compression_flag: false,
        dashboard_button_flag: false,
      },
    };
  }
};
