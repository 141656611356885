import React from 'react';
import { Button, css, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ModalPaperProps = {
  message?: string;
  disabled?: boolean;
  peerHospitals?: {
    hospitalName: string;
    response?: string | null;
  }[];
  handleRespond: (
    response: 'ACCEPT' | 'REJECT' | 'OTHER',
    freetext?: string
  ) => void | Promise<void>;
  handleClose: () => void;
};

const boxStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  width: 640px;
  height: 640px;
  overflow-y: scroll;
  background-color: #292929;
  border-radius: 8px;
`;

const closeButtonStyle = css`
  position: absolute;
  top: 16px;
  right: 32px;
`;

const responseButtonStyle = (type: 'accept' | 'reject' | 'other') => css`
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: bold;
  color: white;
  background-color: ${type === 'accept'
    ? '#263cff'
    : type === 'reject'
    ? '#ff144c'
    : '#787878'};
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const ModalPaper: React.VFC<ModalPaperProps> = React.memo(
  ({ message, disabled, peerHospitals, handleRespond, handleClose }) => {
    return (
      <Paper css={boxStyle} elevation={6}>
        <h5
          css={css`
            font-size: 1.75rem; /* 28px */
            line-height: 1;
            font-weight: bold;
            margin: 0;
          `}
        >
          一斉要請応答
        </h5>
        {message !== undefined && (
          <p
            css={css`
              font-size: 1rem; /* 16px */
              line-height: 1.5rem; /* 24px */
              font-weight: bold;
              margin: 0;
            `}
          >
            {message}
          </p>
        )}
        <ResponseButtonList disabled={disabled} handleRespond={handleRespond} />
        <h6
          css={css`
            font-size: 1.5rem; /* 24px */
            line-height: 1;
            font-weight: bold;
            margin: 0;
          `}
        >
          回答状況
        </h6>
        <table
          css={css`
            border-spacing: 0;
            border-top: 1px solid white;
            width: 512px;
          `}
        >
          <tbody>
            {peerHospitals?.map((peerHospital) => (
              <tr key={peerHospital.hospitalName}>
                <td
                  css={css`
                    border-bottom: 1px solid white;
                    padding: 4px 8px;
                    font-size: 1rem; /* 16px */
                    line-height: 1.5rem; /* 24px */
                  `}
                >
                  {peerHospital.hospitalName}
                </td>
                <td
                  css={css`
                    border-bottom: 1px solid white;
                    padding: 4px 8px;
                    width: 156px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 1rem; /* 16px */
                    line-height: 1.5rem; /* 24px */
                  `}
                >
                  {peerHospital.response === 'ACCEPT'
                    ? '受入可'
                    : peerHospital.response === 'REJECT'
                    ? '受入不可'
                    : peerHospital.response === 'OTHER'
                    ? 'その他'
                    : peerHospital.response === 'DESTINATION'
                    ? '搬送決定'
                    : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={handleClose}
          css={closeButtonStyle}
        >
          閉じる
        </Button>
      </Paper>
    );
  }
);

const ResponseButtonList: React.VFC<{
  disabled?: boolean;
  handleRespond: (
    response: 'ACCEPT' | 'REJECT' | 'OTHER',
    freetext?: string
  ) => void | Promise<void>;
}> = ({ disabled, handleRespond }) => {
  const [freetext, setFreetext] = React.useState('');
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        gap: 16px;
      `}
    >
      <button
        css={responseButtonStyle('accept')}
        disabled={disabled}
        onClick={() => handleRespond('ACCEPT')}
      >
        受入可
      </button>
      <button
        css={responseButtonStyle('reject')}
        disabled={disabled}
        onClick={() => handleRespond('REJECT')}
      >
        受入不可
      </button>
      <div
        css={css`
          background-color: white;
          border-radius: 4px;
          opacity: ${disabled ? 0.5 : 1};
        `}
      >
        <input
          type="text"
          disabled={disabled}
          value={freetext}
          onChange={(e) => setFreetext(e.target.value)}
          css={css`
            font-size: 1rem; /* 16px */
            line-height: 1.5rem; /* 24px */
            width: 216px;
            background-color: transparent;
            height: 100%;
            border: none;
            margin-right: 2px;
            padding: 0px 4px;
          `}
        />
        <button
          css={responseButtonStyle('other')}
          disabled={disabled}
          onClick={() => handleRespond('OTHER', freetext)}
        >
          その他
        </button>
      </div>
    </div>
  );
};
