/**
 * Import Layer
 */
import React from 'react';

import { PatientDetailItem } from '../PatientDetailParts';
import { useSelectedPatient } from 'hooks';
import { checkEmpty, insertNewLineByAtSign } from '../utils';

/**
 * Types Layer
 */
type MedicineType = { me?: string };

/**
 * Presentational Layer
 */
export const MedicineUI = React.memo((props: MedicineType) => {
  const { me } = props;
  const primaryBody = {
    text: me === undefined ? '-' : insertNewLineByAtSign(checkEmpty(me)),
  };

  return <PatientDetailItem title={'内服'} primaryBody={primaryBody} />;
});

/**
 * Container Layer
 */
export const Medicine = () => {
  const { me } = useSelectedPatient();

  return <MedicineUI me={me} />;
};
