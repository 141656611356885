import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'states/index';
import {
  setUsername,
  setPassword,
  loginSuccess,
  loginFailed,
  setIsLoading,
} from 'states/ducks/auth/slice';
import { setHospital, setIsAuthenticated } from 'states/ducks/hospital/slice';
import { SignInFormUI } from '../Presentation/SignInFormUI';
import { authenticate } from 'utils';
import { useSignOut } from 'hooks';

export const SignInForm = () => {
  const { username, password, isButtonDisabled, helperText, isError } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();
  const signOut = useSignOut();
  let navigate = useNavigate();

  const handleSignIn = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));
      await signOut(); // ログイン前にログアウト

      const authResponse = await authenticate(username, password);
      if (authResponse.isAuthenticated) {
        if (authResponse.data) {
          // 従来認証の場合のみ
          dispatch(
            setHospital({
              username: authResponse.data.username,
              hospitalName: authResponse.data.hospitalName,
              hpUID: authResponse.data.hpUID,
              hostUrl: authResponse.data.hostUrl,
              lat: authResponse.data.lat,
              lng: authResponse.data.lng,
              qr_compression_flag: authResponse.data.qr_compression_flag,
              dashboard_button_flag: authResponse.data.dashboard_button_flag,
              inquiry_history_button_flag: undefined,
              inquiry_response_button_flag: undefined,
              ambulance_record_button_flag: undefined,
              authVersion: 'v1',
            })
          );
        }
        dispatch(setIsAuthenticated(true));
        dispatch(loginSuccess());
        dispatch(setIsLoading(false));
        navigate('/dashboard');
      } else {
        throw new Error('Authentication failed');
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      dispatch(loginFailed());
    }
  }, [dispatch, navigate, signOut, password, username]);

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(setPassword(event.target.value));
    },
    [dispatch]
  );

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(setUsername(event.target.value));
    },
    [dispatch]
  );

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        isButtonDisabled || handleSignIn();
      }
    },
    [handleSignIn, isButtonDisabled]
  );

  return (
    <SignInFormUI
      helperText={helperText}
      isError={isError}
      isButtonDisabled={isButtonDisabled}
      handleUsernameChange={handleUsernameChange}
      handlePasswordChange={handlePasswordChange}
      handleKeyPress={handleKeyPress}
      handleSignIn={handleSignIn}
    />
  );
};
