import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import store from 'states';

Sentry.init({
  dsn: 'https://038ad1edbfa443c751ec9c14c6055a47@o4506772321075200.ingest.us.sentry.io/4506816454197248',
  integrations: [],
  tracesSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
