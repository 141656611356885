export const getAgeString = (bd: string, createdAt: string) => {
  try {
    if (bd.length !== 8) return undefined;
    if (Number.isNaN(Number(bd.slice(0, 4)))) return undefined;
    if (Number.isNaN(Number(bd.slice(4, 6)))) return undefined;
    if (Number.isNaN(Number(bd.slice(6, 8)))) return undefined;
    if (Number.isNaN(Date.parse(createdAt))) return undefined;

    const birthDayDate = new Date(
      Number(bd.slice(0, 4)),
      Number(bd.slice(4, 6)) - 1,
      Number(bd.slice(6, 8))
    );
    const createdAtDate = new Date(createdAt);
    const [years, months, days] = getAgeYMD(birthDayDate, createdAtDate);

    if (years === 0 && months < 3) {
      const postnatalDays = getDays(birthDayDate, createdAtDate);
      return `${years}歳${months}ヶ月${days}日 - 生後日数${postnatalDays}日`;
    }
    if (years < 6) {
      return `${years}歳${months}ヶ月`;
    }
    return `${years}歳`;
  } catch {
    return undefined;
  }
};

export const getAgeYMD = (birthDay: Date, current: Date): [number, number, number] => {
  const [years, lastYearIncrementDate] = getYears(birthDay, current);
  const [months, lastMonthIncrementDate] = getMonths(lastYearIncrementDate, current);
  const days = getDays(lastMonthIncrementDate, current);
  return [years, months, days];
};

export const getYears = (birthDay: Date, current: Date): [number, Date] => {
  const thisYearsBirthDay = new Date(
    current.getFullYear(),
    birthDay.getMonth(),
    birthDay.getDate()
  );
  const years =
    current >= thisYearsBirthDay
      ? current.getFullYear() - birthDay.getFullYear()
      : current.getFullYear() - birthDay.getFullYear() - 1;
  const lastYearIncrementDate = new Date(
    birthDay.getFullYear() + years,
    birthDay.getMonth(),
    birthDay.getDate()
  );
  return [years, lastYearIncrementDate];
};

export const getMonths = (birthDay: Date, current: Date): [number, Date] => {
  const thisMonthsBirthDay = new Date(
    current.getFullYear(),
    current.getMonth(),
    birthDay.getDate()
  );
  const months =
    (current.getFullYear() - birthDay.getFullYear()) * 12 +
    (current >= thisMonthsBirthDay
      ? current.getMonth() - birthDay.getMonth()
      : current.getMonth() - birthDay.getMonth() - 1);
  const lastMonthIncrementDateCandidate1 = new Date(
    birthDay.getFullYear(),
    birthDay.getMonth() + months,
    birthDay.getDate()
  );
  const lastMonthIncrementDateCandidate2 = new Date(
    birthDay.getFullYear(),
    birthDay.getMonth() + months + 1,
    1
  );
  const lastMonthIncrementDate =
    lastMonthIncrementDateCandidate1 < lastMonthIncrementDateCandidate2
      ? lastMonthIncrementDateCandidate1
      : lastMonthIncrementDateCandidate2;
  return [months, lastMonthIncrementDate];
};

export const getDays = (birthDay: Date, current: Date) => {
  const birthDayMidnight = new Date(
    birthDay.getFullYear(),
    birthDay.getMonth(),
    birthDay.getDate()
  ); // 誕生日の午前0時を取得
  const diff = current.getTime() - birthDayMidnight.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24));
};
