import React from 'react';
import { css } from '@emotion/react';

const filterTypes = {
  all: {
    color: '#90CAF9',
    label: '全事案',
  },
  doctorheli: {
    color: '#fc508e',
    label: 'ドクヘリ',
  },
  doctorcar: {
    color: '#ff9417',
    label: 'DRカー',
  },
  rescue: {
    color: '#6FA4C3',
    label: '救急隊搬送',
  },
  referral: {
    color: '#A18FFF',
    label: '紹介患者',
  },
} as const;

export type FilterType = keyof typeof filterTypes;

export type FilterButtonProps = {
  type: FilterType;
  onSelectFilter: (value: FilterType) => void;
  isSelected: boolean;
};

const filterButtonStyle = (type: FilterType, isSelected: boolean) => css`
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
  background: ${!isSelected ? 'transparent' : filterTypes[type].color};
  cursor: pointer;

  /* Border */
  border-width: 1px;
  border-style: ${isSelected ? 'solid' : 'dashed'};
  border-color: ${filterTypes[type].color};

  /* Content Center */
  display: flex;
  place-content: center;

  /* Text Style */
  font-family: 'Noto Sans JP';
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${isSelected ? '#292929' : filterTypes[type].color}};
`;

export const FilterButton = React.memo((props: FilterButtonProps) => {
  return (
    <button
      onClick={() => props.onSelectFilter(props.type)}
      css={filterButtonStyle(props.type, props.isSelected)}
      aria-pressed={props.isSelected ? 'true' : 'false'}
    >
      {filterTypes[props.type].label}
    </button>
  );
});
