import { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CookieStorage,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

const PROXY_USER_NAME = 'dashboard-proxy-user';

export type CognitoAuthenticatedUserType = {
  username: string;
  hpUID: string;
};

const authenticationDetailsConfigure = (
  cognitoUsername: string,
  cognitoPassword: string
) =>
  new AuthenticationDetails({
    Username: cognitoUsername,
    Password: cognitoPassword,
  });

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'ap-northeast-1_DEFAULT',
  ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || 'DEFAULT',
  Storage: new CookieStorage({
    domain: process.env.REACT_APP_COOKIE_DOMAIN || 'localhost',
    secure: process.env.REACT_APP_COOKIE_SECURE === 'true' ? true : false || true,
    sameSite: 'strict',
  }),
});

const createCognitoUser = (cognitoUsername: string) =>
  new CognitoUser({
    Username: cognitoUsername,
    Pool: userPool,
    Storage: new CookieStorage({
      domain: process.env.REACT_APP_COOKIE_DOMAIN || 'localhost',
      secure: process.env.REACT_APP_COOKIE_SECURE === 'true' ? true : false || true,
      sameSite: 'strict',
    }),
  });

export const cognitoAuth = (
  cognitoUsername: string,
  cognitoPassword: string
): Promise<{ isCognitoAuthenticated: boolean }> => {
  const cognitoUser = createCognitoUser(cognitoUsername);
  const authenticationDetails = authenticationDetailsConfigure(
    cognitoUsername,
    cognitoPassword
  );
  try {
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (_) => {
          resolve({
            isCognitoAuthenticated: true,
          });
        },
        onFailure: (_) => {
          reject({
            isCognitoAuthenticated: false,
          });
        },
        newPasswordRequired: (_) => {
          reject({
            isCognitoAuthenticated: false,
          });
        },
      });
    });
  } catch (error) {
    return new Promise((_, reject) => {
      reject({
        isCognitoAuthenticated: false,
      });
    });
  }
};

export const getCognitoAuthenticatedUser = async (): Promise<
  CognitoAuthenticatedUserType | undefined
> => {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
  const username = cognitoUser.getUsername();

  // usernameがPROXY_USER_NAMEと一致したとき、V1認証として処理すべきなので、undefinedを返す
  if (username === PROXY_USER_NAME) {
    return undefined;
  }

  // ------ 以下はV2認証としての処理 ------
  const getAttributes = () =>
    new Promise<CognitoUserAttribute[]>((resolve, reject) => {
      cognitoUser.getUserAttributes((error, attributes) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(attributes || []);
      });
    });
  const attributes = await getAttributes();

  // ユーザー属性リストからcustom:hpUID属性を取得
  const hpUIDAttribute = attributes.find(
    (attribute) => attribute.getName() === 'custom:hpUID'
  );
  if (hpUIDAttribute === undefined) {
    console.error('Failed to get `custom:hpUID` attribute.');
    return undefined;
  }

  // custom:hpUID属性からhpUIDを取得
  const hpUID = hpUIDAttribute.getValue();
  if (hpUID === undefined) {
    console.error('Failed to get value of hpUID from `custom:hpUID` attribute.');
    return undefined;
  }

  return {
    username,
    hpUID,
  };
};

export const getIdToken = async (): Promise<string> => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (error) {
    console.error('Failed to get ID Token.');
    return '';
  }
};
