/**
 * Import Layer
 */
import React from 'react';
import { TextField } from '@mui/material';

/**
 * Types Layer
 */
export type UsernameFormType = {
  isError: boolean;
  handleUsernameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export type PasswordFormType = {
  isError: boolean;
  helperText: string;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const UsernameForm = React.memo((props: UsernameFormType) => {
  return (
    <TextField
      error={props.isError}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="username"
      label="Username"
      name="username"
      autoComplete="username"
      autoFocus
      onChange={props.handleUsernameChange}
      onKeyPress={props.handleKeyPress}
    />
  );
});

export const PasswordForm = React.memo((props: PasswordFormType) => {
  return (
    <TextField
      error={props.isError}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="password"
      autoComplete="current-password"
      helperText={props.helperText}
      onChange={props.handlePasswordChange}
      onKeyPress={props.handleKeyPress}
    />
  );
});
