import React from 'react';
import { css, TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'

const textFieldStyle = css`
    width: 600px;
    padding-top: 8px;
`;

export type PasswordFormProps = {
    label: string;
    value: string;
    setValue: (value: string) => void;
    errorMessage?: string;
};

export const PasswordForm = React.memo((props: PasswordFormProps) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setValue(event.target.value);
    };

    return (
        <TextField
            error={Boolean(props.errorMessage)}
            value={props.value}
            helperText={props.errorMessage}
            onChange={handleChangeValue}
            margin="normal"
            label={props.label}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            css={textFieldStyle}
            InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
        />
    );
});
