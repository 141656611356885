/**
 * Import Layer
 */
import React from 'react';
import { Box, Fade, Modal, useTheme, Theme } from '@mui/material';
import { css } from '@emotion/react';

import { ImageDownload } from './ImageDownload';
import { useSelector } from 'react-redux';
import { imagesSelectors } from 'states';

/**
 * Types Layer
 */
type ImageModalType = {
  isOpen: boolean;
  handleCloseImage: () => void;
  selectedImageKey: string;
};

/**
 * Container Layer
 */
const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const fadeStyle = (theme: Theme) => css`
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: ${theme.shadows[5]};
  backdrop-filter: blur(3px);
  padding: ${theme.spacing(3, 3, 3)};
  outline: none;
`;

export const ImageModal = React.memo((props: ImageModalType) => {
  const theme = useTheme();
  const images = useSelector(imagesSelectors.selectAll);
  const image = images.find(
    (image) => `${image.patientId}/${image.id}` === props.selectedImageKey
  );
  const isVideo = image?.isVideo;
  const videoUrl = image?.videoUrl;
  const videoHeight =
    image?.videoWidth != null &&
    image?.videoHeight != null &&
    image.videoHeight >= image.videoWidth
      ? 640
      : 360;

  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleCloseImage}
      closeAfterTransition
      css={modalStyle}
    >
      <Fade in={props.isOpen}>
        <Box css={fadeStyle(theme)}>
          {isVideo ? (
            <video
              src={videoUrl}
              css={css`
                width: 640px;
                height: ${videoHeight}px;
                object-fit: contain;
              `}
              width={640}
              height={videoHeight}
              controls
            />
          ) : (
            <ImageDownload s3Key={props.selectedImageKey} />
          )}
        </Box>
      </Fade>
    </Modal>
  );
});
