/**
 * Import Layer
 */
import React from 'react';
import { Button, useTheme } from '@mui/material';
import { css } from '@emotion/react';

/**
 * Types Layer
 */
export type SignInButtonType = {
  handleSignIn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isButtonDisabled: boolean;
};

export const SignInButton = React.memo((props: SignInButtonType) => {
  const theme = useTheme();
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      onClick={props.handleSignIn}
      disabled={props.isButtonDisabled}
      css={css`
        margin: ${theme.spacing(3, 0, 2)};
      `}
    >
      ログイン
    </Button>
  );
});
