/**
 * Import Layer
 */
import React from 'react';

import { PatientDetailItem } from '../PatientDetailParts';
import { useSelectedPatient } from 'hooks';
import { checkEmpty, insertNewLineByAtSign } from '../utils';

/**
 * Types Layer
 */
type ChiefComplaintType = { pi?: string };

/**
 * Presentational Layer
 */
export const ChiefComplaintUI = React.memo((props: ChiefComplaintType) => {
  const { pi } = props;
  const primaryBody = {
    text: pi === undefined ? '-' : insertNewLineByAtSign(checkEmpty(pi)),
  };

  return <PatientDetailItem title={'主訴'} primaryBody={primaryBody} />;
});

/**
 * Container Layer
 */
export const ChiefComplaint = () => {
  const { pi } = useSelectedPatient();

  return <ChiefComplaintUI pi={pi} />;
};
