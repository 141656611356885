/**
 * Import Layer
 */
import React from 'react';
import { Box } from '@mui/material';
import { css } from '@emotion/react';

import { useSelectedPatient } from 'hooks';
import { getSexString } from './utils';
import { Sex } from 'states/ducks/patients/types';

/**
 * Types Layer
 */
type AgeType = { ag?: number | null };
type SexType = { se?: Sex };
type AgeSexType = AgeType & SexType;

/**
 * Presentational Layer
 */
// 性別用
const sexUnknownStyle = css`
  width: max-content;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: #ffffff;
`;
const sexMaleStyle = css`
  ${sexUnknownStyle}
  color: #1362fc;
`;
const sexFemaleStyle = css`
  ${sexUnknownStyle}
  color: #c73000;
`;

const AgeUI = React.memo((props: AgeType) => {
  return (
    <Box
      css={css`
        width: max-content;
        font-size: 24px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: #ffffff;
      `}
    >
      {props.ag != null && (
        <>
          {props.ag}
          <span style={{ fontSize: '12px' }}>歳</span>
        </>
      )}
    </Box>
  );
});

const SexUI = React.memo((props: SexType) => {
  const style =
    props.se === 1
      ? sexMaleStyle // 男性
      : props.se === 2
      ? sexFemaleStyle // 女性
      : sexUnknownStyle; // それ以外

  return <Box css={style}>{getSexString(props.se)}</Box>;
});

export const AgeSexUI = React.memo((props: AgeSexType) => {
  return (
    <Box>
      <AgeUI ag={props.ag} />
      <SexUI se={props.se} />
    </Box>
  );
});

/**
 * Container Layer
 */
export const AgeSex = () => {
  const { ag, se } = useSelectedPatient();
  return <AgeSexUI ag={ag} se={se} />;
};
