import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SubscriptionErrorsType = {
  CreateSubscriptionPatientEffect_0: boolean;
  CreateSubscriptionPatientEffect_1: boolean;
  UpdateSubscriptionPatientEffect_0: boolean;
  UpdateSubscriptionPatientEffect_1: boolean;
  UpdateSubscriptionPatientEffect_2: boolean;
  CreateSubscriptionImageEffect: boolean;
};
export type ErrorStateType = {
  subscriptionError: {
    errors: SubscriptionErrorsType;
    errorByOR: boolean;
  };
};

export const initialState = {
  subscriptionError: {
    errors: {
      CreateSubscriptionPatientEffect_0: false,
      CreateSubscriptionPatientEffect_1: false,
      UpdateSubscriptionPatientEffect_0: false,
      UpdateSubscriptionPatientEffect_1: false,
      UpdateSubscriptionPatientEffect_2: false,
      CreateSubscriptionImageEffect: false,
    },
    errorByOR: false,
  },
} as ErrorStateType;

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setSubscriptionError: (
      state,
      action: PayloadAction<
        | { CreateSubscriptionPatientEffect_0: boolean }
        | { CreateSubscriptionPatientEffect_1: boolean }
        | { UpdateSubscriptionPatientEffect_0: boolean }
        | { UpdateSubscriptionPatientEffect_1: boolean }
        | { UpdateSubscriptionPatientEffect_2: boolean }
        | { CreateSubscriptionImageEffect: boolean }
      >
    ) => {
      const errors = { ...state.subscriptionError.errors, ...action.payload };
      const errorList = Object.values(errors); // [true, false, true, ...]
      const numberOfTrue = errorList.filter((bool) => bool === true).length;
      if (numberOfTrue >= 1) {
        const ret: ErrorStateType = {
          ...state,
          subscriptionError: { errors, errorByOR: true },
        };
        return ret;
      } else {
        return {
          ...state,
          subscriptionError: { errors, errorByOR: false },
        };
      }
    },
    clearSubscriptionError: (state) => {
      return { ...state, subscriptionError: initialState.subscriptionError };
    },
    clearError: () => {
      return initialState;
    },
  },
});

// Reducerのエクスポート
export default errorSlice.reducer;

// Action Creatorsのエクスポート
export const { setSubscriptionError, clearSubscriptionError, clearError } =
  errorSlice.actions;
