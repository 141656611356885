/**
 * Import Layer
 */
import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { css } from '@emotion/react';

import { ImageListView } from './src/ImageListView';
import { ImageModal } from './src//ImageModal';

/**
 * Types Layer
 */
type S3ImageListViewUIType = {
  child1: React.ReactNode | string;
  child2: React.ReactNode | string;
};

/**
 * Presentational Layer
 */
export const S3ImageListViewUI = React.memo((props: S3ImageListViewUIType) => {
  return (
    <Box
      css={css`
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          width: 8px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #888888;
          border-radius: 10px;
        }
        width: 268px;
      `}
    >
      {props.child1}
      {props.child2}
    </Box>
  );
});

/**
 * Container Layer
 */
export const S3ImageListView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageKey, setSelectedImageKey] = useState('');
  const handleOpenImage = useCallback((key) => {
    setIsOpen(true);
    setSelectedImageKey(key);
  }, []);

  const handleCloseImage = useCallback(() => {
    setIsOpen(false);
    setSelectedImageKey('');
  }, []);

  return (
    <S3ImageListViewUI
      child1={<ImageListView handleOpenImage={handleOpenImage} />}
      child2={
        <ImageModal
          isOpen={isOpen}
          handleCloseImage={handleCloseImage}
          selectedImageKey={selectedImageKey}
        />
      }
    />
  );
};
