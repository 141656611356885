/**
 * Import Layer
 */
import React from 'react';
import { ListItemText, Typography } from '@mui/material';
import { css } from '@emotion/react';

import { Sex as SexType } from 'states/ducks/patients/types';

const getSexString = (sex?: SexType | null) => {
  switch (sex) {
    case 0:
      return '不明';
    case 1:
      return '男性';
    case 2:
      return '女性';
    default:
      return '不明';
  }
};

/**
 * Types Layer
 */
type SexTypeUI = { se?: SexType };

/**
 * Presentational Layer
 */
export const SexUI = React.memo((props: SexTypeUI) => {
  const color = props.se === 1 ? '#1362fc' : props.se === 2 ? '#c73000' : '#FFFFFF';

  return (
    <ListItemText
      css={css`
        min-width: max-content;
      `}
      primary={
        <React.Fragment>
          <Typography
            component="span"
            noWrap={true}
            css={css`
              font-size: 24px;
              font-weight: bold;
              font-family: 'Noto Sans JP', sans-serif;
              color: ${color};
            `}
          >
            {getSexString(props.se)}
          </Typography>
        </React.Fragment>
      }
    />
  );
});
