import { Sex, GcsE, GcsV, GcsM } from 'states/ducks/patients/types';

// 数値→性別文字列取得
export const getSexString = (sex?: Sex) => {
  switch (sex) {
    case 0:
      return '不明';
    case 1:
      return '男性';
    case 2:
      return '女性';
    default:
      return '不明';
  }
};

export const addEUnit = (ge?: GcsE) => (ge ? 'E' + String(ge) : '-');

export const addVUnit = (gv?: GcsV) => (gv ? 'V' + String(gv) : '-');

export const addMUnit = (gm?: GcsM) => (gm ? 'M' + String(gm) : '-');

export const createBloodPressureString = (sb?: number | null, db?: number | null) => {
  if (sb === null || sb === undefined)
    return db === null || db === undefined ? '-/-' : `-/${db}`;
  return db === null || db === undefined ? `${sb}/-` : `${sb}/${db}`;
};

export const createSpO2Text = (sp?: number | null, sp2?: number | null) => {
  if (sp2 === null || sp2 === undefined)
    return sp === null || sp === undefined ? '-' : sp;
  return sp === null || sp === undefined ? `-→${sp2}` : `${sp}→${sp2}`;
};

export const validationCpf = (cpf?: string) => {
  if (cpf === undefined) return '';
  if (cpf.length === 5) {
    const witness = cpf[1] === '無' ? '0' : '有' ? '1' : '0';
    const bystander = cpf[3] === '無' ? '0' : '有' ? '1' : '0';
    return cpf[0] + witness + bystander;
  } else {
    return cpf;
  }
};

export const getWitnessString = (cpf?: string | null) => {
  if (cpf) {
    switch (cpf[1]) {
      case '0':
        return '無';
      case '1':
        return '有';
      default:
        return '';
    }
  } else {
    return '';
  }
};

export const getBystanderString = (cpf?: string | null) => {
  if (cpf) {
    switch (cpf[2]) {
      case '0':
        return '無';
      case '1':
        return '有';
      default:
        return '';
    }
  } else {
    return '';
  }
};

/**
 * Vital Outliers check function
 *  - prCheckIsOutlier
 *  - sbCheckIsOutlier
 *  - spo2CheckIsOutlier
 *  - rrCheckIsOutlier
 *  - btCheckIsOutlier
 */
export const prCheckIsOutlier = (value?: number | null) => {
  if (value === undefined) return false;
  if (value === null) return false;
  if (value < 50 || value >= 120) {
    return true;
  } else {
    return false;
  }
};

export const sbCheckIsOutlier = (value?: number | null) => {
  if (value === undefined) return false;
  if (value === null) return false;
  if (value < 90 || value > 180) {
    return true;
  } else {
    return false;
  }
};

export const spo2CheckIsOutlier = (value?: number | null) => {
  if (value === undefined) return false;
  if (value === null) return false;
  if (value < 90) {
    return true;
  } else {
    return false;
  }
};

export const rrCheckIsOutlier = (value?: number | null) => {
  if (value === undefined) return false;
  if (value === null) return false;
  if (value >= 40) {
    return true;
  } else {
    return false;
  }
};

export const btCheckIsOutlier = (value?: number | null) => {
  if (value === undefined) return false;
  if (value === null) return false;
  if (value < 34 || value >= 38) {
    return true;
  } else {
    return false;
  }
};
