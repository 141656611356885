import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SignInState = {
  username: string;
  password: string;
  isButtonDisabled: boolean;
  helperText: string;
  isError: boolean;
  isAuthLoading: boolean;
};

export const initialState = {
  username: '',
  password: '',
  isButtonDisabled: true,
  helperText: '',
  isError: false,
  isAuthLoading: false,
} as SignInState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        username: action.payload,
        isButtonDisabled: !action.payload.trim() || !state.password.trim(),
      };
    },
    setPassword: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        password: action.payload,
        isButtonDisabled: !state.username.trim() || !action.payload.trim(),
      };
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAuthLoading: action.payload };
    },
    loginSuccess: (state) => {
      return { ...state, helperText: 'Login Successfully', isError: false };
    },
    loginFailed: (state) => {
      return {
        ...state,
        helperText: 'Incorrect username or password',
        isError: true,
        isButtonDisabled: false,
      };
    },
    clearAuth: () => {
      return initialState;
    },
  },
});

// Reducerのエクスポート
export default authSlice.reducer;

// Action Creatorsのエクスポート
export const {
  setUsername,
  setPassword,
  setIsLoading,
  loginSuccess,
  loginFailed,
  clearAuth,
} = authSlice.actions;
