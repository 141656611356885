/**
 * Import Layer
 */
import React from 'react';
import { ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { css } from '@emotion/react';

/**
 * Types Layer
 */
export type PatientDetailTitleType = {
  title: string | JSX.Element;
};
export type PatientDetailBodyType = {
  primaryBody: { text: string | JSX.Element | JSX.Element[]; subText?: string };
  secondaryBody?: string | JSX.Element;
};
export type PatientDetailItemType = PatientDetailTitleType & PatientDetailBodyType;

/**
 * Presentational Layer
 */
const patientDetailTitleStyle = {
  listItemText: css`
    width: 20%;
  `,
  primary: css`
    font-size: 15px;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    color: rgba(255, 255, 255, 0.6);
  `,
};
const patientDetailBodyStyle = {
  listItemText: css`
    width: 80%;
  `,
  primaryText: css`
    font-size: 20px;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    color: rgba(255, 255, 255, 0.9);
  `,
  primarySubText: css`
    font-size: 12px;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    color: rgba(255, 255, 255, 0.9);
  `,
  secondary: css`
    font-size: 20px;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    color: rgba(255, 255, 255, 0.9);
  `,
};

export const PatientDetailTitle = React.memo((props: PatientDetailTitleType) => {
  const { title } = props;

  return (
    <ListItemText
      css={patientDetailTitleStyle.listItemText}
      primary={
        <React.Fragment>
          <Typography
            component="span"
            variant="body1"
            css={patientDetailTitleStyle.primary}
            data-testid="title"
          >
            {title}
          </Typography>
        </React.Fragment>
      }
    />
  );
});

export const PatientDetailBody = React.memo((props: PatientDetailBodyType) => {
  const { primaryBody, secondaryBody } = props;

  return (
    <ListItemText
      css={patientDetailBodyStyle.listItemText}
      primary={
        <React.Fragment>
          {primaryBody.subText ? (
            <>
              <Typography
                component="span"
                variant="body2"
                css={patientDetailBodyStyle.primarySubText}
                data-testid="primary-sub-body"
              >
                {primaryBody.subText}
              </Typography>
              <br />
            </>
          ) : undefined}
          <Typography
            component="span"
            variant="body1"
            css={patientDetailBodyStyle.primaryText}
            data-testid="primary-body"
          >
            {primaryBody.text}
          </Typography>
        </React.Fragment>
      }
      secondary={
        secondaryBody !== undefined ? (
          <React.Fragment>
            <Typography
              component="span"
              variant="body1"
              css={patientDetailBodyStyle.secondary}
              data-testid="secondary-body"
            >
              {secondaryBody}
            </Typography>
          </React.Fragment>
        ) : undefined
      }
    />
  );
});

export const PatientDetailItem = React.memo((props: PatientDetailItemType) => {
  const theme = useTheme();
  const { title, primaryBody, secondaryBody } = props;

  return (
    <ListItem
      alignItems="flex-start"
      css={css`
        background: ${theme.palette.background.default};
        margin: 0;
      `}
    >
      <PatientDetailTitle title={title} />
      <PatientDetailBody primaryBody={primaryBody} secondaryBody={secondaryBody} />
    </ListItem>
  );
});
