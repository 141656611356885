/**
 * Import Layer
 */
import { css } from '@emotion/react';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';

import { LogOutButton } from './LogOutButton';
import {
  DemandButton,
  VideoCallInvitationsModalButton,
  SiliusLinkMenuButton,
} from '../../GlobalButtons';
import { useHospital, useHospitalName, useSilius } from 'hooks';
import { InquiryHistoryButton } from '../../GlobalButtons/InquiryHistoryButton';
import { SettingButton } from './SettingButton';

const Header = () => {
  const hospitalName = useHospitalName();
  const { hpUID, inquiry_history_button_flag, authVersion } = useHospital();
  const shouldShowDemandButton =
    hpUID === 'C49DD00D-0D94-B42B-7A73-0A49F5D501B9' || // 日立総合病院
    hpUID === 'BD839490-6081-4709-BB67-FCD0701A58C1'; // 日立テスト病院
  const shouldShowVideoCallInvitationsModalButton =
    hpUID === '386E8F49-3705-421A-9381-A83596E87177' || // 湘南鎌倉総合病院
    hpUID === 'E0EF4BD2-9A4A-4B51-B8A2-41661C218C4D' || // 藤沢市民病院
    hpUID === '856FAC53-2994-4A22-8829-822A54E667E1' || // 藁【テスト】藤沢テスト病院
    hpUID === '684E6FB3-36EB-4722-BDE0-ED79D1640D67' || // 藁【テスト】藤沢テスト病院(本番ダッシュボード)
    hpUID === '9C74A46F-D23F-48DF-A000-8DB8198DA9D3' || //湘南藤沢徳洲会病院
    hpUID === 'CFE543C1-07CA-4DB0-BAE0-EC1603608E23'; // 藤沢湘南台病院
  const shouldShowSiliusLinkMenuButton = useSilius();

  return (
    <Box>
      <AppBar
        position="static"
        css={css`
          background-color: #222222;
        `}
      >
        <Toolbar
          css={css`
            border-bottom: solid 1px;
            gap: 12px;
          `}
        >
          <Typography
            variant="h5"
            component="div"
            css={css`
              flex-grow: 1;
              font-weight: bold;
              margin-left: -8px;
            `}
          >
            {hospitalName}
          </Typography>
          {authVersion === 'v2' && <SettingButton />}
          {shouldShowSiliusLinkMenuButton && <SiliusLinkMenuButton />}
          {shouldShowDemandButton && <DemandButton hospitalId={hpUID} />}
          {shouldShowVideoCallInvitationsModalButton && (
            <VideoCallInvitationsModalButton />
          )}
          {inquiry_history_button_flag && <InquiryHistoryButton />}
          <LogOutButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export { Header };
