import { Patient } from 'states/ducks/patients/types';

export const initialPatient: Patient = {
  ambulancerecord_UID: '',
  ac: null,
  acclog: '',
  ag: null,
  am: '',
  amUID: '',
  amz: {
    lvo: '',
    cpss: '',
    cvdd: '',
    cvr: '',
    cvp: null,
    com: '',
    vst: '',
  },
  ar: '',
  bd: '',
  bt: null,
  cc: '',
  co: '',
  cpf: '',
  cpr: '',
  cptd: '',
  cr: '',
  cvb: null,
  cvf: null,
  da: '',
  db: null,
  dc: '',
  dd: '',
  de: '',
  dh: '',
  di: '',
  dp: '',
  dq: '',
  drcar_inquiry: null,
  drheli_inquiry: null,
  ea: null,
  em: '',
  ge: null,
  gm: null,
  gv: null,
  ha: '',
  hd: '',
  ho: '',
  hpUID: '',
  id: '',
  jc: '',
  jn: '',
  me: '',
  na: '',
  naf: '',
  nsc: 0,
  of: null,
  or: '',
  ot: '',
  pd: '',
  ph: '',
  pi: '',
  pr: null,
  qa: '',
  qhpUID: '',
  re: '',
  rr: null,
  sb: null,
  se: null,
  simultaneousInquiry: null,
  sp: null,
  sp2: null,
  tff: 0,
  ty: null,
  vt: '',
  wp: '',
  vitals: [
    {
      rr: null,
      pr: null,
      bt: null,
      or: '',
      sp2: null,
      of: null,
      sp: null,
      db: null,
      sb: null,
      createdAt: '',
    },
  ],
  createdAt: '',
  updatedAt: '',
};
