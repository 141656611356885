import { validateBirthDay } from 'states/ducks/patients/types';
import { Sex, GcsE, GcsV, GcsM } from 'states/ducks/patients/types';

const getSexString = (sex?: Sex) => {
  switch (sex) {
    case 0:
      return '不明';
    case 1:
      return '男';
    case 2:
      return '女';
    default:
      return '不明';
  }
};

const generateBirthdate = (bd: string) => {
  const validated = validateBirthDay(bd);
  if (validated === '') return validated;
  const year = validated.slice(0, 4);
  const month = validated.slice(4, 6);
  const day = validated.slice(6, 8);

  const date = new Date(Number(year), Number(month) - 1, Number(day));
  const options = {
    era: 'narrow',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const birthday = date.toLocaleDateString('ja-JP-u-ca-japanese', options);
  const era = birthday.split('/')[0]; // S63 or H23 or ...
  return `${year}(${era})/${month}/${day}`;
};

const convertNumberIntoString = (num: GcsE | GcsV | GcsM | number | null) => {
  return num === null ? '' : String(num);
};

const estimateArrivalTime = (ea?: number | null, createdAt?: string) => {
  if (!ea) return '';
  let dateObj: Date;
  if (!createdAt) {
    dateObj = new Date();
  } else {
    dateObj = new Date(createdAt);
  }
  dateObj.setMinutes(dateObj.getMinutes() + ea);
  const year = dateObj.getFullYear();
  const month = ('0' + String(dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + String(dateObj.getDate())).slice(-2);
  const hours = ('0' + String(dateObj.getHours())).slice(-2);
  const minutes = ('0' + String(dateObj.getMinutes())).slice(-2);
  const seconds = ('0' + String(dateObj.getSeconds())).slice(-2);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+09:00`;
};

export { getSexString, generateBirthdate, convertNumberIntoString, estimateArrivalTime };
