/**
 * Import Layer
 */
import React from 'react';
import { css } from '@emotion/react';
import Stack from '@mui/material/Stack';

import { FooterButton } from './FooterButton';
import { Box } from '@mui/material';

/**
 * Presentational Layer
 */

const Footer = React.memo(() => {
  return (
    <Box
      css={css`
        height: 96px;
        width: 100%;
        background-color: #545454;
        z-index: 10;
      `}
    >
      <Stack
        component="footer"
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        css={css`
          height: 100%;
          width: 100%;
        `}
      >
        <FooterButton action={'ACCEPT'} />
        <FooterButton action={'REJECT'} />
        <FooterButton action={'REQUEST'} />
      </Stack>
    </Box>
  );
});

export { Footer };
