import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import Observable from 'zen-observable';

import { clearImages, setImages, addImage } from 'states/ducks/s3images/slice';
import { setSubscriptionError } from 'states/ducks/error/slice';
import { listImagesData } from 'graphql/queries';
import { onCreateImagesData } from 'graphql/subscriptions';
import { usePatientsState } from 'hooks';

/**
 * Types Layer
 */
export type ListImagesDataQueryType = {
  listImagesData: {
    items: ImageResponse[] | [] | null;
  };
};
type CreateImagesDataType = {
  data: {
    onCreateImagesData: ImageResponse;
  };
};
export type CreateImagesSubscriptionEventType = { value: CreateImagesDataType };

/**
 * AppSync の Query / Subscription をモデル化
 */
export const ImageModel = {
  listImages: (
    selectedPatientId: string
  ): Promise<GraphQLResult<ListImagesDataQueryType>> => {
    return API.graphql({
      query: listImagesData,
      variables: { patientId: selectedPatientId },
    });
  },
  subscribeOnCreateImage: (selectedPatientId: string) => {
    return API.graphql({
      query: onCreateImagesData,
      variables: { patientId: selectedPatientId },
    });
  },
};

export const ListImagesEffect = () => {
  const { selectedPatientId } = usePatientsState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearImages());
    const getImagesData = async () => {
      const response = (await ImageModel.listImages(
        selectedPatientId
      )) as GraphQLResult<ListImagesDataQueryType>;
      if (response.data) {
        const imagesData = response.data.listImagesData?.items ?? [];
        dispatch(setImages(imagesData));
      }
    };
    getImagesData();
  }, [dispatch, selectedPatientId]);
  return null;
};

export const CreateSubscriptionImageEffect = () => {
  const { selectedPatientId } = usePatientsState();
  const dispatch = useDispatch();
  useEffect(() => {
    const client = ImageModel.subscribeOnCreateImage(
      selectedPatientId
    ) as Observable<object>;
    const subscription = client.subscribe({
      next: (event: CreateImagesSubscriptionEventType) => {
        const {
          value: { data },
        } = event;
        const imageData = data.onCreateImagesData;
        dispatch(addImage(imageData));
      },
      error: () => {
        dispatch(setSubscriptionError({ CreateSubscriptionImageEffect: true }));
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, selectedPatientId]);
  return null;
};

export const ImagesEffect = () => {
  return (
    <>
      <ListImagesEffect />
      <CreateSubscriptionImageEffect />
    </>
  );
};
