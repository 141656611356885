import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from '@reduxjs/toolkit';

import { Patient } from './types';

export const patientsAdaper = createEntityAdapter<Patient>({
  selectId: (patient) => patient.id,
  sortComparer: (data, nextData) => (data.createdAt < nextData.createdAt ? 1 : -1),
});

export interface PatientsState extends EntityState<Patient> {
  selectedPatientId: string;
  filterCondition: 'all' | 'doctorheli' | 'doctorcar' | 'rescue' | 'referral';
  qrCodeUrl: string;
}

export const initialState: PatientsState = patientsAdaper.getInitialState({
  selectedPatientId: '',
  filterCondition: 'all',
  qrCodeUrl: '',
});

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    /**
     * 患者一覧データの取得
     */
    setPatients: (state, action: PayloadAction<Patient[]>) => {
      patientsAdaper.setAll(state, action.payload);
      if (action.payload.length > 0) {
        state.selectedPatientId = state.ids[0] as string;
      } else {
        state.selectedPatientId = '';
      }
    },
    /**
     * 患者データの追加
     */
    addPatient: (state, action: PayloadAction<Patient>) => {
      patientsAdaper.addOne(state, action.payload);
      state.selectedPatientId = action.payload.id;
    },
    /**
     * 患者データの更新
     */
    updatePatient: (state, action: PayloadAction<Patient>) => {
      patientsAdaper.upsertOne(state, action.payload);
    },
    /**
     * 患者データの削除
     */
    dropPatient: (state, action: PayloadAction<Patient>) => {
      if (state.selectedPatientId === action.payload.id) {
        const updatedPatientIndex = state.ids.findIndex((id) => id === action.payload.id);
        const isSelectedFirstIndex = state.selectedPatientId === (state.ids[0] as string);
        if (isSelectedFirstIndex && state.ids.length >= 2) {
          const id = state.ids[updatedPatientIndex + 1];
          state.selectedPatientId = id as string;
        } else if (isSelectedFirstIndex && state.ids.length === 1) {
          patientsAdaper.removeAll(state);
          state.selectedPatientId = '';
        } else {
          const id = state.ids[updatedPatientIndex - 1];
          state.selectedPatientId = id as string;
        }
      }
      patientsAdaper.removeOne(state, action.payload.id);
    },
    /**
     * 患者データの選択
     */
    selectPatient: (state, action: PayloadAction<string>) => {
      state.selectedPatientId = action.payload;
    },
    /**
     * 全患者データの選択
     */
    clearPatients: () => {
      return initialState;
    },
    /**
     * フィルター条件の更新
     */
    setFilterCondition: (
      state,
      action: PayloadAction<PatientsState['filterCondition']>
    ) => {
      state.filterCondition = action.payload;
    },
    /**
     * エンコード前QR Code文字列の更新
     */
    setQrCodeUrl: (state, action: PayloadAction<string>) => {
      state.qrCodeUrl = action.payload;
    },
  },
});

// Reducerのエクスポート
export default patientsSlice.reducer;

// Action Creatorsのエクスポート
export const {
  setPatients,
  addPatient,
  updatePatient,
  dropPatient,
  clearPatients,
  selectPatient,
  setFilterCondition,
  setQrCodeUrl,
} = patientsSlice.actions;
