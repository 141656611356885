/**
 * Import Layer
 */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { css } from '@emotion/react';

import { useSignOut } from 'hooks';

/**
 * Types Layer
 */
type LogOutButtonUIType = { onClick: () => void };

/**
 * Presentational Layer
 */
export const LogOutButtonUI = React.memo((props: LogOutButtonUIType) => {
  return (
    <Button
      variant="outlined"
      size="large"
      startIcon={<PowerSettingsNewIcon />}
      css={css`
        font-weight: bold;
        font-size: 18px;
        font-family: 'Noto Sans JP', sans-serif;
        &:hover {
          opacity: 0.8;
        }
      `}
      onClick={props.onClick}
    >
      ログアウト
    </Button>
  );
});

/**
 * Conrainer Layer
 */
export const LogOutButton = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const handleLogOut = useCallback(async () => {
    await signOut();
    navigate('/signin');
  }, [signOut, navigate]);

  return <LogOutButtonUI onClick={handleLogOut} />;
};
