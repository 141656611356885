/**
 * Import Layer
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { ImageList, ImageListItem } from '@mui/material';
import { css } from '@emotion/react';
import { SlideshowOutlined } from '@mui/icons-material';

import { imagesSelectors } from 'states/index';
import { useBreakPoints, useClientDimensions } from 'hooks';
import { ImageDownload } from './ImageDownload';

/**
 * Types Layer
 */
type ImageListViewType = { handleOpenImage: (key: string) => void };

/**
 * Container Layer
 */
const tabletWidthStyle = (clientWidth: number) => css`
  width: ${clientWidth / 3 - 100}px;
`;
const pcWidthStyle = css`
  width: 248px;
`;
const imageListItemStyle = css`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111111;
  &:hover {
    opacity: 0.65;
    cursor: pointer;
  }
`;

export const ImageListView = React.memo((props: ImageListViewType) => {
  const { isTabletSize } = useBreakPoints();
  const { width } = useClientDimensions();
  const images = useSelector(imagesSelectors.selectAll);

  return (
    <ImageList
      rowHeight={isTabletSize ? width / 3 - 124 : 124}
      cols={isTabletSize ? 1 : 2}
      variant="standard"
      css={isTabletSize ? tabletWidthStyle(width) : pcWidthStyle}
    >
      {images.map((image) => (
        <ImageListItem
          key={image.id}
          onClick={() => props.handleOpenImage(`${image.patientId}/${image.id}`)}
          css={imageListItemStyle}
        >
          <div className="MuiGridListTile-imgFullWidth">
            <ImageDownload s3Key={`${image.patientId}/${image.id}`} />
            {image.isVideo && (
              <>
                <SlideshowOutlined
                  css={css`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 64px;
                    height: 64px;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 4px;
                  `}
                />
                {image.videoDuration != null && (
                  <div
                    css={css`
                      position: absolute;
                      padding: 2px 4px;
                      bottom: 4px;
                      right: 4px;
                      background-color: rgba(0, 0, 0, 0.5);
                      border-radius: 2px;
                      color: white;
                      font-size: 14px;
                      font-weight: bold;
                      line-height: 1.2;
                    `}
                  >
                    {stringfyDuration(image.videoDuration)}
                  </div>
                )}
              </>
            )}
          </div>
        </ImageListItem>
      ))}
    </ImageList>
  );
});

function stringfyDuration(duration: number): string {
  const seconds = duration % 60;
  const minutes = Math.floor((duration % 3600) / 60);
  const hours = Math.floor(duration / 3600);

  const paddedSeconds = seconds.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');

  if (hours > 0) {
    return `${hours}:${paddedMinutes}:${paddedSeconds}`;
  } else {
    return `${minutes}:${paddedSeconds}`;
  }
}
