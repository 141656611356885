/**
 * Import Layer
 */
import React from 'react';
import { Box, Typography, Paper, Grid, Stack } from '@mui/material';
import { css } from '@emotion/react';

import { useSelectedPatient } from 'hooks';
import { addEUnit, addVUnit, addMUnit } from './utils';
import { Jcs, GcsE, GcsV, GcsM } from 'states/ducks/patients/types';

/**
 * Types Layer
 */
type FieldItemType = {
  name: string;
  value: string | number | JSX.Element;
};
type JcsType = { jc?: Jcs };
type GcsType = { ge?: GcsE; gv?: GcsV; gm?: GcsM };
type JcsGcsType = JcsType & GcsType;

/**
 * Presentational Layer
 */
const defaultPaperStyle = css`
  padding: 5px;
  height: 42px;
  display: grid;
  grid-template-columns: 38px 1fr;
  border-color: #ffffff;
  background: #292929;
`;
const jcsPaperStyle = css`
  ${defaultPaperStyle}
  width: 100px;
  min-width: 100px;
`;
const gcsPaperStyle = css`
  ${defaultPaperStyle}
  width: 148px;
  min-width: 148px;
`;

const FieldItem = (props: FieldItemType) => {
  return (
    <Paper
      variant="outlined"
      square={true}
      css={
        props.name === 'JCS' ? jcsPaperStyle : props.name === 'GCS' ? gcsPaperStyle : null
      }
    >
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Typography
          gutterBottom
          css={css`
            font-size: 10px;
            font-weight: bold;
            font-family: 'Noto Sans JP', sans-serif;
            color: rgba(255, 255, 255, 0.6);
          `}
        >
          {props.name}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        css={css`
          height: 100%;
          width: 100%;
        `}
      >
        <Typography
          css={css`
            font-size: 18px;
            font-weight: bold;
            font-family: 'Noto Sans JP', sans-serif;
          `}
        >
          {props.value}
        </Typography>
      </Grid>
    </Paper>
  );
};

const JcsUI = React.memo((props: JcsType) => {
  const jcs = props.jc === undefined ? '-' : props.jc === '' ? '-' : props.jc;
  return <FieldItem name={'JCS'} value={jcs} />;
});

const GcsUI = React.memo((props: GcsType) => {
  const gcs = (
    <>
      {addEUnit(props.ge)}&nbsp;&nbsp;{addVUnit(props.gv)}&nbsp;&nbsp;{addMUnit(props.gm)}
    </>
  );
  return <FieldItem name={'GCS'} value={gcs} />;
});

export const JcsGcsUI = React.memo((props: JcsGcsType) => {
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <JcsUI jc={props.jc} />
        <GcsUI ge={props.ge} gv={props.gv} gm={props.gm} />
      </Stack>
    </Box>
  );
});

/**
 * Container Layer
 */
export const JcsGcs = () => {
  const { jc, ge, gv, gm } = useSelectedPatient();
  return <JcsGcsUI jc={jc} ge={ge} gv={gv} gm={gm} />;
};
