/**
 * Import Layer
 */
import React from 'react';

import { PatientDetailItem } from '../PatientDetailParts';
import { useSelectedPatient } from 'hooks';
import { checkEmpty, insertNewLineByAtSign, createCovic19Text } from '../utils';

/**
 * Types Layer
 */
type Covid19Type = { cvf?: boolean | null; cvb?: boolean | null; cptd?: string };

/**
 * Presentational Layer
 */
export const Covid19UI = React.memo((props: Covid19Type) => {
  const { cvf, cvb, cptd } = props;
  const covic19Text = createCovic19Text(cvf, cvb, cptd);
  const primaryBody = { text: insertNewLineByAtSign(checkEmpty(covic19Text)) };
  const title = (
    <span>
      新型コロナ
      <br />
      チェック
    </span>
  );

  return <PatientDetailItem title={title} primaryBody={primaryBody} />;
});

/**
 * Container Layer
 */
export const Covid19 = () => {
  const { cvf, cvb, cptd } = useSelectedPatient();

  return <Covid19UI cvf={cvf} cvb={cvb} cptd={cptd} />;
};
