/**
 * Import Layer
 */
import React from 'react';
import { Button } from '@mui/material';
// import PrintIcon from '@mui/icons-material/Print';
import { css } from '@emotion/react';

import { useSelectedPatient, usePrintAmbulanceRecordButton, useHospital } from 'hooks';

/**
 * Types Layer
 */
type PrintAmbulanceRecordButtonUIType = { url: string; label: string };

/**
 * Presentational Layer
 */
export const PrintAmbulanceRecordButtonUI = React.memo(
  (props: PrintAmbulanceRecordButtonUIType) => {
    return (
      <Button
        size="small"
        // startIcon={<PrintIcon />}
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        css={css`
          padding: 5px 10px;
          height: 40px;
          width: 118px;
          font-size: 16px;
          font-weight: bold;
          font-family: 'Noto Sans JP', sans-serif;
          color: #333333;
          background-color: #f0ffff;
          &:hover {
            opacity: 0.8;
            background-color: #f0ffff;
          }
        `}
      >
        {props.label}
      </Button>
    );
  }
);

/**
 * Conrainer Layer
 */
export const PrintAmbulanceRecordButton = () => {
  const { ambulancerecord_UID } = useSelectedPatient();
  const { hostUrl } = useHospital();
  const path = '/fmi/webd/NSER_mobile';
  const script = 'print_ambulance_record(transportID)';
  const url = `${hostUrl}${path}?script=${script}&param=${ambulancerecord_UID}`;
  const { label } = usePrintAmbulanceRecordButton();

  return <PrintAmbulanceRecordButtonUI url={url} label={label} />;
};
