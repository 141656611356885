import { useEffect } from 'react';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { useDispatch } from 'react-redux';
import Observable from 'zen-observable';

import { listMessages } from 'graphql/queries';
import { onChangeMessage } from 'graphql/subscriptions';
import { Message, setMessages } from 'states/ducks/messages/slice';
import { useHospital } from 'hooks';

export type ListMessagesQueryType = {
  listMessages: {
    messages: Message[];
  };
};

export type ChangeMessageSubscriptionEventType = {
  value: {
    data: {
      onChangeMessage: {
        messages: Message[];
      };
    };
  };
};

export const MessagesModel = {
  listMessages: (hpUID: string): Promise<GraphQLResult<ListMessagesQueryType>> => {
    return API.graphql({
      query: listMessages,
      variables: { hpUID: hpUID },
    });
  },
  subscribeOnChangeMessage: (hpUID: string | null): Observable<object> => {
    return API.graphql({
      query: onChangeMessage,
      variables: { hpUID: hpUID },
    });
  },
};

export const ListMessagesEffect = () => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const getMessagesData = async () => {
      if (hpUID !== null) {
        const response = (await MessagesModel.listMessages(
          hpUID
        )) as GraphQLResult<ListMessagesQueryType>;
        if (response.data) {
          const messages = response.data.listMessages.messages;
          dispatch(setMessages(messages));
        }
      }
    };
    getMessagesData();
  }, [hpUID, dispatch]);

  return null;
};

export const SubscribeOnChangeMessageEffect = () => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = MessagesModel.subscribeOnChangeMessage(hpUID).subscribe({
      next: (payload: ChangeMessageSubscriptionEventType) => {
        const messages = payload.value.data.onChangeMessage.messages;
        dispatch(setMessages(messages));
      },
      error: (error: Error) => {
        console.error(error);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [hpUID, dispatch]);

  return null;
};

export const MessagesEffect = () => {
  return (
    <>
      <ListMessagesEffect />
      <SubscribeOnChangeMessageEffect />
    </>
  );
};
