/**
 * Import Layer
 */
import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';

import { QrCodeButton } from './src/QrCodeButton';
import { QrCodeModal } from './src/QrCodeModal';
import { usePatientsState } from 'hooks';

/**
 * Conrainer Layer
 */
export const QrCodeImage = () => {
  const { qrCodeUrl } = usePatientsState();
  const [isOpen, setIsOpen] = useState(false);
  const handleQrCodeOpen = useCallback(() => setIsOpen(true), []);
  const handleQrCodeClose = useCallback(() => setIsOpen(false), []);

  return (
    <Box>
      <QrCodeButton qrCodeUrl={qrCodeUrl} handleQrCodeOpen={handleQrCodeOpen} />
      <QrCodeModal
        isOpen={isOpen}
        qrCodeUrl={qrCodeUrl}
        handleQrCodeClose={handleQrCodeClose}
      />
    </Box>
  );
};
