/**
 * Import Layer
 */
import React from 'react';
import { useTheme } from '@mui/material';
import { css } from '@emotion/react';

import { useSelectedPatient } from 'hooks';
import { TransferBadge } from '../../utils/TransferBadge';

/**
 * Types Layer
 */
type RescueType = { am?: string; tff?: 0 | 1 };
type ChiefComplaintType = { pi?: string };
type RescueChiefComplaintType = RescueType & ChiefComplaintType;

/**
 * Presentational Layer
 */
const Rescue: React.VFC<RescueType> = React.memo(({ am, tff }) => {
  return (
    <div
      css={css`
        display: flex;
        gap: 0.5rem;
      `}
    >
      {tff === 1 ? <TransferBadge /> : null}
      <span
        css={css`
          font-size: 12px;
          font-family: 'Noto Sans JP', sans-serif;
          color: #ffffff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {!am ? '救急隊名不明' : am}
      </span>
    </div>
  );
});

const ChiefComplaint = React.memo((props: ChiefComplaintType) => {
  return (
    <div
      css={css`
        font-size: 36px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
    >
      {props.pi === undefined ? '-' : props.pi === '' ? '-' : props.pi}
    </div>
  );
});

export const RescueChiefComplaintUI = React.memo((props: RescueChiefComplaintType) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        background: ${theme.palette.background.default};
      `}
    >
      <Rescue am={props.am} tff={props.tff} />
      <ChiefComplaint pi={props.pi} />
    </div>
  );
});

/**
 * Container Layer
 */
export const RescueChiefComplaint = () => {
  const { am, tff, pi } = useSelectedPatient();
  return <RescueChiefComplaintUI am={am} tff={tff} pi={pi} />;
};
