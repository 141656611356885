/**
 * Import Layer
 */
import React, { useState } from 'react';
import { Button, Modal, Fade, Box } from '@mui/material';
import { css } from '@emotion/react';

import { useInvitationIds } from 'hooks';
import { ModalPaper } from './ModalPaper';

/**
 * Types Layer
 */
type VideoCallInvitationsModalButtonUIProps = {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  invitationIds: string[];
};

/**
 * Presentational Layer
 */
const openButtonStyle = css`
  width: 116px;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  background-color: #f0ffff;
  &:hover {
    opacity: 0.8;
    background-color: #f0ffff;
  }
`;

const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoCallInvitationsModalButtonUI: React.VFC<
  VideoCallInvitationsModalButtonUIProps
> = ({ isOpen, handleOpen, handleClose, invitationIds }) => {
  return (
    <>
      <Button onClick={handleOpen} css={openButtonStyle}>
        招待一覧
      </Button>
      <Modal open={isOpen} onClose={handleClose} css={modalStyle}>
        <Fade in={isOpen}>
          <Box>
            <ModalPaper invitationIds={invitationIds} handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

/**
 * Conrainer Layer
 */
export const VideoCallInvitationsModalButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const invitationIds = useInvitationIds();
  return (
    <VideoCallInvitationsModalButtonUI
      isOpen={isOpen}
      handleOpen={() => setIsOpen(true)}
      handleClose={() => setIsOpen(false)}
      invitationIds={invitationIds}
    />
  );
};
