import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: true,
} as RouteState;

const routeSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      return { isLoading: action.payload };
    },
  },
});

// Reducerのエクスポート
export default routeSlice.reducer;

// Action Creatorsのエクスポート
export const { setIsLoading } = routeSlice.actions;
