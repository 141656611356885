import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#292929',
    },
  },
  breakpoints: {
    values: {
      xs: 0, // default
      sm: 600, // default
      md: 1025, // default: 900
      lg: 1200, // default
      xl: 1536, // default
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#000000',
          },
        },
      },
    },
  },
});
