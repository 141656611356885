import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const NetworkEffect = () => {
  useEffect(() => {
    const handleDisconnect = () => {
      const disconnectStartTime = new Date();
      console.log('Network Disconnect');
      const handleReconnect = () => {
        const reconnectTime = new Date();
        console.log('Network Reconnect');
        Sentry.captureMessage('Network Disconnect', {
          extra: {
            disconnectStartTime: disconnectStartTime.toISOString(),
            reconnectTime: reconnectTime.toISOString(),
          },
        });
        window.removeEventListener('online', handleReconnect);
      };
      window.addEventListener('online', handleReconnect);
    };

    window.addEventListener('offline', handleDisconnect);

    return () => {
      window.removeEventListener('offline', handleDisconnect);
    };
  }, []);

  return null;
};
