import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setQrCodeUrl } from 'states/ducks/patients/slice';
import { useQrCodeCsvString, useQrCompressionFlag } from 'hooks';
import { qrApiRequest } from 'utils';

export const QrCodeApiEffect = () => {
  const dispatch = useDispatch();
  const qr_compression_flag = useQrCompressionFlag();
  const qrCodeCsvString = useQrCodeCsvString();
  useEffect(() => {
    const getQrImageUrl = async () => {
      // const qr_compression_flag = getQrCompressionFlag();
      const qrImgUrl = await qrApiRequest(qr_compression_flag, qrCodeCsvString);
      dispatch(setQrCodeUrl(qrImgUrl));
    };
    getQrImageUrl();
  }, [dispatch, qr_compression_flag, qrCodeCsvString]);
  return null;
};

export const QrCodeEffect = () => {
  return (
    <>
      <QrCodeApiEffect />
    </>
  );
};
