/**
 * Import Layer
 */
import React from 'react';
import { CssBaseline, Box, Container, Avatar, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { css } from '@emotion/react';

import {
  UsernameForm,
  PasswordForm,
  UsernameFormType,
  PasswordFormType,
} from './src/Forms';
import { SignInButton, SignInButtonType } from './src/SignInButton';

/**
 * Types Layer
 */
type SignInFormUIType = UsernameFormType & PasswordFormType & SignInButtonType;

export const SignInFormUI = React.memo((props: SignInFormUIType) => {
  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        css={css`
          padding-top: 10rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Avatar
          css={css`
            margin: 0.5rem;
            background-color: #f48fb1;
          `}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Box
          css={css`
            margin-top: 0.5rem;
          `}
        >
          <UsernameForm
            isError={props.isError}
            handleUsernameChange={props.handleUsernameChange}
            handleKeyPress={props.handleKeyPress}
          />
          <PasswordForm
            isError={props.isError}
            helperText={props.helperText}
            handlePasswordChange={props.handlePasswordChange}
            handleKeyPress={props.handleKeyPress}
          />
          <SignInButton
            isButtonDisabled={props.isButtonDisabled}
            handleSignIn={props.handleSignIn}
          />
        </Box>
      </Box>
    </Container>
  );
});
