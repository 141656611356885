import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import Observable from 'zen-observable';
import { toast } from 'react-hot-toast';

import type { VideoCallInvitation } from 'states/ducks/invitations/slice';
import {
  setVideoCallInvitations,
  addVideoCallInvitation,
} from 'states/ducks/invitations/slice';
import { listInvitations, getInvitationUrl } from 'graphql/queries';
import { onCreateInvitation } from 'graphql/subscriptions';
import { useHospital } from 'hooks';
import { VideoCallInvitationToast } from 'views/components/Dashboard/src/Toast/VideoCallInvitationToast';

export type ListInvitationsQueryType = {
  listInvitations: VideoCallInvitation[] | [];
};

export type GetInvitationUrlQueryType = {
  getInvitationUrl: {
    url: string;
  };
};

export type CreateInvitationSubscriptionEventType = {
  value: {
    data: {
      onCreateInvitation: VideoCallInvitation;
    };
  };
};

/**
 * AppSync の Query / Subscription をモデル化
 */
export const InvitationsModel = {
  listInvitations: (hpUID: string): Promise<GraphQLResult<ListInvitationsQueryType>> => {
    return API.graphql({
      query: listInvitations,
      variables: { hpUID: hpUID },
    });
  },
  getInvitationUrl: (
    invitationId: string
  ): Promise<GraphQLResult<GetInvitationUrlQueryType>> => {
    return API.graphql({
      query: getInvitationUrl,
      variables: { invitationId: invitationId },
    });
  },
  subscribeOnCreateInvitation: (hpUID: string | null): Observable<object> => {
    return API.graphql({
      query: onCreateInvitation,
      variables: { hpUID: hpUID },
    });
  },
};

export const ListInvitationsEffect = () => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const getInvitationsData = async () => {
      if (hpUID !== null) {
        const response = (await InvitationsModel.listInvitations(
          hpUID
        )) as GraphQLResult<ListInvitationsQueryType>;
        if (response.data) {
          const patientsData = response.data.listInvitations;
          dispatch(setVideoCallInvitations(patientsData));
        }
      }
    };
    getInvitationsData();
  }, [dispatch, hpUID]);
  return null;
};

export const CreateSubscriptionInvitationEffect = React.memo(() => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = InvitationsModel.subscribeOnCreateInvitation(hpUID).subscribe({
      next: (payload: CreateInvitationSubscriptionEventType) => {
        if (payload.value.data.onCreateInvitation) {
          const invitation = payload.value.data.onCreateInvitation;
          toast.custom(
            <VideoCallInvitationToast id={invitation.id} name={invitation.teamName} />,
            { duration: 60000 }
          );
          dispatch(addVideoCallInvitation(invitation));
        }
      },
      error: (error: Error) => {
        console.error(error);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, hpUID]);
  return null;
});

export const InvitationsEffect = () => {
  return (
    <>
      <ListInvitationsEffect />
      <CreateSubscriptionInvitationEffect />
    </>
  );
};
