/**
 * Import Layer
 */
import React from 'react';

import { PatientDetailItem } from '../PatientDetailParts';
import { useSelectedPatient } from 'hooks';
import {
  estimateArrivalTime,
  convertIso8601Format,
  generateArrivalTimeView,
} from '../utils';

/**
 * Types Layer
 */
type EstimatedArrivalType = { ea?: number | null; ha?: string; createdAt?: string };

/**
 * Presentational Layer
 */
export const EstimatedArrivalUI = React.memo((props: EstimatedArrivalType) => {
  const { ea, ha, createdAt } = props;
  const refinedHa = convertIso8601Format(ha);
  const primaryBody =
    refinedHa !== ''
      ? { text: generateArrivalTimeView(refinedHa, ea) }
      : { text: estimateArrivalTime(createdAt, ea) };

  return <PatientDetailItem title={'病院到着'} primaryBody={primaryBody} />;
});

/**
 * Container Layer
 */
export const EstimatedArrival = () => {
  const { ea, ha, createdAt } = useSelectedPatient();

  return <EstimatedArrivalUI ea={ea} ha={ha} createdAt={createdAt} />;
};
