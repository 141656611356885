export type Amz = {
  lvo?: string | null;
  cpss?: string | null;
  cvdd?: string | null;
  cvr?: 'h' | 'l' | '' | null;
  cvp?: 1 | 2 | null;
  com?: string | null;
  vst?: string | null;
  mco?: string | null;
  freetextModalButtonLabel?: string | null;
  freetextModalContent?: string | null;
};

export type Vitals = {
  rr: number | null;
  pr: number | null;
  bt: number | null;
  or: string;
  sp2: number | null;
  of: number | null;
  sp: number | null;
  db: number | null;
  sb: number | null;
  createdAt: string;
};

declare const validBirthDay: unique symbol;
type BirthDaty = string & { [validBirthDay]: never };

export const validateBirthDay = (input: string) => {
  const isBirthDayString = /^[0-9]{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(
    input
  );
  return isBirthDayString ? (input as BirthDaty) : '';
};

export type Acceptance = 0 | 1 | 2 | 3 | null;
export type Sex = 0 | 1 | 2 | 9 | null;
export type Jcs =
  | '0'
  | '1'
  | '2'
  | '3'
  | '10'
  | '20'
  | '30'
  | '100'
  | '200'
  | '300'
  | '1桁'
  | '2桁'
  | '3桁'
  | '';
export type GcsE = 4 | 3 | 2 | 1 | null;
export type GcsV = 5 | 4 | 3 | 2 | 1 | null;
export type GcsM = 6 | 5 | 4 | 3 | 2 | 1 | null;

export type Patient = {
  ambulancerecord_UID: string;
  ac: Acceptance;
  acclog: string;
  ag: number | null;
  am: string;
  amUID: string;
  amz: Amz;
  ar: string;
  bd: string;
  bt: number | null;
  cc: string;
  co: string;
  cpf: string;
  cpr: string;
  cptd: string;
  cr: string;
  cvb: boolean | null;
  cvf: boolean | null;
  da: string;
  db: number | null;
  dc: string;
  dd: string;
  de: string;
  dh: string;
  di: string;
  drcar_inquiry: boolean | null;
  drheli_inquiry: boolean | null;
  dp: string;
  dq: string;
  ea: number | null;
  em: string;
  ge: GcsE;
  gm: GcsM;
  gv: GcsV;
  ha: string;
  hd: string;
  ho: string;
  hpUID: string;
  id: string;
  jc: Jcs;
  jn: string;
  me: string;
  na: string;
  naf: string;
  nsc: 0 | 1 | null;
  of: number | null;
  or: string;
  ot: string;
  pd: string;
  ph: string;
  pi: string;
  pr: number | null;
  qa: string;
  qhpUID: string;
  re: string;
  rr: number | null;
  sb: number | null;
  se: Sex;
  simultaneousInquiry: boolean | null;
  peerHospitals?: {
    id: string;
    hospitalName: string;
    response: 'ACCEPT' | 'REJECT' | 'OTHER' | null;
  }[];
  destination_hospital?: string | null;
  sp: number | null;
  sp2: number | null;
  tff: 0 | 1;
  ty: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 11 | 12 | null;
  vt: string;
  wp: string;
  vitals: Vitals[];
  createdAt: string;
  updatedAt: string;
  lastClicked?: LastClickedButtonType;
};

export type LastClickedButtonType = 'ACCEPT' | 'REJECT' | 'REQUEST';
