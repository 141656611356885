import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHospital } from 'hooks';

export const SiliusLinkMenuButton = () => {
  const { hpUID } = useHospital();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  const hostName = 'nsermo-fms002.nser.jp';

  return (
    <div>
      <Button
        id="silius-button"
        aria-controls={open ? 'silius-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        SIRIUS
      </Button>
      <Menu
        id="silius-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'silius-button',
        }}
      >
        <MenuItem
          onClick={() => {
            const url = `https://${hostName}/fmi/webd/Demand_status?script=start_input(user_id)&param=${hpUID}`;
            window.open(url, '_blank');
            handleClose();
          }}
        >
          応需入力
        </MenuItem>
        <MenuItem
          onClick={() => {
            const url = `https://${hostName}/fmi/webd/SIRIUS?script=from_dashboard_view_demandstatus(hospital_uid)&param=${hpUID}`;
            window.open(url, '_blank');
            handleClose();
          }}
        >
          応需確認
        </MenuItem>
        <MenuItem
          onClick={() => {
            const url = `https://${hostName}/fmi/webd/SIRIUS?script=from_dashboard_view_emergency_duty(hospital_uid)&param=${hpUID}`;
            window.open(url, '_blank');
            handleClose();
          }}
        >
          当番表
        </MenuItem>
        <MenuItem
          onClick={() => {
            const url = process.env.REACT_APP_TRACKING_BASEURL ?? '';
            window.open(url, '_blank');
            handleClose();
          }}
        >
          動態管理
        </MenuItem>
      </Menu>
    </div>
  );
};
