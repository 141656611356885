/**
 * import Layer
 */
import React from 'react';
import { Box, Stack } from '@mui/material';
import { css } from '@emotion/react';

import { RescueChiefComplaint, AgeSex, JcsGcs, Vital, Cpa } from './src';
import { useActiveCpa } from 'hooks';

/**
 * Container Layer
 */
const PatientInfoView = React.memo(() => {
  return (
    <Stack direction="column" spacing={2}>
      <Box
        css={css`
          display: grid;
          grid-template-columns: auto minmax(0, 1fr);
          gap: 1rem;
        `}
      >
        <AgeSex />
        <RescueChiefComplaint />
      </Box>
      <JcsGcs />
    </Stack>
  );
});

const PatientVitalCpaView = React.memo(() => {
  const isActiveCpa = useActiveCpa();
  return <Box>{isActiveCpa ? <Cpa /> : <Vital />}</Box>;
});

/**
 * Container Layer
 */
export const PatientOverview = React.memo(() => {
  return (
    <Box
      css={css`
        margin: 1rem 0 0 1rem;

        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr auto;
        gap: 0.5rem;

        @media screen and (min-width: 1024px) {
          display: grid;
          grid-template-rows: auto 1fr;
          grid-template-columns: auto;
          gap: 2rem;
        }
      `}
    >
      <PatientInfoView />
      <PatientVitalCpaView />
    </Box>
  );
});
