/**
 * Import Layer
 */
import React from 'react';
import { ListItemText, Typography } from '@mui/material';
import { css } from '@emotion/react';

import { getCreatedAt } from 'utils';

/**
 * Types Layer
 */
type DatetimeUIType = { createdAt?: string; isSelected?: boolean };

/**
 * Presentational Layer
 */
export const DatetimeUI = React.memo((props: DatetimeUIType) => {
  const datetime = getCreatedAt(props.createdAt);
  const borderLeft = props.isSelected ? 'solid 6px #FFF503' : 'solid 6px transparent';

  return (
    <ListItemText
      data-testid="listItemText"
      css={css`
        border-left: ${borderLeft};
        width: 74px;
        max-width: 74px;
        height: 70%;
        margin-left: -10px;
        display: flex;
        align-items: center;
      `}
      primary={
        <React.Fragment>
          <Typography
            noWrap={true}
            css={css`
              padding-left: 4px;
              font-size: 18px;
              font-weight: bold;
              font-family: 'Noto Sans JP', sans-serif;
            `}
          >
            {datetime}
          </Typography>
        </React.Fragment>
      }
    />
  );
});
