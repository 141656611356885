import { getAgeString } from './getAgeString';

export const getBirthDayString = (bd: string, createdAt: string | undefined) => {
  if (bd.length !== 8) {
    return '-';
  }
  try {
    const year = bd.slice(0, 4);
    const month = bd.slice(4, 6);
    const day = bd.slice(6, 8);
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as const;
    const birthday = date.toLocaleDateString('ja-JP-u-ca-japanese', options);
    const birthdayList = birthday.split(/[年月日]/);
    const birthdayString = `${birthdayList[0]}年 ${birthdayList[1]}月 ${birthdayList[2]}日`;

    const ageString =
      createdAt !== undefined && getAgeString(bd, createdAt) !== undefined
        ? `(${getAgeString(bd, createdAt)})`
        : '';

    return `${birthdayString} ${ageString}`;
  } catch (error) {
    return '-';
  }
};
