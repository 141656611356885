import React from 'react';
import { css } from '@emotion/react';

type TransferBadgeProps = {
  short?: boolean;
};

export const TransferBadge: React.VFC<TransferBadgeProps> = ({ short }) => {
  return (
    <div
      role="status"
      css={css`
        font-size: 12px;
        font-weight: 700;
        background-color: #e2e2e2;
        color: #292929;
        min-height: fit-content;
        max-height: fit-content;
        min-width: fit-content;
        max-width: fit-content;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 4px;
      `}
    >
      {short === true ? '転院' : '転院搬送'}
    </div>
  );
};
