/**
 * Import Layer
 */
import React from 'react';
import { css } from '@emotion/react';
import { RescueBadge } from './RescueBadge';
import { Box } from '@mui/material';

/**
 * Types Layer
 */
type RescueUIProps = {
  ac: 0 | 1 | 2 | 3 | null;
  am?: string;
  simultaneousInquiry?: boolean | null;
  drcar_inquiry?: boolean | null;
  drheli_inquiry?: boolean | null;
  tff?: 0 | 1;
  nsc?: 0 | 1 | null;
};

/**
 * Presentational Layer
 */
export const RescueUI: React.VFC<RescueUIProps> = React.memo(
  ({ ac, am, simultaneousInquiry, drcar_inquiry, drheli_inquiry, tff, nsc }) => {
    return (
      <div
        css={css`
          min-width: 136px;
          display: flex;
          gap: 8px;
        `}
      >
        <div
          css={css`
            margin-left: 4px;
            font-size: 18px;
            font-weight: bold;
            font-family: 'Noto Sans JP', sans-serif;
            flex-grow: 1;

            /* truncate */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {am ?? '救急隊名不明'}
        </div>
        {ac === 3 && <CancelBadge />}
        {simultaneousInquiry === true ? (
          <RescueBadge type={'simultaneous'} />
        ) : drheli_inquiry === true ? (
          <RescueBadge type={'doctorheli'} />
        ) : drcar_inquiry === true ? (
          <RescueBadge type={'doctorcar'} />
        ) : tff === 1 ? (
          <RescueBadge type={'transfer'} />
        ) : nsc === 1 ? (
          <RescueBadge type={'referral'} />
        ) : null}
      </div>
    );
  }
);

const cancelBadgeStyle = css`
  width: 32px;
  height: 32px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: white;
  border-radius: 8px;

  /* Content Center */
  display: grid;
  place-content: center;

  /* Text Style */
  text-align: center;
  font-size: 9px;
  line-height: 14px;
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: black;
`;

const CancelBadge: React.VFC = React.memo(() => {
  return (
    <Box css={cancelBadgeStyle}>
      <div>キャン</div>
      <div>セル</div>
    </Box>
  );
});
