/**
 * Import Layer
 */
import React, { useState } from 'react';
import { Button, Modal, Fade, Box } from '@mui/material';
import { css } from '@emotion/react';
import { useSelectedPatient } from 'hooks';
import { ModalPaper } from './ModalPaper';

/**
 * Types Layer
 */
type FreeTextModalButtonUIProps = {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  label: string | null | undefined;
  content: string | null | undefined;
};

/**
 * Presentational Layer
 */
const openButtonStyle = css`
  padding: 5px 10px;
  height: 40px;
  width: 220px;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  background-color: #f0ffff;
  &:hover {
    opacity: 0.8;
    background-color: #f0ffff;
  }
`;

const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FreeTextModalButtonUI: React.VFC<FreeTextModalButtonUIProps> = ({
  isOpen,
  handleOpen,
  handleClose,
  label,
  content,
}) => {
  if (content == null && label == null) {
    return <></>;
  }

  return (
    <>
      <Button onClick={handleOpen} size="small" css={openButtonStyle}>
        {label || '追加情報'}
      </Button>
      <Modal open={isOpen} onClose={handleClose} css={modalStyle}>
        <Fade in={isOpen}>
          <Box>
            <ModalPaper label={label} content={content} handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

/**
 * Conrainer Layer
 */
export const FreeTextModalButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { amz } = useSelectedPatient();
  const label = amz.freetextModalButtonLabel;
  const content = amz.freetextModalContent;
  return (
    <FreeTextModalButtonUI
      isOpen={isOpen}
      handleOpen={() => setIsOpen(true)}
      handleClose={() => setIsOpen(false)}
      label={label}
      content={content}
    />
  );
};
