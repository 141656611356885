/**
 * Import Layer
 */
import React from 'react';
import { Box, List, Divider } from '@mui/material';
import { css } from '@emotion/react';

import {
  PatientName,
  ChiefComplaint,
  PresentIllness,
  PastMedicalHistory,
  Medicine,
  EstimatedArrival,
  Covid19,
  WithPerson,
} from './src';
import { useVisibleFooter } from 'hooks';

/**
 * Types Layer
 */
type PatientDetailUIType = {
  isFooterVisible: boolean;
};

/**
 * Presentational Layer
 */
export const PatientDetailUI = React.memo((props: PatientDetailUIType) => {
  return (
    <Box
      css={css`
        margin: 1rem 0 0 1rem;
      `}
    >
      <List
        css={css`
          height: 100%;
          min-height: 200px;
          padding: 0;

          overflow-y: scroll;
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #666666;
            border-radius: 10px;
          }
        `}
      >
        <PatientName />
        <Divider />
        <ChiefComplaint />
        <Divider />
        <PresentIllness />
        <Divider />
        <PastMedicalHistory />
        <Divider />
        <Medicine />
        <Divider />
        <EstimatedArrival />
        <Divider />
        {props.isFooterVisible ? (
          <></>
        ) : (
          <>
            <Covid19 />
            <Divider />
          </>
        )}
        <WithPerson />
        <Divider />
      </List>
    </Box>
  );
});

/**
 * Container Layer
 */
export const PatientDetail = () => {
  const isFooterVisible = useVisibleFooter();

  return <PatientDetailUI isFooterVisible={isFooterVisible} />;
};
