import { css } from '@emotion/react';
import { useRef } from 'react';
import { Message } from 'states/ducks/messages/slice';
import { isValidColorCode } from 'utils/validator';

export const MessageList = ({ messages }: { messages: Message[] }) => {
  if (messages.length === 0) {
    return null;
  }

  return (
    <ul
      css={css`
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin: 0;
        padding: 2px 8px 4px;
        background-color: #484848;
        list-style: none;
        width: 100%;
      `}
    >
      {messages.map((item) => (
        <MessageItem key={item.id} message={item} />
      ))}
    </ul>
  );
};

const MessageItem = ({ message }: { message: Message }) => {
  const textNode = useRef<HTMLDivElement>(null);
  const clientWidth = textNode.current?.clientWidth ?? 0;
  const scrollWidth = textNode.current?.scrollWidth ?? 0;

  const fontSize = message.size === 'small' ? 16 : message.size === 'medium' ? 20 : 24;
  const fontColor = isValidColorCode(message.fontColor) ? message.fontColor : '#ffffff';
  const backgroundColor = isValidColorCode(message.backgroundColor)
    ? message.backgroundColor
    : '#000000';

  return (
    <li
      css={css`
        background-color: ${backgroundColor};
        overflow: hidden;
      `}
    >
      <div
        ref={textNode}
        css={css`
          font-size: ${fontSize}px;
          line-height: 1;
          color: ${fontColor};
          white-space: nowrap;
          padding: 4px 12px;
          ${scrollWidth > clientWidth
            ? `
            animation: ${scrollWidth / 100}s linear infinite slide;
            @keyframes slide {
              from {
                transform: translateX(${clientWidth}px);
              }
              to {
                transform: translateX(-${scrollWidth}px);
              }
            }
            `
            : ''}
        `}
      >
        {message.message}
      </div>
    </li>
  );
};
