/**
 * Import Layer
 */
import React from 'react';
import { Button, Typography } from '@mui/material';
import { css } from '@emotion/react';

import { Image } from './Image';

/**
 * Types Layer
 */
type QrCodeButtonType = {
  qrCodeUrl: string;
  handleQrCodeOpen: () => void;
};

/**
 * Presentational Layer
 */
export const QrCodeButton = React.memo((props: QrCodeButtonType) => {
  return (
    <Button
      css={css`
        width: 224px;
        height: 224px;

        display: grid;
        place-content: center;

        background-color: #ffffff;
        &:hover {
          opacity: 0.65;
          background: #ffffff;
        }
      `}
      onClick={() => props.handleQrCodeOpen()}
      disabled={!props.qrCodeUrl}
    >
      {!!props.qrCodeUrl ? (
        <Image
          imageUrl={props.qrCodeUrl}
          imageCss={css`
            width: 216px;
            height: 216px;
          `}
        />
      ) : (
        <Typography
          css={css`
            color: #222222;
          `}
        >
          NO QR-CODE
        </Typography>
      )}
    </Button>
  );
});
