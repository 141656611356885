import { Button, css } from '@mui/material';
import { useGetInvitationUrl, useInvitation } from 'hooks';
import React, { useCallback } from 'react';

const enterVideoRoomButtonStyle = css`
  background-color: #f7a355;
  opacity: 1;
  color: #ffffff;
  padding: 8px 24px;
  font-size: 18px;
  line-height: 24px;
  &:hover {
    background-color: #f7a355;
    opacity: 0.8;
  }
  &:disabled {
    background-color: #666666;
    opacity: 1;
  }
`;

export const EnterVideoRoomButton: React.VFC<{ invitationId: string }> = React.memo(
  ({ invitationId }) => {
    const invitation = useInvitation(invitationId);
    const isActive = invitation?.isActive ?? false;
    const { getInvitationUrl, isLoading } = useGetInvitationUrl(invitationId);

    const onClick = useCallback(async () => {
      const url = await getInvitationUrl();
      if (url === undefined || url === '') {
        console.error('Failed to get invitation url');
        return;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    }, [getInvitationUrl]);

    return (
      <Button
        variant="contained"
        onClick={onClick}
        disabled={!isActive || isLoading}
        css={enterVideoRoomButtonStyle}
      >
        {isActive ? '入室' : '終了'}
      </Button>
    );
  }
);
