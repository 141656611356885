import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import Loader from 'react-loader-spinner';

import { RootState } from 'states/index';
import { SignInForm } from './src/Container/SignInForm';

export const SignIn = () => {
  const theme = useTheme();
  const { isAuthLoading } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <Backdrop
        open={isAuthLoading}
        css={css`
          z-index: ${theme.zIndex.drawer + 1};
        `}
      >
        <Loader type="Grid" color="#00BFFF" height={100} width={60} timeout={15000} />
      </Backdrop>
      <SignInForm />
    </>
  );
};
