// V1認証でログインしたとき活動記録表ボタンを表示するかどうかを判定する
// V2認証への移行が完了した病院はリストから削除する
const hpUIDWhiteList: string[] = [
  'B973BEF5-DF15-3447-FC1E-B2E214D54DAE', // 藁【テスト】TXP三次病院
  '43DB1389-6312-463B-99C6-A17C8013CD7D', // 山陽病院
  'CFCA089F-3190-40EB-86EE-451097B1BADE', // セントラル病院
  'FFB4486A-1FBD-4004-B772-E09208BC8A57', // 大田記念病院
  '8BEF8A67-6054-41CE-A06E-96497959B001', // 藁【テスト】TXP病院
  '71181BD8-D9C9-402C-9FA3-392591BD8C8D', // 日本鋼管福山病院
  '51EC0C56-25D4-4D58-84E5-DB3149E88391', // 藤井病院
  '6D3E2F4B-F80C-4B2C-8A4D-DFFAD2345305', // 聖麗メモリアル病院
  'C49DD00D-0D94-B42B-7A73-0A49F5D501B9', // 日立総合病院
  '27152D5D-40D3-4DF5-A523-7C648E63880B', // ひたち医療センター
  'B551F2A9-CADA-4029-A2B4-00DAA6192776', // 常陸大宮済生会病院
  'BD839490-6081-4709-BB67-FCD0701A58C1', // 藁【テスト】日立テスト病院
  'C6C658B8-15F5-4F63-B86E-41EB690CD61D', // 藁【テスト】札幌テスト病院
  'BB645AF3-FFFB-4FE5-A0F0-3978065F0300', // 札幌東徳洲会病院
  'AFB2866F-C31C-4C53-8369-5F84AEAEA5FE', // 手稲渓仁会病院
  'D3878696-BC90-4781-9C9B-6336E1BE1C5A', // 北海道医療センター
  '5F44DF43-4ED5-4F24-8619-C172555B8844', // 市立札幌病院
  'F1E705E8-3323-4C71-B756-0545F353641C', // 札幌医科大学病院
  'E94F98AE-6CC4-42D6-A8A3-CB19399F31AB', // 北海道大学病院
  '3862CDD4-F78C-439B-BC2A-EE9E46893F54', //
  '720ADC20-D3EF-4AA8-947B-7E479CFF8DC8', // 群馬大学病院
  '17D80743-F556-4255-855E-2F8DACC0CC0F', // 藁【テスト】鹿児島大学病院
  'F43A6837-8B8F-41B9-BD4B-6F9E5C62F678', // 藁【テスト】高島テスト病院
  '24A72C0E-2528-4E07-81AE-928272A774EE', // 【内科】高島市民病院
  'CE2B1328-5F75-485C-B339-BEFEA34BF8AD', // 豊田厚生病院
  '0CE6E328-31F5-498A-BAD6-6538328ABC83', // トヨタ記念病院
  'F7C67BF3-1BA0-4D40-AAA4-7670431F29F2', // マキノ病院
  // '386E8F49-3705-421A-9381-A83596E87177', // 湘南鎌倉総合病院
  'F6416236-C074-4C1A-B8BF-7991036AF076', // 大船中央病院
  '6D3AC24A-8C6C-4CFB-BB39-5B74C4D2A522', // 藁【テスト】鎌倉テスト病院(本番ダッシュボード検証用)
  '16CCDC46-AF33-CA4E-99FC-6AEE345EA2EE', // ふれあい鎌倉ホスピタル
  '1622E9AD-CDF9-DC48-B2AE-A22D67A23395', // 清川病院
  'D2778746-09E4-3B40-AD2D-2F1A993EF60C', // 湘南記念病院
  'E0337869-88F8-E648-888E-0E6364DF4B85', // みやぎ県南中核病院
  '9FD1301B-B527-4C03-A8A0-D7703EBD69A4', // 藁【テスト】日立テスト病院(本番ダッシュボード検証)
  'FFA0A4B2-F69C-4844-A045-89A7792DA131', // 藁【テスト】仙南テスト病院(本番DB)
  'F3565025-7317-094C-9028-730B7053E0E2', // 藁【テスト】豊田テスト病院
  'F963B1CA-0F09-9949-AF07-8B5D9BE28246', // 足助病院
  'C37F1E08-F708-0849-ACC9-05D7FD4536C3', // 豊田地域医療センター
  '35AD76C8-E174-F248-9EB6-7D91F4D7B06F', // 鎌倉病院
  'E58B2D67-4902-4536-8B68-790B4C091BC2', // 藁【テスト】鹿児島転院先テスト病院
  '6A7BA111-DD23-48C2-BCC1-AEA25846D890', // 藁【テスト】鹿児島転院先テスト病院(本番)
  'D0D9E7E6-B17B-4D49-AFA5-5E8B19DCA7EE', // 米盛病院
  '48FC910C-2350-46C4-9E49-8DA475415A07', // 横須賀市立うわまち病院
  'F1643E45-4E36-4E87-A706-E2758350AEE2', // 横須賀市立市民病院
  '98F58140-4015-1456-B239-F263744EA3E2', // 横須賀共済病院
  '47B0579E-5B12-41E4-AB48-14F61E939BE8', // 藁【テスト】横須賀テスト病院(本番)
  '6C90695B-5E7D-425C-B42A-14F295360162', // 藁【テスト】横須賀テスト病院(テスト)
  'BA2F8655-57E5-4075-B85C-BE49A4B31F03', // 勤医協中央病院
  '80D3B341-B63F-494D-AE56-612C26DD6AA8', // 藁【テスト】豊田テスト病院(本番ダッシュボード検証)
  'B303383D-77C5-421E-91C6-638C34392D34', // 藁【テスト】札幌テスト病院(本番ダッシュボード検証用)
  '4E60A70D-7147-04CD-2C2B-04C5FF2FDA46', // 東北大学病院
  'ECDAABB5-E8EC-4039-8A2C-E5DC65F5BBCC', // 救急隊連携は未開始）大泉記念病院
  '094B4EAB-CAAC-4121-9BEC-F005C0FB16BC', // 仙南病院
  'BF123EEC-B1CE-457F-B79A-3F7A5C95E23E', // 三浦市立病院
  '1C729DB6-CEA8-41C8-B73B-25BCD546FF2D', // よこすか浦賀病院
  // 'E0EF4BD2-9A4A-4B51-B8A2-41661C218C4D', // 藤沢市民病院
  '856FAC53-2994-4A22-8829-822A54E667E1', // 藁【テスト】藤沢テスト病院
  '684E6FB3-36EB-4722-BDE0-ED79D1640D67', // 藁【テスト】藤沢テスト病院(本番ダッシュボード)
  '3EB52397-596F-4DF0-A3D6-AEEF42ABC8F1', // 藁【テスト】つくばテスト病院
  '6CE6C96A-05D0-4E39-9201-9581229EB843', // 筑波メディカルセンター病院
  'FCE3D7EA-6C75-45BD-B887-EDEF9D10E7E5', // 筑波大学病院
  'BEDD29A5-7EA3-4414-8B4E-CD3E2FCD51B9', // 筑波記念病院
  'EC3E4C87-8B7D-476E-8E1E-E45A9AA33F7F', // 筑波学園病院
  'BB2EE67D-BE97-4216-A4F0-1738784870DE', // UMMC
  'C7C1DDDF-0599-4033-918A-58E73502BCA6', // HUSM
  'D6064E40-2F5D-416E-97B7-25C1C659C2BB', // SASMEC
  '9FA68AD9-5378-4A31-927B-458B729CDE33', // 藁【テスト】水戸テスト病院
  'A97755AA-F217-4F3A-A6B9-FA1F37568E5C', // 水戸赤十字病院
  'ACDB6712-EE40-4DF5-8589-98FC90F2AC3E', // 水戸協同病院
  'F90A2FB1-62F5-4026-98BF-069AEBF7A22E', // 茨城県立中央病院
  'DD660FD1-F0B9-46F8-AC7F-655C0290FF90', // 水戸医療センター
  '1D8EAAE3-D855-46C7-873B-8913689EE5A2', // 水戸済生会病院
  'CB7771CF-464E-4F8F-B1F6-23C067D814A9', // ひたちなか総合病院
  '8A792BC1-B6FA-4CE0-968B-FB9825E1430B', // 水府病院
  '94B93524-D79C-47E1-AB34-403393CEED5F', // 誠潤会水戸病院
  'B8DB92B5-0F67-45F2-897A-7606B7B90E34', // 水戸ブレインハートセンター
  'FC910B8E-4617-4CFB-BB7F-50088571538F', // 水戸中央病院
  '3CABC99D-582E-4D7C-823D-8B15236F1453', // 城南病院
  '8CFDB9A5-8064-48B8-9423-A0641A523FCE', // 大久保病院
  '17748E38-6759-4A52-A078-1AA80FAB1E59', // 志村病院
  'EAE1D68D-BDCF-41B0-BB11-C21AD9BDEAED', // 北水会記念病院
  '474CE502-A6FC-427C-868B-73E87877A6DC', // 丹野病院
  'BC195821-CE80-4EC0-BD7E-7B81F5C4B3C3', // 青柳病院
  '0DB04507-2B61-45EE-B1BA-B39F0913BBD8', // クリニック健康の杜
  // '9C74A46F-D23F-48DF-A000-8DB8198DA9D3', // 湘南藤沢徳洲会病院
  // 'CFE543C1-07CA-4DB0-BAE0-EC1603608E23', // 藤沢湘南台病院
  'D0E25752-B643-48FF-9832-C7531BFF6F61', // 藁【テスト】鹿行テスト病院
  'FA88E5AC-87D2-44CA-8257-F244C530B50A', // 小美玉市医療センター
  '1415C710-E58C-4790-B2F0-7CFFFC2A175C', // 石岡循環器科脳神経外科病院
  '266EA61A-30B8-46D7-BDD4-C414B52E511E', // 大洗海岸病院
  'F1DB5143-4E59-4656-B114-B75097222C51', // 高須病院
  'B2548ADF-AAF4-48CE-8702-B1D4B7EEDB0B', // 鉾田病院
  '5916F6DA-11F6-4A4F-8ACA-CA7618C0A52C', // 白十字総合病院
  'AFD86933-D085-4D28-96D2-8FF859E35899', // 小山記念病院
  '30BB0512-A1D2-463F-BA56-718AFE241BC5', // 神栖済生会病院
  'A25AE9C6-74C3-4A01-B290-A1950083591E', // 山王台病院
  'F1A04A83-761E-4F92-858B-5E9FC633C13D', // なめがた地域医療センター
  'AE55252D-BC40-476D-BDB6-F6102F30580D', // 土浦協同病院(3次症例のみ)
  '06E71E55-0C32-4446-8D48-04EC0FF07497', // 鹿嶋ハートクリニック
  '0B5CE179-E4ED-4CB8-AD96-C0CCEDCA7674', // HUKM
  'D694B97C-E203-4E0C-BFC6-87AE3875904C', // HTAA
  '910D40C9-2894-4ACE-AA68-3B484F66397C', // 山本整形外科病院
  '442C7CAF-FD43-40DA-A4DA-90392403A7C5', // 藁【テスト】鹿嶋テスト病院
  '93D65828-4FC2-FD4C-AD4A-D70BE13036A9', // 藁【テスト】島根県出雲市テスト病院
  '97A74D45-42C7-AF40-8793-1F57DF23A014', // 島根大学病院
  'FC91847B-C429-C843-BDEC-C13C68985C8B', // 島根県立中央病院
  'F498BFE2-DA53-4301-8855-9FB3F4B611D1', // 藁【テスト】逗子テスト病院
  '3D31E5B8-E7AC-4CB9-AEC1-32A0564D8268', // 葉山ハートセンター
  'C01826F8-DA3C-4384-AE7C-ABC45D60A274', // 藁【テスト】葉山テスト病院
  '1A45DAEE-A540-429F-AB6F-75ABA53C0AEA', // 藁【テスト】秦野テスト病院
  '309BAB03-1AB9-42C8-9604-BCCAAC465D24', // 秦野赤十字病院
  'F951B2E7-2E17-4310-825C-F0BE91621999', // 八木病院
  '65ACA4F0-6DF3-480B-99A0-0A5B47CB94D2', // 神奈川病院
  '76E40C92-3714-40AB-8ABA-5CA7709C3135', // 東海大学病院
  'FAD91BC8-164E-4A20-B5C4-74672C0D29D2', // 平塚市民病院
  '7757CE04-BD73-4424-BDEC-2CFE22778FF8', // 足柄上病院
  'E7C08F63-BCB2-4FFD-B941-83C8D31023F5', // 伊勢原協同病院
  '4D8BA729-B326-894D-B1F8-91F0C1E6FA04', // 藁【テスト】石橋テスト病院
  'B56A5B64-652D-3B4B-8C00-FB0D6146DCB9', // 新小山市民病院
  '2E9817CD-8493-184C-89F7-B0F9A04869C3', // 石橋総合病院
  '6F0DBD46-3A8E-4F48-B51A-AD978BC65285', // 小金中央病院
  '0B934E5A-DF93-234A-8280-33B0B95D316C', // 新上三川病院
  '90780450-795D-D140-8320-64DD600D2381', // 藁【テスト】芳賀テスト病院
  '68BB0134-B55F-4192-BA21-C171D0974AD6', // 自治医科大学附属病院
  '9DE464FC-A3BC-7841-A0F8-FE9BB8DE2996', // 芳賀赤十字
  '928CC881-F658-ED49-BD3C-B3E68186BA64', // 芳賀中央病院
  '7A4312B5-5F1B-E54B-8E8B-F475927D2F17', // 真岡病院
  '74B19592-6E79-2249-B071-C0AE48D95606', // 福田記念病院
  '6FFCD821-4867-014B-B9B2-81FA45C25121', // 藁【テスト】北九州テスト病院
  '53C3D535-8456-FA49-8D63-446C10477B5A', // 新小文字病院
  '0977C697-39B8-ED48-9BD4-E9953EDE7544', // 戸畑共立病院
  '79F10C1F-D241-0248-B8C7-B1C16ACBADF6', // 北九州市立医療センター
  '9F9EDCFF-270D-5142-B0E0-652696317B6F', // 健和会大手町病院
  'AAADAC2B-2130-9543-8CEB-488D35A97A67', // 北九州総合病院
  'CF898DAD-CB0D-9E49-8C94-66B8DC7E7A55', // 九州労災病院
  'F7C816E6-2852-4D49-8259-468DFA36AA9A', // 製鉄記念八幡病院
  'EBE93C89-D72F-DE41-9779-C711B1FB3174', // 北九州市立八幡病院
  '18E5F363-A403-2B44-B88B-1D0367DED3C6', // JCHO九州病院
  '5F33B8A6-5809-F94C-BEC2-7AFC322598B2', // 産業医科大学病院
];

export const shouldShowAmbulanceRecordButton = (hpUID: string): boolean => {
  return hpUIDWhiteList.includes(hpUID);
};
