/**
 * import Layer
 */
import React from 'react';
import { List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { css, SerializedStyles } from '@emotion/react';

import { useSelectedPatient } from 'hooks';
import {
  createBloodPressureString,
  createSpO2Text,
  prCheckIsOutlier,
  sbCheckIsOutlier,
  spo2CheckIsOutlier,
  rrCheckIsOutlier,
  btCheckIsOutlier,
} from './utils';

/**
 * Types Layer
 */
type VitalListItemType = {
  itemKey: string;
  title: string;
  body?: string | number | JSX.Element;
  listItemStyle: SerializedStyles;
};
type PulseRateType = { id?: string; pr?: number | null };
type BloodPressureType = { id?: string; sb?: number | null; db?: number | null };
type SpO2Type = {
  id?: string;
  sp?: number | null;
  sp2?: number | null;
  of?: number | null;
};
type RespirationRateType = { id?: string; rr?: number | null };
type BodyTemperatureType = { id?: string; bt?: number | null };
type VitalType = {
  id?: string;
  pr?: number | null;
  sb?: number | null;
  db?: number | null;
  sp?: number | null;
  sp2?: number | null;
  of?: number | null;
  rr?: number | null;
  bt?: number | null;
};

/**
 * Presentational Layer
 */
const defaultListItemStyle = css`
  padding: 0;
  color: #ffffff;
  background: transparent;
  height: 36px;
`;

const outlierListItemStyle = css`
  ${defaultListItemStyle}
  color: #292929;
  background: #fff503;
`;

const VitalListItem = React.memo((props: VitalListItemType) => {
  return (
    <ListItem key={props.itemKey} divider={true} css={props.listItemStyle}>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              css={css`
                padding-left: 4px;
                font-size: 17px;
                font-weight: bold;
                font-family: 'Noto Sans JP', sans-serif;
              `}
            >
              {props.title}
            </Typography>
          </React.Fragment>
        }
      />
      <ListItemText
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 2px;
        `}
        primary={
          <React.Fragment>
            <Typography
              css={css`
                font-size: 17px;
                font-weight: bold;
                font-family: 'Noto Sans JP', sans-serif;
              `}
            >
              {props.body}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
});

export const PulseRate = React.memo((props: PulseRateType) => {
  const body = props.pr === null ? '-' : props.pr;
  const style = prCheckIsOutlier(props.pr) ? outlierListItemStyle : defaultListItemStyle;
  return (
    <VitalListItem
      itemKey={`pr-${props.id}`}
      title={'PR'}
      body={body}
      listItemStyle={style}
    />
  );
});

export const BloodPressure = React.memo((props: BloodPressureType) => {
  const body = createBloodPressureString(props.sb, props.db);
  const style = sbCheckIsOutlier(props.sb) ? outlierListItemStyle : defaultListItemStyle;
  return (
    <VitalListItem
      itemKey={`bp-${props.id}`}
      title={'BP'}
      body={body}
      listItemStyle={style}
    />
  );
});

export const SpO2 = React.memo((props: SpO2Type) => {
  const style = spo2CheckIsOutlier(props.sp)
    ? outlierListItemStyle
    : defaultListItemStyle;
  const body = (
    <>
      {createSpO2Text(props.sp, props.sp2)}
      {props.of === null || props.of === undefined ? '' : `(${props.of}`}
      {props.of === null || props.of === undefined ? (
        ''
      ) : (
        <span style={{ fontSize: '12px' }}>L</span>
      )}
      {props.of === null || props.of === undefined ? '' : ')'}
    </>
  );
  return (
    <VitalListItem
      itemKey={`spo2-${props.id}`}
      title={'SpO2'}
      body={body}
      listItemStyle={style}
    />
  );
});

export const RespirationRate = React.memo((props: RespirationRateType) => {
  const body = props.rr === null ? '-' : props.rr;
  const style = rrCheckIsOutlier(props.rr) ? outlierListItemStyle : defaultListItemStyle;
  return (
    <VitalListItem
      itemKey={`rr-${props.id}`}
      title={'RR'}
      body={body}
      listItemStyle={style}
    />
  );
});

export const BodyTemperature = React.memo((props: BodyTemperatureType) => {
  const body = props.bt === null ? '-' : props.bt;
  const style = btCheckIsOutlier(props.bt) ? outlierListItemStyle : defaultListItemStyle;
  return (
    <VitalListItem
      itemKey={`bt-${props.id}`}
      title={'BT'}
      body={body}
      listItemStyle={style}
    />
  );
});

export const VitalUI = React.memo((props: VitalType) => {
  const theme = useTheme();
  return (
    <List
      css={css`
        background: ${theme.palette.background.default};
        min-width: 170px;
        width: fit-content;
        padding: 0;
        outline: solid 1px;
        outline-color: #ffffff;
      `}
    >
      <PulseRate id={props.id} pr={props.pr} />
      <BloodPressure id={props.id} sb={props.sb} db={props.db} />
      <SpO2 id={props.id} sp={props.sp} sp2={props.sp2} of={props.of} />
      <RespirationRate id={props.id} rr={props.rr} />
      <BodyTemperature id={props.id} bt={props.bt} />
    </List>
  );
});

/**
 * Container Layer
 */
export const Vital = () => {
  const { id, pr, sb, db, sp, sp2, of, rr, bt } = useSelectedPatient();
  return (
    <VitalUI id={id} pr={pr} sb={sb} db={db} sp={sp} sp2={sp2} of={of} rr={rr} bt={bt} />
  );
};
