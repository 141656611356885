/**
 * Import Layer
 */
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { ChangePasswordModal } from './ChangePasswordModal';

/**
 * Types Layer
 */
type SettingButtonUIProps = {
  anchorElement: HTMLElement | null;
  onMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMenuClose: () => void;
  changePasswordModalOpen: boolean;
  onChangePasswordModalOpen: () => void;
  onChangePasswordModalClose: () => void;
};

/**
 * Presentational Layer
 */
export const SettingButtonUI = React.memo((props: SettingButtonUIProps) => {
  return (
    <div>
      <IconButton id="setting-button" onClick={props.onMenuClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id="setting-menu"
        anchorEl={props.anchorElement}
        open={Boolean(props.anchorElement)}
        onClose={props.onMenuClose}
        MenuListProps={{
          'aria-labelledby': 'setting-button',
        }}
      >
        <MenuItem onClick={props.onChangePasswordModalOpen}>パスワード変更</MenuItem>
      </Menu>
      <ChangePasswordModal
        open={props.changePasswordModalOpen}
        onClose={props.onChangePasswordModalClose}
      />
    </div>
  );
});

/**
 * Conrainer Layer
 */
export const SettingButton = () => {
  // メニューのOpenフラグ
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  // パスワード変更ModalのOpenフラグ
  const [changePasswordModalOpen, setChangePasswordModalOpenFlag] = useState(false);
  const handleChangePasswordModalOpen = () => {
    setChangePasswordModalOpenFlag(true);
    handleClose();
  };
  const handleChangePasswordModalClose = () => {
    setChangePasswordModalOpenFlag(false);
  };

  return <SettingButtonUI
    anchorElement={anchorElement}
    onMenuClick={handleClick}
    onMenuClose={handleClose}
    changePasswordModalOpen={changePasswordModalOpen}
    onChangePasswordModalOpen={handleChangePasswordModalOpen}
    onChangePasswordModalClose={handleChangePasswordModalClose}
  />;
};
