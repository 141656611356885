/**
 * Import Layer
 */
import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { css, SerializedStyles } from '@emotion/react';

import { useSelectedPatient } from 'hooks';
import { validationCpf, getWitnessString, getBystanderString } from './utils';

/**
 * Types Layer
 */
type CpaListItemType = {
  itemKey?: string;
  title?: string | JSX.Element;
  body?: string;
  titleStyle?: SerializedStyles;
};
type CpaTitleType = { id?: string };
type CpaRhythmType = { id?: string; cpr?: string };
type CpaFlagType = { id?: string; cpf?: string };
type CpaType = CpaRhythmType & CpaFlagType;

/**
 * Presentational Layer
 */
const defaultTitleStyle = css`
  font-size: 18px;
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: #ffffff;
`;

const bystanderCprTitleStyle = css`
  ${defaultTitleStyle}
  font-size: 14px;
`;

export const CpaTitle = React.memo((props: CpaTitleType) => {
  return (
    <ListItem
      key={`cpa-${props.id}`}
      css={css`
        padding: 0 0 0 4px;
        background: #fff503;
        height: 46px;
      `}
    >
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              css={css`
                font-size: 36px;
                font-weight: bold;
                font-family: 'Noto Sans JP', sans-serif;
                color: #292929;
              `}
            >
              {'CPA'}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
});

const CpaListItem = React.memo((props: CpaListItemType) => {
  return (
    <ListItem
      key={props.itemKey}
      data-testid={`${props.itemKey}`}
      css={css`
        padding: 0 0 0 4px;
        background: #c73000;
        height: 46px;
      `}
    >
      <ListItemText
        primary={
          <React.Fragment>
            <Typography css={props.titleStyle ? props.titleStyle : defaultTitleStyle}>
              {props.title}
            </Typography>
          </React.Fragment>
        }
      />
      <ListItemText
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 2px;
        `}
        primary={
          <React.Fragment>
            <Typography
              css={css`
                font-size: 18px;
                font-weight: bold;
                font-family: 'Noto Sans JP', sans-serif;
                color: #ffffff;
              `}
            >
              {props.body}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
});

export const CpaRhythm = React.memo((props: CpaRhythmType) => {
  return <CpaListItem itemKey={'wave'} title={'初期波形'} body={props.cpr} />;
});

export const CpaFlag = React.memo((props: CpaFlagType) => {
  // const value = props.cpf[1] === '0' ? '無' : props.cpf[1] === '1' ? '有' : '-';
  const value = getWitnessString(props.cpf);
  return <CpaListItem itemKey={'witness'} title={'目撃'} body={value} />;
});

export const BystanderCpr = React.memo((props: CpaFlagType) => {
  const title = (
    <span>
      bystander
      <br />
      CPR
    </span>
  );
  const value = getBystanderString(props.cpf);

  // const value = props.cpf[2] === '0' ? '無' : props.cpf[2] === '1' ? '有' : '-';

  return (
    <CpaListItem
      itemKey={'bystanderCPR'}
      titleStyle={bystanderCprTitleStyle}
      title={title}
      body={value}
    />
  );
});

export const CpaUI = React.memo((props: CpaType) => {
  return (
    <List
      css={css`
        min-width: 170px;
        width: fit-content;
        padding: 0;
      `}
    >
      <CpaTitle id={props.id} />
      <CpaRhythm id={props.id} cpr={props.cpr} />
      <CpaFlag id={props.id} cpf={props.cpf} />
      <BystanderCpr id={props.id} cpf={props.cpf} />
    </List>
  );
});

/**
 * Container Layer
 */
export const Cpa = () => {
  const { id, cpf, cpr } = useSelectedPatient();
  const refinedCpf = validationCpf(cpf);
  const refinedCpr = cpr.replace(/ /g, '');
  return <CpaUI id={id} cpf={refinedCpf} cpr={refinedCpr} />;
};
