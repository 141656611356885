/**
 * Import Layer
 */
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';
import { css } from '@emotion/react';
import PrintIcon from '@mui/icons-material/Print';
import {
  getSexString,
  generateBirthdate,
  estimateArrivalTime,
} from './utils';
import { useSelectedPatient, usePatientsState } from 'hooks';
import HandoffSheet from 'views/components/Sheets/HandoffSheet/HandoffSheet';

const PageStyle = `
  @page {
    size: b5 landscape;
    margin: 0mm;
    background-color: white;
    color: black;
  }
`

/**
 * Types Layer
 */
type PatientInfoPrintButtonUIType = {
  patientId: string;
  age: number | null;
  sex: string;
  birthdate: string;
  nameCana: string;
  name: string;
  chiefComplaint: string;
  historyOfPresentIllness: string;
  pastMedicalHistory: string;
  medicine: string;
  jcs: string;
  gcsE: number | null;
  gcsV: number | null;
  gcsM: number | null;
  respirationRate: number | null;
  pulseRate: number | null;
  systolicBloodPressure: number | null;
  dyastolicBloodPressure: number | null;
  sp: number | null;
  sp2: number | null;
  oxygenRoute: string;
  oxygenFlow: number | null;
  bodyTemperature: number | null;
  cpf: string;
  hospitalArrivalTime: string;
  timeToArrive: number | null;
  emsTeamName: string;
  withPerson: string;
  qrCodeUrl: string;
};

/**
 * Presentational Layer
 */
export const PatientInfoPrintButtonUI = React.memo(
  (props: PatientInfoPrintButtonUIType) => {
    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
      content: () => contentToPrint.current,
      pageStyle: PageStyle,
    });

    return (
      <>
      <div style={{ display: "none" }}>
        <HandoffSheet componentRef={contentToPrint} {...props}/>
      </div>
      <Button
        size="small"
        startIcon={<PrintIcon />}
        css={css`
          padding: 5px 10px;
          height: 40px;
          width: 118px;
          font-size: 16px;
          font-weight: bold;
          font-family: 'Noto Sans JP', sans-serif;
          color: #333333;
          background-color: #f0ffff;
          &:hover {
            opacity: 0.8;
            background-color: #f0ffff;
          }
        `}
        onClick={handlePrint}
      >
        引継
      </Button>
      </>
    );
  }
);

/**
 * Container Layer
 */
export const PatientInfoPrintButton = () => {
  const selectedPatient = useSelectedPatient();
  const sex = getSexString(selectedPatient.se);
  const birthdate = generateBirthdate(selectedPatient.bd);
  const hospital_arrival_time = estimateArrivalTime(
    selectedPatient.ea,
    selectedPatient.createdAt
  );
  const { qrCodeUrl } = usePatientsState();

  return <PatientInfoPrintButtonUI
    patientId={selectedPatient.hd}
    age={selectedPatient.ag}
    sex={sex}
    birthdate={birthdate}
    nameCana={selectedPatient.naf}
    name={selectedPatient.na}
    chiefComplaint={selectedPatient.pi}
    historyOfPresentIllness={selectedPatient.cc}
    pastMedicalHistory={selectedPatient.ph}
    medicine={selectedPatient.me}
    jcs={selectedPatient.jc}
    gcsE={selectedPatient.ge}
    gcsV={selectedPatient.gv}
    gcsM={selectedPatient.gm}
    respirationRate={selectedPatient.rr}
    pulseRate={selectedPatient.pr}
    systolicBloodPressure={selectedPatient.sb}
    dyastolicBloodPressure={selectedPatient.db}
    sp={selectedPatient.sp}
    sp2={selectedPatient.sp2}
    oxygenRoute={selectedPatient.or}
    oxygenFlow={selectedPatient.of}
    bodyTemperature={selectedPatient.bt}
    cpf={selectedPatient.cpf}
    hospitalArrivalTime={hospital_arrival_time}
    timeToArrive={selectedPatient.ea}
    emsTeamName={selectedPatient.am}
    withPerson={selectedPatient.wp}
    qrCodeUrl={qrCodeUrl}
  />;
};
