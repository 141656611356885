import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as Sentry from '@sentry/react';

import { getHospitalUser } from 'graphql/queries';
import { setIsLoading } from 'states/ducks/route/slice';
import {
  setHospital,
  setIsAuthenticated,
  clearHospital,
  HospitalState,
} from 'states/ducks/hospital/slice';
import { getAuthenticatedUser } from 'utils';
import { getCognitoAuthenticatedUser } from 'utils/cognitoHelper';

export type GetHospitalUserType = {
  getHospitalUser: {
    username: string | null;
    hospitalName: string | null;
    hpUID: string | null;
    hostUrl: string | null;
    lat: number | null;
    lng: number | null;
    qrCompressionFlag: boolean;
    inquiryHistoryButtonFlag: boolean;
    inquiryResponseButtonFlag: boolean;
    ambulanceRecordButtonFlag: boolean;
  } | null;
};

export const GetHospitalUsersModel = {
  getHospitalUsers: (
    hpUID: string,
    userName: string
  ): Promise<GraphQLResult<GetHospitalUserType>> => {
    return API.graphql({
      query: getHospitalUser,
      variables: { hpUID, userName },
    });
  },
};

export const PrivateRouteEffect = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const dispatchUserData = async () => {
      try {
        const cognitoUser = await getCognitoAuthenticatedUser();
        const getHospitalUser = async (): Promise<HospitalState> => {
          if (cognitoUser !== undefined) {
            // V2認証
            const response = await GetHospitalUsersModel.getHospitalUsers(
              cognitoUser.hpUID,
              cognitoUser.username
            );
            const hospitalUserData = response.data?.getHospitalUser;
            if (hospitalUserData == null) {
              throw new Error('Failed to get hospital user data');
            }
            Sentry.setUser({
              username: hospitalUserData.username ?? '',
              hospitalName: hospitalUserData.hospitalName,
              hpUID: hospitalUserData.hpUID,
              type: 'V2認証',
            });
            return {
              username: hospitalUserData.username,
              hospitalName: hospitalUserData.hospitalName,
              hpUID: hospitalUserData.hpUID?.toUpperCase() ?? '', // hpUIDは大文字に変換しておく
              hostUrl: hospitalUserData.hostUrl || 'https://fms1.txpmedical.com',
              lat: hospitalUserData.lat,
              lng: hospitalUserData.lng,
              qr_compression_flag: hospitalUserData.qrCompressionFlag,
              dashboard_button_flag: undefined,
              inquiry_history_button_flag: hospitalUserData.inquiryHistoryButtonFlag,
              inquiry_response_button_flag: hospitalUserData.inquiryResponseButtonFlag,
              ambulance_record_button_flag: hospitalUserData.ambulanceRecordButtonFlag,
              authVersion: 'v2',
            };
          } else {
            // V1認証
            const authenticatedUser = await getAuthenticatedUser();
            if (authenticatedUser === undefined) {
              throw new Error('Failed to get authenticated user data');
            }
            Sentry.setUser({
              username: authenticatedUser.username ?? '',
              hospitalName: authenticatedUser.hospitalName,
              hpUID: authenticatedUser.hpUID,
              type: 'V1認証',
            });
            return {
              ...authenticatedUser,
              hostUrl: 'https://fms1.txpmedical.com',
              inquiry_history_button_flag: undefined,
              inquiry_response_button_flag: undefined,
              ambulance_record_button_flag: undefined,
              authVersion: 'v1',
            };
          }
        };
        const hospitalUser = await getHospitalUser();

        // Dispatch
        dispatch(setHospital(hospitalUser));
        dispatch(setIsAuthenticated(true));
        dispatch(setIsLoading(false));
      } catch (error) {
        dispatch(clearHospital());
        dispatch(setIsLoading(false));
      }
    };

    dispatchUserData();
  }, [dispatch]);
  return null;
};
