import React from 'react';
import { css } from '@mui/material';

import { useInvitation } from 'hooks';
import { EnterVideoRoomButton } from '../EnterVideoRoomButton';

type InvitationItemProps = {
  id: string;
};

const invitationListItemStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  border-bottom: 1px solid #808080;
`;

const dateTimeStyle = (isActive: boolean) => css`
  font-family: 'Noto Sans JP';
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  width: 108px;
  color: ${isActive ? '#f7a355' : '#ffffff'};
`;

const teamNameStyle = (isActive: boolean) => css`
  flex: none;
  flex-grow: 1;
  font-size: 24px;
  line-height: 35px;
  color: ${isActive ? '#f7a355' : '#ffffff'};
`;

export const InvitationItem: React.VFC<InvitationItemProps> = React.memo(({ id }) => {
  const invitation = useInvitation(id);
  if (invitation === undefined) {
    return null;
  }

  const parseError = Number.isNaN(Date.parse(invitation.updatedAt)); // 与えられた文字列が日付として正しいかどうかを判定する
  const updateDate = new Date(invitation.updatedAt);
  const dateString = `${updateDate.getFullYear()}/${
    updateDate.getMonth() + 1
  }/${updateDate.getDate()}`;
  const timeString = `${updateDate.getHours()}:${updateDate.getMinutes()}:${updateDate.getSeconds()}`;

  return (
    <div css={invitationListItemStyle}>
      <time
        dateTime={!parseError ? invitation.updatedAt : undefined}
        css={dateTimeStyle(invitation.isActive)}
      >
        {!parseError ? dateString : '---'}
        <br />
        {!parseError ? timeString : '---'}
      </time>
      <div css={teamNameStyle(invitation.isActive)}>{invitation.teamName}</div>
      <EnterVideoRoomButton invitationId={id} />
    </div>
  );
});
