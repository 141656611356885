import React from 'react';

import { PatientsEffect } from './src/PatientsEffect';
import { ImagesEffect } from './src/ImagesEffect';
import { InvitationsEffect } from './src/InvitationsEffect';
import { MessagesEffect } from './src/MessagesEffect';
import { QrCodeEffect } from './src/QrCodeEffect';
import { PrivateRouteEffect } from './src/PrivateRouteEffect';
import { NetworkEffect } from './src/NetworkEffect';
import { useSubscriptionError } from 'hooks';
import { delayReload } from './utils';

const DashboardEffects = () => {
  const isError = useSubscriptionError();
  if (isError) delayReload(1);

  return (
    <>
      <PatientsEffect />
      <QrCodeEffect />
      <ImagesEffect />
      <InvitationsEffect />
      <MessagesEffect />
    </>
  );
};

export { DashboardEffects, PrivateRouteEffect, NetworkEffect };
