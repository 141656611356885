import { Box, Button, css, Paper, Typography, Modal, Fade } from '@mui/material';
import React, { useState } from 'react';
import { useHospital, useChangePassword } from 'hooks';
import { PasswordFormProps, PasswordForm } from './ChangePasswordModal/PasswordForm';
import { toast } from 'react-hot-toast';

const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const boxStyle = css`
  height: min-content;
  width: min-content;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
`;

const titleStyle = css`
  padding: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: bold;
`;

const explanationStyle =css`
  margin-bottom: 0;
`;

const buttonGroupStyle = css`
  padding: 8px 0;
  margin-top: 8px;
`;

type ChangePasswordPropsModal = {
  open: boolean;
  onClose: () => void;
  onChangePassword: () => void;
  previousPasswordProps: PasswordFormProps;
  proposedPasswordProps: PasswordFormProps;
  confirmPasswordProps: PasswordFormProps;
};

export const ChangePasswordModalUI: React.VFC<ChangePasswordPropsModal> = React.memo(
  (props: ChangePasswordPropsModal) => {
    return (
      <Modal open={props.open} onClose={props.onClose} css={modalStyle}>
        <Fade in={props.open}>
          <Box>
            <Paper css={boxStyle} elevation={6}>
              <Typography variant="h6" css={titleStyle}>
                パスワード変更
              </Typography>
              <p css={explanationStyle}>
                現在のパスワードを入力してください
              </p>
              <PasswordForm
                label={props.previousPasswordProps.label}
                value={props.previousPasswordProps.value}
                setValue={props.previousPasswordProps.setValue}
                errorMessage={props.previousPasswordProps.errorMessage}
              />
              <p css={explanationStyle}>
                新しいパスワードを入力してください
              </p>
              <PasswordForm
                label={props.proposedPasswordProps.label}
                value={props.proposedPasswordProps.value}
                setValue={props.proposedPasswordProps.setValue}
                errorMessage={props.proposedPasswordProps.errorMessage}
              />
              <p css={explanationStyle}>
                確認のため、もう一度新しいパスワードを入力してください
              </p>
              <PasswordForm
                label={props.confirmPasswordProps.label}
                value={props.confirmPasswordProps.value}
                setValue={props.confirmPasswordProps.setValue}
                errorMessage={props.confirmPasswordProps.errorMessage}
              />
              <Box justifyContent="space-between" display="flex" css={buttonGroupStyle}>
                <Button onClick={props.onChangePassword} variant="contained">
                  更新
                </Button>
                <Button onClick={props.onClose}>
                  閉じる
                </Button>
              </Box>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    );
  }
);

/**
 * Conrainer Layer
 */
export const ChangePasswordModal = ({open, onClose}: {open: boolean, onClose: () => void}) => {
  const changePassword = useChangePassword;
  const { hpUID } = useHospital();
  const [previousPassword, setPreviousPassword] = useState("");
  const [proposedPassword, setProposedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [previousErrorMessage, setPreviousErrorMessage] = useState("")
  const [proposedErrorMessage, setProposedErrorMessage] = useState("")
  const [confirmErrorMessage, setConfirmErrorMessage] = useState("")

  const handleChangePassword = async () => {
    setPreviousErrorMessage("");
    setProposedErrorMessage("");
    setConfirmErrorMessage("");
    const { result, errors } = await changePassword(String(hpUID), previousPassword, proposedPassword, confirmPassword);
    if (result) {
      toast.success(
        "パスワードを変更しました（1時間あたり5回まで変更可能）",
        {
          duration: 2000,
          position: "top-center",
        }
      )
      handleClose();
    } else {
      for (const error of errors) {
        switch (error) {
          case "Previous password not entered.":
            setPreviousErrorMessage("現在のパスワードが入力されていません");
            break;
          case "Previous password is incorrect.":
            setPreviousErrorMessage("現在のパスワードが正しくありません");
            break;
          case "Proposed password not entered.":
            setProposedErrorMessage("新しいパスワードが入力されていません");
            break;
          case "Proposed password does not mutch password policy.":
            setProposedErrorMessage("新しいパスワードがパスワード要件を満たしていません");
            break;
          case "Confirm password not entered.":
            setConfirmErrorMessage("新しいパスワード（確認）が入力されていません");
            break;
          case "Proposed passwords do not match.":
            setConfirmErrorMessage("新しいパスワードが一致しません");
            break;
          case "API limit exceeded.":
            setProposedErrorMessage("パスワード変更回数上限（1時間に5回まで）に到達いたしました 時間を空けてから操作してください");
            break;
          case "Uknown exception.":
          default:
            setProposedErrorMessage("エラーが発生しました 管理者へ問い合わせください");
            break;
        }
      }
    }
  };

  const handleClose = () => {
      // 入力内容をリセット
      setPreviousPassword("");
      setProposedPassword("");
      setConfirmPassword("");
      setPreviousErrorMessage("");
      setProposedErrorMessage("");
      setConfirmErrorMessage("");
      onClose();
  }

  return (
    <ChangePasswordModalUI
      open={open}
      onClose={handleClose}
      onChangePassword={handleChangePassword}
      previousPasswordProps={{
        label: "現在のパスワード",
        value: previousPassword,
        setValue: setPreviousPassword,
        errorMessage: previousErrorMessage,
      }}
      proposedPasswordProps={{
        label: "新しいパスワード",
        value: proposedPassword,
        setValue: setProposedPassword,
        errorMessage: proposedErrorMessage,
      }}
      confirmPasswordProps={{
        label: "新しいパスワード（確認）",
        value: confirmPassword,
        setValue: setConfirmPassword,
        errorMessage: confirmErrorMessage,
      }}
    />
  );
}
