import React from 'react';
import { css } from '@emotion/react';

import { EnterVideoRoomButton } from '../GlobalButtons';

type Props = {
  id: string;
  name: string;
};

const toastStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  gap: 32px;
  background: #ffffff;
  border: 1px solid #292929;
  border-radius: 8px;
`;

const textStyle = css`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #292929;
`;

export const VideoCallInvitationToast: React.VFC<Props> = ({ id, name }) => {
  return (
    <div css={toastStyle}>
      <span css={textStyle}>{name}からビデオ通話の招待が来ています</span>
      <EnterVideoRoomButton invitationId={id} />
    </div>
  );
};
