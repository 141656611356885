import { useMessages } from 'hooks';
import { MessageList } from './MessageList';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

export const MessageView = () => {
  const { messages, isOpenMessages, toggleOpen } = useMessages();

  return (
    <div
      css={css`
        background-color: #484848;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          padding: 6px 12px 4px;
        `}
      >
        <Button
          variant="outlined"
          color="inherit"
          css={css`
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            width: 172px;
            padding: 2px;
          `}
          onClick={() => toggleOpen()}
        >
          {isOpenMessages ? 'メッセージを閉じる' : 'メッセージを開く'}
        </Button>
        <div
          css={css`
            color: #dddddd;
            font-size: 14px;
            line-height: 20px;
            height: 20px;
          `}
        >
          {messages.length}件のメッセージ
        </div>
      </div>

      {isOpenMessages && <MessageList messages={messages} />}
    </div>
  );
};
