/**
 * Import Layer
 */
import React from 'react';
import { AmplifyS3Image } from '@aws-amplify/ui-react';

/**
 * Types Layer
 */
type ImageDownloadType = {
  s3Key: string;
};

/**
 * Container Layer
 */
const style = {
  display: 'flex',
  '--heigt': '224px',
  '--width': '100%',
} as React.CSSProperties;

export const ImageDownload = React.memo((props: ImageDownloadType) => {
  return <AmplifyS3Image style={style} path="public" imgKey={props.s3Key} />;
});
