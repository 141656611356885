import React from 'react';
import { Box, Typography } from '@mui/material';
import { css } from '@emotion/react';
import icon from './no-data.png';

export const NoData = React.memo(() => {
  return (
    <Box
      css={css`
        display: grid;
        grid-template-rows: auto auto;
      `}
    >
      <Box
        css={css`
          display: grid;
          place-content: center;
        `}
      >
        <img src={icon} alt="no data" />
      </Box>
      <Typography
        css={css`
          font-size: 18px;
          text-align: center;
        `}
      >
        No Data
      </Typography>
    </Box>
  );
});
