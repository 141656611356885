import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HospitalState = {
  username: string | null;
  hospitalName: string | null;
  hpUID: string | null;
  hostUrl: string;
  lat: number | null;
  lng: number | null;
  qr_compression_flag: boolean;
  dashboard_button_flag: boolean | undefined;
  inquiry_history_button_flag: boolean | undefined;
  inquiry_response_button_flag: boolean | undefined;
  ambulance_record_button_flag: boolean | undefined;
  authVersion: 'v1' | 'v2' | undefined;
};

type IsAuthenticated = boolean;

export type HospitalAuthState = {
  hospital: HospitalState;
  isAuthenticated: IsAuthenticated;
};

export const initialState = {
  hospital: {
    username: '',
    hospitalName: '',
    hpUID: '',
    hostUrl: '',
    lat: 38,
    lng: 138,
    qr_compression_flag: false,
    inquiry_history_button_flag: false,
    inquiry_response_button_flag: false,
    ambulance_record_button_flag: false,
  },
  isAuthenticated: false,
} as HospitalAuthState;

const hospitalSlice = createSlice({
  name: 'hospital',
  initialState,
  reducers: {
    setHospital: (state, action: PayloadAction<HospitalState>) => {
      return { ...state, hospital: action.payload };
    },
    setIsAuthenticated: (state, action: PayloadAction<IsAuthenticated>) => {
      return { ...state, isAuthenticated: action.payload };
    },
    clearHospital: () => {
      return initialState;
    },
  },
});

// Reducerのエクスポート
export default hospitalSlice.reducer;

// Action Creatorsのエクスポート
export const { setHospital, setIsAuthenticated, clearHospital } = hospitalSlice.actions;
