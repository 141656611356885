import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Message = {
  id: string;
  message: string;
  size: 'small' | 'medium' | 'large';
  fontColor: string;
  backgroundColor: string;
};

export type MessagesState = {
  messages: Message[];
  isOpenMessages: boolean;
};

const initialState: MessagesState = {
  messages: [],
  isOpenMessages: true,
};

const messegeSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages: (_, action: PayloadAction<Message[]>) => {
      return { messages: action.payload, isOpenMessages: true };
    },
    toggleOpenMessages: (state) => {
      return { ...state, isOpenMessages: !state.isOpenMessages };
    },
  },
});

// Reducerのエクスポート
export default messegeSlice.reducer;

// Action Creatorsのエクスポート
export const { setMessages, toggleOpenMessages } = messegeSlice.actions;
