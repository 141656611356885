import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';

export type VideoCallInvitation = {
  id: string;
  hpUID: string;
  teamId: string;
  teamName: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

export const videoCallInvitationsAdapter = createEntityAdapter<VideoCallInvitation>({
  selectId: (data) => data.id,
  sortComparer: (data, nextData) => (data.updatedAt < nextData.updatedAt ? 1 : -1),
});

export const initialState = videoCallInvitationsAdapter.getInitialState();

export const videoCallInvitationsSlice = createSlice({
  name: 'videoCallInvitations',
  initialState: initialState,
  reducers: {
    setVideoCallInvitations: (state, action: PayloadAction<VideoCallInvitation[]>) => {
      videoCallInvitationsAdapter.setAll(state, action.payload);
    },
    addVideoCallInvitation: (state, action: PayloadAction<VideoCallInvitation>) => {
      videoCallInvitationsAdapter.addOne(state, action.payload);
    },
    clearVideoCallInvitations: () => {
      return initialState;
    },
  },
});

// Reducerのエクスポート
export default videoCallInvitationsSlice.reducer;

// Action Creatorsのエクスポート
export const {
  setVideoCallInvitations,
  addVideoCallInvitation,
  clearVideoCallInvitations,
} = videoCallInvitationsSlice.actions;
