import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { RootState } from 'states/index';
import { PrivateRouteEffect, NetworkEffect } from 'effects';

export const PrivateRoute = React.memo(({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { isLoading } = useSelector((state: RootState) => state.route);
  const { isAuthenticated } = useSelector((state: RootState) => state.hospital);

  return (
    <>
      <CssBaseline />
      <PrivateRouteEffect />
      <NetworkEffect />
      {isLoading ? (
        <></>
      ) : isAuthenticated ? (
        children
      ) : (
        <Navigate to="/signin" state={{ from: location }} />
      )}
    </>
  );
});
