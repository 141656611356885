/**
 * Import Layer
 */
import React from 'react';
import { Box } from '@mui/material';
import { css } from '@emotion/react';

/**
 * Types Layer
 */
type AgeType = { ag?: number | null };

/**
 * Presentational Layer
 */
export const AgeUI = React.memo((props: AgeType) => {
  return (
    <Box
      css={css`
        min-width: max-content;
        display: grid;
        place-content: center;
      `}
    >
      <span
        css={css`
          white-space: nowrap;
          font-size: 24px;
          font-weight: bold;
          font-family: 'Noto Sans JP', sans-serif;
        `}
      >
        {props.ag != null ? props.ag : '-'}
        <span
          css={css`
            font-size: 12px;
          `}
        >
          歳
        </span>
      </span>
    </Box>
  );
});
