export const getCreatedAt = (createdAt?: string) => {
  const regex =
    /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]\+09:00$/;
  const result = createdAt === undefined ? false : regex.test(createdAt);

  if (!result || createdAt === undefined) {
    const dt = new Date();
    const jdt = new Date(dt.getTime() + 9 * 60 * 60 * 1000);
    const isoStr = jdt.toISOString().split(/\.[0-9][0-9][0-9]Z/)[0] + '+09:00';
    const date = isoStr.slice(5, 10).replace('-', '/');
    const time = isoStr.slice(11, 16);
    return (
      <span>
        {date}
        <br />
        {time}
      </span>
    );
  } else {
    const date = createdAt.slice(5, 10).replace('-', '/');
    const time = createdAt.slice(11, 16);
    return (
      <span>
        {date}
        <br />
        {time}
      </span>
    );
  }
};
