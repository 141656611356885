/**
 * Import Layer
 */
import React from 'react';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';

/**
 * Types Layer
 */
type ChiefComplaintType = { pi?: string };

/**
 * Presentational Layer
 */
export const ChiefComplaintUI = React.memo((props: ChiefComplaintType) => {
  return (
    <Typography
      css={css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 24px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
      `}
    >
      {props.pi}
    </Typography>
  );
});
