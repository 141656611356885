/**
 * Import Layer
 */
import React from 'react';
import { Button } from '@mui/material';
import { css } from '@emotion/react';
import { useHospital } from 'hooks';

/**
 * Types Layer
 */
type InquiryHistoryButtonUIType = { url: string };

/**
 * Presentational Layer
 */
export const InquiryHistoryButtonUI = React.memo((props: InquiryHistoryButtonUIType) => {
  return (
    <Button
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        width: 116px;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: #333333;
        background-color: #f0ffff;
        &:hover {
          opacity: 0.8;
          background-color: #f0ffff;
        }
      `}
    >
      交渉･搬送履歴
    </Button>
  );
});

/**
 * Conrainer Layer
 */
export const InquiryHistoryButton = () => {
  const { hostUrl, hpUID } = useHospital();
  const fileName = 'NSER_mobile';
  const script = 'get_demad_history_from_dashboard';
  const url = `${hostUrl}/fmi/webd/${fileName}?script=${script}&param=${hpUID}`;

  return <InquiryHistoryButtonUI url={url} />;
};
