/**
 * Import Layer
 */
import React from 'react';
import { Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { GraphQLResult } from '@aws-amplify/api';

import { PatientsModel, ListPatientsQueryType } from 'effects/src/PatientsEffect';
import {
  setPatients,
  clearPatients,
  selectPatient,
  setFilterCondition,
} from 'states/ducks/patients/slice';
import { useHospital, usePatientsState } from 'hooks';

/**
 * Types Layer
 */
type ReloadPatientsButtonUIType = { onClick: () => Promise<void> };

/**
 * Presentational Layer
 */
export const ReloadPatientsButtonUI = React.memo((props: ReloadPatientsButtonUIType) => {
  return (
    <Button
      size="small"
      startIcon={<ReplayIcon />}
      onClick={props.onClick}
      css={css`
        height: 36px;
        width: 96px;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Noto Sans JP', sans-serif;
        color: #333333;
        background-color: #f0ffff;
        &:hover {
          opacity: 0.8;
          background-color: #f0ffff;
        }
      `}
    >
      更新
    </Button>
  );
});

/**
 * Conrainer Layer
 */
export const ReloadPatientsButton = () => {
  const { hpUID } = useHospital();
  const dispatch = useDispatch();
  const { selectedPatientId, filterCondition } = usePatientsState();
  const getPatiensData = async () => {
    if (hpUID == null) return;
    try {
      const response = (await PatientsModel.listPatients(
        hpUID
      )) as GraphQLResult<ListPatientsQueryType>;
      if (response.data == null) throw new Error('Failed to get patients data');
      return response.data.listPatientsInfo.items;
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const handleClick = async () => {
    const patients = await getPatiensData();
    if (patients == null) return;
    dispatch(clearPatients());
    dispatch(setPatients(patients));
    dispatch(selectPatient(selectedPatientId));
    dispatch(setFilterCondition(filterCondition));
  };

  return <ReloadPatientsButtonUI onClick={handleClick} />;
};
