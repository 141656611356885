import { initialPatient } from 'constant';
import { Amz, Patient } from 'states/ducks/patients/types';

const replaceCommmaToToten = (text: string) => {
  return text.replace(/,/g, '、');
};

const validationCpf = (cpf: string) => {
  if (cpf.length === 5) {
    const witness = cpf[1] === '無' ? '0' : '有' ? '1' : '0';
    const bystander = cpf[3] === '無' ? '0' : '有' ? '1' : '0';
    return cpf[0] + witness + bystander;
  } else {
    return cpf;
  }
};

const amzToString = (amz: Amz) => {
  const lvo =
    amz.lvo === undefined ? undefined : amz.lvo === null ? null : `"lvo":"${amz.lvo}"`;
  const cpss =
    amz.cpss === undefined
      ? undefined
      : amz.cpss === null
      ? null
      : `"cpss":"${amz.cpss}"`;
  const cvdd =
    amz.cvdd === undefined
      ? undefined
      : amz.cvdd === null
      ? null
      : `"cvdd":"${amz.cvdd}"`;
  const cvr =
    amz.cvr === undefined ? undefined : amz.cvr === null ? null : `"cvr":"${amz.cvr}"`;
  const cvp =
    amz.cvp === undefined ? undefined : amz.cvp === null ? null : `"cvp":"${amz.cvp}"`;
  const com =
    amz.com === undefined ? undefined : amz.com === null ? null : `"com":"${amz.com}"`;
  const vst =
    amz.vst === undefined ? undefined : amz.vst === null ? null : `"vst":"${amz.vst}"`;
  const mco =
    amz.mco === undefined ? undefined : amz.mco === null ? null : `"mco":"${amz.mco}"`;

  let amzArray: string[] = [];
  if (com !== undefined && com !== null) amzArray.push(com);
  if (cpss !== undefined && cpss !== null) amzArray.push(cpss);
  if (cvdd !== undefined && cvdd !== null) amzArray.push(cvdd);
  if (cvp !== undefined && cvp !== null) amzArray.push(cvp);
  if (cvr !== undefined && cvr !== null) amzArray.push(cvr);
  if (lvo !== undefined && lvo !== null) amzArray.push(lvo);
  if (mco !== undefined && mco !== null) amzArray.push(mco);
  if (vst !== undefined && vst !== null) amzArray.push(vst);
  const amzString = `{${amzArray.join('θ')}}`;
  return amzString;
};

export const generateQrCodeArray = (patient: Patient) => {
  if (patient === initialPatient) return { qrCodeCsvString: '', qrCodeString: '' };
  const qrArray: string[] = new Array(53).fill('');
  qrArray[0] = '2';
  qrArray[1] = patient.cr;
  qrArray[2] = patient.ambulancerecord_UID;
  qrArray[3] = patient.pd;
  qrArray[4] = patient.na + 'θ' + patient.naf;
  qrArray[5] = String(patient.se === null ? '' : patient.se);
  qrArray[6] = patient.em;
  qrArray[7] = replaceCommmaToToten(patient.ph);
  qrArray[8] = patient.vt;
  qrArray[9] = replaceCommmaToToten(patient.cc);
  qrArray[10] = replaceCommmaToToten(patient.pi);
  qrArray[11] = patient.hd;
  qrArray[12] = replaceCommmaToToten(patient.me);
  qrArray[13] = patient.bd;
  qrArray[14] = String(patient.ag === null ? '' : patient.ag);
  qrArray[15] = patient.jc;
  qrArray[16] = String(patient.ge === null ? '' : patient.ge);
  qrArray[17] = String(patient.gv === null ? '' : patient.gv);
  qrArray[18] = String(patient.gm === null ? '' : patient.gm);
  qrArray[19] = String(patient.sb === null ? '' : patient.sb);
  qrArray[20] = String(patient.db === null ? '' : patient.db);
  qrArray[21] = String(patient.pr === null ? '' : patient.pr);
  qrArray[22] = String(patient.rr === null ? '' : patient.rr);
  qrArray[23] = String(patient.bt === null ? '' : patient.bt);
  qrArray[24] = String(patient.sp === null ? '' : patient.sp);
  qrArray[25] = patient.re;
  qrArray[26] = patient.ar;
  qrArray[27] = patient.de;
  qrArray[28] = patient.ha;
  qrArray[29] = patient.am;
  qrArray[30] = String(patient.ac === null ? '' : patient.ac);
  qrArray[31] = patient.dc;
  qrArray[32] = '診療科' + patient.ot;
  qrArray[33] = patient.dh;
  qrArray[34] = patient.da;
  qrArray[35] = patient.co;
  qrArray[36] = patient.dd;
  qrArray[37] = String(patient.ea === null ? '' : patient.ea);
  qrArray[38] = patient.dq;
  qrArray[39] = patient.di;
  qrArray[40] = patient.qa;
  qrArray[41] = patient.ho;
  qrArray[42] = patient.dp;
  qrArray[43] = String(patient.sp2 === null ? '' : patient.sp2);
  qrArray[44] = String(patient.of === null ? '' : patient.of);
  qrArray[45] = patient.or;
  qrArray[46] = patient.jn;
  qrArray[47] = validationCpf(patient.cpf);
  qrArray[48] = patient.cpr.replace(/ /g, '');
  qrArray[49] = String(patient.cvf === true ? '1' : '0');
  qrArray[50] = String(patient.cvb === true ? '1' : '0');
  qrArray[51] = patient.cptd === '' ? '' : patient.cptd[0];
  qrArray[52] = amzToString(patient.amz);
  qrArray[53] = replaceCommmaToToten(patient.wp);
  qrArray[54] = ',';

  const qrCodeCsvString = Object.values(qrArray).join(',');
  // console.log(qrArrayString);
  return { qrCodeCsvString };
};
