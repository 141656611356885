/**
 * Import Layer
 */
import React, { useCallback, useState } from 'react';
import { Button, Modal, Fade, Box } from '@mui/material';
import { css } from '@emotion/react';
import { useHospital, useSelectedPatient } from 'hooks';

import { getIdToken } from 'utils/cognitoHelper';
import { ModalPaper } from './ModalPaper';
import toast from 'react-hot-toast';

const BASE_URL = process.env.REACT_APP_AUTH_BASEURL;

/**
 * Presentational Layer
 */
const openButtonStyle = css`
  padding: 5px 10px;
  height: 40px;
  width: 220px;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  background-color: #fb923c;
  &:hover {
    opacity: 0.8;
    background-color: #fb923c;
  }
`;

const modalStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Conrainer Layer
 */
export const SimultaneousInquiryResponseButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hpUID } = useHospital();
  const { id, ac, peerHospitals, destination_hospital } = useSelectedPatient();

  const collater = new Intl.Collator('ja-JP');
  const sortedPeerHospitals = peerHospitals
    ?.slice()
    .sort((a, b) => {
      return collater.compare(a.hospitalName, b.hospitalName);
    })
    .map((hospital) => ({
      ...hospital,
      response: hospital.id === destination_hospital ? 'DESTINATION' : hospital.response,
    }));

  const myResponse = peerHospitals?.find((hospital) => hospital.id === hpUID)?.response;
  const message = Boolean(destination_hospital)
    ? 'この要請は搬送先が決定しました'
    : ac === 3
    ? 'この要請は救急隊によりキャンセルされました'
    : myResponse === 'ACCEPT'
    ? '応答済：受入可'
    : myResponse === 'REJECT'
    ? '応答済：受入不可'
    : myResponse === 'OTHER'
    ? '応答済：その他'
    : undefined;

  const handleSubmit = useCallback(
    async (response: 'ACCEPT' | 'REJECT' | 'OTHER', freetext?: string) => {
      setIsSubmitting(true);

      const idToken = await getIdToken();
      const promise = fetch(`${BASE_URL}/simultaneous-inquiry/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          response,
          freetext,
        }),
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Response is not ok');
        }
        return;
      });

      toast.promise(promise, {
        loading: '応答を送信中...',
        success: '応答を送信しました',
        error: '応答の送信に失敗しました',
      });

      try {
        await promise;
      } catch (error) {
        console.error(error);
      }

      setIsSubmitting(false);
    },
    [id]
  );

  return (
    <>
      <Button onClick={() => setIsOpen(true)} size="small" css={openButtonStyle}>
        一斉要請応答
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} css={modalStyle}>
        <Fade in={isOpen}>
          <Box>
            <ModalPaper
              message={message}
              disabled={isSubmitting || message !== undefined}
              peerHospitals={sortedPeerHospitals}
              handleRespond={handleSubmit}
              handleClose={() => setIsOpen(false)}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
