// 以下のリストに含まれるUserNameでのみ以前の認証方式を許可する
// 新しい認証情報を通知した病院からコメントアウトで除外していく
const userNameWhiteList: string[] = [
  "TXP",          // 藁【テスト】TXP三次病院
  "txptel",       // 藁【テスト】TXP三次病院
  "sanyo",        // 山陽病院
  "central",      // セントラル病院
  "ota",          // 大田記念病院
  "TXPtestH",     // 藁【テスト】TXP病院
  "kokanhp",      // 日本鋼管福山病院
  "fujiihp",      // 藤井病院
  "txphpLINE",    // 藁【テスト】TXP病院
  "txphpMEDLINE", // 藁【テスト】TXP病院
  "txphpslack",   // 藁【テスト】TXP病院
  "memorial",     // 聖麗メモリアル病院
  "HGH",          // 日立総合病院
  "HMC",          // ひたち医療センター
  "HOS",          // 常陸大宮済生会病院
  "HTH",          // 藁【テスト】日立テスト病院
  "StestH",       // 藁【テスト】札幌テスト病院
  "SHTH",         // 札幌東徳洲会病院
  "TKH",          // 手稲渓仁会病院
  "HKMC",         // 北海道医療センター
  "CSC",          // 市立札幌病院
  "SMUH",         // 札幌医科大学病院
  "HUH",          // 北海道大学病院
  "FTH",          //
  "GUH",          // 群馬大学病院
  "KUH",          // 藁【テスト】鹿児島大学病院
  "TTH",          // 藁【テスト】高島テスト病院
  "TSCH",         // 【内科】高島市民病院
  "TYKH",         // 豊田厚生病院
  "TKNH",         // トヨタ記念病院
  "MKNH",         // マキノ病院
  // "SKGH",         // 湘南鎌倉総合病院
  "OFCH",         // 大船中央病院
  "KtestH",       // 藁【テスト】鎌倉テスト病院(本番ダッシュボード検証用)
  "FKH",          // ふれあい鎌倉ホスピタル
  "KKH",          // 清川病院
  "SKH",          // 湘南記念病院
  "SMMC",         // みやぎ県南中核病院
  "HTH2",         // 藁【テスト】日立テスト病院(本番ダッシュボード検証)
  "SNTH",         // 藁【テスト】仙南テスト病院(本番DB)
  "TtestH",       // 藁【テスト】豊田テスト病院
  "ASHP",         // 足助病院
  "TRMC",         // 豊田地域医療センター
  "Khos",         // 鎌倉病院
  "kthsanji1",    // 藁【テスト】鹿児島転院先テスト病院
  "kthsanji2",    // 藁【テスト】鹿児島転院先テスト病院(本番)
  "YMHQQ",        // 米盛病院
  "YUH",          // 横須賀市立うわまち病院
  "YCH",          // 横須賀市立市民病院
  "YkyoH",        // 横須賀共済病院
  "YtestH2",      // 藁【テスト】横須賀テスト病院(本番)
  "YtestH",       // 藁【テスト】横須賀テスト病院(テスト)
  "KIC",          // 勤医協中央病院
  "TtestH2",      // 藁【テスト】豊田テスト病院(本番ダッシュボード検証)
  "StestH2",      // 藁【テスト】札幌テスト病院(本番ダッシュボード検証用)
  "TUHems",       // 東北大学病院
  "OZH",          // 救急隊連携は未開始）大泉記念病院
  "SNH",          // 仙南病院
  "MCH",          // 三浦市立病院
  "YUGH",         // よこすか浦賀病院
  // "FCH",          // 藤沢市民病院
  "FtestH",       // 藁【テスト】藤沢テスト病院
  "FtestH2",      // 藁【テスト】藤沢テスト病院(本番ダッシュボード)
  "kthsanji2y",   // 藁【テスト】鹿児島転院先テスト病院(本番)
  "TStestH2",     // 藁【テスト】つくばテスト病院
  "TMCH",         // 筑波メディカルセンター病院
  "TSUH",         // 筑波大学病院(日勤帯のみ)
  "TSKH",         // 筑波記念病院
  "TSGH",         // 筑波学園病院
  "UMMCH31",      // UMMC
  "HUSMH39",      // HUSM
  "SASMECH40",    // SASMEC
  "33testH",      // 藁【テスト】水戸テスト病院
  "MRC33",        // 水戸赤十字病院
  "MKH33",        // 水戸協同病院
  "IPCH33",       // 茨城県立中央病院
  "MMC33",        // 水戸医療センター
  "MSH33",        // 水戸済生会病院
  "HNGH33",       // ひたちなか総合病院
  "SFH33",        // 水府病院
  "SMH33",        // 誠潤会水戸病院
  "MBHC33",       // 水戸ブレインハートセンター
  "MCH33",        // 水戸中央病院
  "JNH33",        // 城南病院
  "OKH33",        // 大久保病院
  "YSG33",        // 志村病院
  "HKH33",        // 北水会記念病院
  "TNH33",        // 丹野病院
  "AYH33",        // 青柳病院
  "CKM33",        // クリニック健康の杜
  // "SFTH29",       // 湘南藤沢徳洲会病院
  // "FSH29",        // 藤沢湘南台病院
  "34testH",      // 藁【テスト】鹿行テスト病院
  "omitama34",    // 小美玉市医療センター
  "ishiokaJN34",  // 石岡循環器科脳神経外科病院
  "OKH34",        // 大洗海岸病院
  "TKSH34",       // 高須病院
  "HKTH34",       // 鉾田病院
  "HJGH34",       // 白十字総合病院
  "KKH34",        // 小山記念病院
  "KSH34",        // 神栖済生会病院
  "SNDH34",       // 山王台病院
  "NMMC34",       // なめがた地域医療センター
  "TKGH36",       // 土浦協同病院(3次症例のみ)
  "KHC35",        // 鹿嶋ハートクリニック
  "UKMH38",       // HUKM
  "HTAAH40",      // HTAA
  "YOH33",        // 山本整形外科病院
  "34testH2",     // 藁【テスト】鹿嶋テスト病院
  "42testH",      // 藁【テスト】島根県出雲市テスト病院
  "SUAH42",       // 島根大学病院
  "SPH42",        // 島根県立中央病院
  "43testH",      // 藁【テスト】逗子テスト病院
  "HHC43",        // 葉山ハートセンター
  "44testH",      // 藁【テスト】葉山テスト病院
  "45testH",      // 藁【テスト】秦野テスト病院
  "HSH45",        // 秦野赤十字病院
  "YGH45",        // 八木病院
  "KGH45",        // 神奈川病院
  "TUH45",        // 東海大学病院
  "HCH45",        // 平塚市民病院
  "AKH45",        // 足柄上病院
  "IKH45",        // 伊勢原協同病院
  "46testH",      // 藁【テスト】石橋テスト病院
  "SOH46",        // 新小山市民病院
  "ISH46",        // 石橋総合病院
  "KCH46",        // 小金中央病院
  "SKH46",        // 新上三川病院
  "47testH",      // 藁【テスト】芳賀テスト病院
  "JichiER",      // 自治医科大学附属病院
  "HSH47",        // 芳賀赤十字
  "HCH47",        // 芳賀中央病院
  "MOH47",        // 真岡病院
  "HKH47",        // 福田記念病院
  "48testH",      // 藁【テスト】北九州テスト病院
  "SKH48",        // 新小文字病院
  "TBH48",        // 戸畑共立病院
  "KCC48",        // 北九州市立医療センター
  "KOH48",        // 健和会大手町病院
  "KGH48",        // 北九州総合病院
  "KRH48",        // 九州労災病院
  "SYH48",        // 製鉄記念八幡病院
  "KYH48",        // 北九州市立八幡病院
  "JKH48",        // JCHO九州病院
  "SUH48",        // 産業医科大学病院
];

export const isUsablePastAuth = (userName: string): boolean => {
  return userNameWhiteList.includes(userName);
};
