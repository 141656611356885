import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';

export const imagesAdaper = createEntityAdapter<ImageResponse>({
  // "id" is filename on S3 bucket
  selectId: (image) => image.id,
  sortComparer: (data, nextData) => (data.createdAt < nextData.createdAt ? 1 : -1),
});

export const initialState = imagesAdaper.getInitialState({
  selectedImageKey: '',
  // isOpen: false,
});

const imagesSlice = createSlice({
  name: 'images',
  initialState: initialState,
  reducers: {
    /**
     * 画像一覧データの取得
     */
    setImages: (state, action: PayloadAction<ImageResponse[]>) => {
      imagesAdaper.setAll(state, action.payload);
    },
    /**
     * 画像データの追加
     */
    addImage: (state, action: PayloadAction<ImageResponse>) => {
      imagesAdaper.addOne(state, action.payload);
    },
    /**
     * 画像一覧データの削除
     */
    clearImages: () => {
      return initialState;
    },
  },
});

// Reducerのエクスポート
export default imagesSlice.reducer;

// Action Creatorsのエクスポート
export const { setImages, addImage, clearImages } = imagesSlice.actions;
